import Card from '@mui/material/Card';
import { colorThemes } from '../../theme';
import { Grid } from '@mui/material';
import { LabelAndValue } from '../../components/LabelAndValue';

type Props = {
  id: string;
  title: string;
  project: string;
  isSelected?: boolean;
  selectDocumentation: (id: string) => void;
};

export default function DocumentationCard({
  id,
  title,
  project,
  isSelected,
  selectDocumentation,
}: Props) {
  return (
    <Card
      sx={{
        padding: '24px',
        minHeight: 184,
        borderRadius: '10px',
        backgroundColor: isSelected ? colorThemes.DARK_BLUE_800 : '#0D2236',
        border: `2px solid ${isSelected ? '#6B8EAE' : '#334E68'}`,

        '&:hover': {
          cursor: 'pointer',
          backgroundColor: colorThemes.DARK_BLUE_200,
        },
      }}
      onClick={() => selectDocumentation(id)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LabelAndValue label="Document name" value={title} />
        </Grid>
        <Grid item xs={8}>
          <LabelAndValue label="Document version" value="" />
        </Grid>
        <Grid item xs={4}>
          <LabelAndValue label="Iteration" value="" />
        </Grid>
        <Grid item xs={8}>
          <LabelAndValue label="Project" value={project} />
        </Grid>
        <Grid item xs={4}>
          <LabelAndValue label="Status" value="" />
        </Grid>
      </Grid>
    </Card>
  );
}
