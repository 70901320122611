import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
} from '@apollo/client';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import { setContext } from '@apollo/client/link/context';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import App from './App';

import { PostHogProvider } from 'posthog-js/react';

import { AuthProvider } from 'react-oidc-context';
import { getUser } from './utils/genericHelpers';

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
const onSigninCallback = (_user: any): void => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

const oidcConfig = {
  authority: window._env_.AUTH_URL,
  client_id: 'trail-lux',
  redirect_uri: window.location.origin + '/projects',
  response_type: 'code',
  scope: 'openid profile email',
  post_logout_redirect_uri: window.location.origin,
  silent_redirect_uri:
    window.location.origin + '/authentication/silent_callback',
  automaticSilentRenew: true,
  onSigninCallback,
  loadUserInfo: true,
};

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

// This is neccessary for using telepresence on mac osx because the DNS resolution node invokes does not honor the /etc/resolver directory
const API_ENDPOINT = process.env.REACT_APP_API_URL
  ? `${process.env.REACT_APP_API_URL}/graphql`
  : '/graphql';

const httpLink = new HttpLink({
  uri: API_ENDPOINT,
});

const authLink = setContext(async (_, { headers }) => {
  const user = getUser();
  const token = user?.access_token ?? '';
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

const removeTypenameLink = removeTypenameFromVariables();

const client = new ApolloClient({
  link: authLink.concat(removeTypenameLink).concat(httpLink),
  cache: new InMemoryCache(),
});

const router = createBrowserRouter([
  {
    path: '/*',
    element: <App />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    >
      <AuthProvider {...oidcConfig}>
        <ApolloProvider client={client}>
          <RouterProvider router={router} />
        </ApolloProvider>
      </AuthProvider>
    </PostHogProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
