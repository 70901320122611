import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';
import myErrorImage from '../resources/unknown_error.png';

function ErrorFallback() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        gap: 2,
      }}
    >
      <Typography variant="h3" gutterBottom>
        Something went wrong
      </Typography>

      <Box
        component="img"
        src={myErrorImage}
        alt="Error Image"
        sx={{ width: '500px' }}
      />

      <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
        <Button variant="contained" onClick={handleGoBack}>
          Go Back
        </Button>
      </Box>
    </Box>
  );
}

function ApplicationErrorBoundary({ children }: { children: React.ReactNode }) {
  const location = useLocation();

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} resetKeys={[location]}>
      {children}
    </ErrorBoundary>
  );
}

export default ApplicationErrorBoundary;
