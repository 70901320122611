import React from 'react';
import {
  Modal,
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import {
  DocumentationTemplate,
  DocumentationTemplates,
  DocumentationTemplateTypeEnum,
} from './templates/DocumentationTemplates';

const CREATE_DOCUMENTATION_MUTATION = gql`
  mutation CreateTemplatedDocumentation(
    $name: String!
    $documentationType: DocumentationType!
    $projectId: String!
    $components: [DocumentComponentInput!]!
    $documentCreator: String!
  ) {
    createDocumentation(
      name: $name
      documentationType: $documentationType
      projectId: $projectId
      components: $components
      documentCreator: $documentCreator
    ) {
      documentation {
        __typename
        id
        name
        documentationType
      }
    }
  }
`;

interface CreateDocumentationModalProps {
  open: boolean;
  onClose: () => void;
  projectId: string;
}

const CreateDocumentationModal: React.FC<CreateDocumentationModalProps> = ({
  open,
  onClose,
  projectId,
}) => {
  const [createDocumentation, { loading }] = useMutation(
    CREATE_DOCUMENTATION_MUTATION,
    {
      update(cache, { data: { createDocumentation } }) {
        const newDocumentation = createDocumentation.documentation;
        cache.modify({
          fields: {
            allDocumentations(existingDocumentationRefs = []) {
              const newDocumentationRef = cache.writeFragment({
                data: newDocumentation,
                fragment: gql`
                  fragment NewDocumentation on Documentation {
                    id
                    name
                    project {
                      title
                    }
                  }
                `,
              });
              return [...existingDocumentationRefs, newDocumentationRef];
            },
          },
        });
      },
    }
  );

  const handleTemplateClick = async (template: DocumentationTemplate) => {
    try {
      await createDocumentation({
        variables: {
          name: template.title,
          documentationType: template.documentationType,
          projectId,
          components: template.components,
          documentCreator: 'USER',
        },
      });
      toast.success('Documentation created successfully');
      onClose();
    } catch (error) {
      toast.error('Failed to create documentation');
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Select a Documentation Template
        </Typography>
        <Typography variant="subtitle1" component="h3" gutterBottom>
          Organizational Templates
        </Typography>
        <List>
          {DocumentationTemplates.filter(
            temlate =>
              temlate.templateType ===
              DocumentationTemplateTypeEnum.Organization_Specific
          ).map((template, index) => (
            <ListItem
              button
              key={index}
              onClick={() => handleTemplateClick(template)}
              disabled={loading}
            >
              <ListItemText primary={template.title} />
            </ListItem>
          ))}
        </List>
        <Divider sx={{ my: 2 }} />
        <Typography variant="subtitle1" component="h3" gutterBottom>
          Project Specific Templates
        </Typography>
        <List>
          {DocumentationTemplates.filter(
            temlate =>
              temlate.templateType ===
              DocumentationTemplateTypeEnum.Project_Specific
          ).map((template, index) => (
            <ListItem
              button
              key={index}
              onClick={() => handleTemplateClick(template)}
              disabled={loading}
            >
              <ListItemText primary={template.title} />
            </ListItem>
          ))}
        </List>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={onClose} sx={{ mr: 1 }}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreateDocumentationModal;
