import React, { FC } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  CardContent,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import 'react-circular-progressbar/dist/styles.css';
import { useWatch, useFormContext, Control } from 'react-hook-form';
import CircularProgressDropdown from './CircularProgressDropdown';
import RiskIndicator from './RiskIndicator';
import ExpandableCard from './ExpandableCard';
import { colorThemes } from '../../theme';
import { RiskAssessment } from '../../__generated__/gql/graphql';

const LIKELIHOOD = 'frequency';
const SEVERITY = 'severity';

export const TRAIL_SECTION_FIELDS = [LIKELIHOOD, SEVERITY];

export const undefinedOption = { label: 'Select an option', value: 0 };
const frequencyOptions = [
  undefinedOption,
  { label: 'Rare', value: 1 },
  { label: 'Unlikely', value: 2 },
  { label: 'Moderate', value: 3 },
  { label: 'Likely', value: 4 },
  { label: 'Often', value: 5 },
];
const severityOptions = [
  undefinedOption,
  { label: 'None/Minor', value: 1 },
  { label: 'Moderate/Small', value: 2 },
  { label: 'Serious', value: 3 },
];

interface TrailRiskSectionProps {
  name: `riskSections.${number}`;
  section: {
    id: string;
    label: string;
    description?: string;
    guidingQuestions?: string;
  };
  control: Control<RiskAssessment>;
  readOnly?: boolean;
  category?: string;
}

export function calculateTrailRiskRank(
  likelihood: number,
  severity: number
): number {
  return likelihood * severity;
}

const TrailRiskSection: FC<TrailRiskSectionProps> = ({
  name,
  section,
  control,
  readOnly = false,
  category,
}) => {
  const { getValues, setValue } = useFormContext<RiskAssessment>();
  setValue(`${name}.category`, category);
  const likelihood = useWatch({
    control,
    name: `${name}.${LIKELIHOOD}`,
    defaultValue: getValues(`${name}.${LIKELIHOOD}`) || 0,
  });
  const severity = useWatch({
    control,
    name: `${name}.${SEVERITY}`,
    defaultValue: getValues(`${name}.${SEVERITY}`) || 0,
  });

  const riskRank = calculateTrailRiskRank(likelihood, severity);

  return (
    <Box>
      <ExpandableCard
        title={section.label}
        titleIcon={<RiskIndicator riskRank={riskRank} />}
        initiallyExpanded={readOnly}
        sx={{
          bgcolor: colorThemes.GREY_100,
          color: 'black',
          display: 'block',
        }}
      >
        <CardContent sx={{ paddingTop: '0px' }}>
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
          >
            <Typography variant="body1" color="grey">
              {section.description}
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              gap="32px"
              paddingY="16px"
            >
              <CircularProgressDropdown
                readOnly={readOnly}
                control={control}
                title="Likelihood"
                graphs={[
                  {
                    name: `${name}.frequency`,
                    options: frequencyOptions,
                    label: 'Likelihood',
                  },
                ]}
              />
              <CircularProgressDropdown
                readOnly={readOnly}
                control={control}
                title="Severity"
                graphs={[
                  {
                    name: `${name}.severity`,
                    options: severityOptions,
                    label: 'Severity',
                  },
                ]}
              />
            </Box>
            {!readOnly && (
              <Accordion elevation={0} sx={{ bgcolor: colorThemes.GREY_100 }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
                >
                  <Typography variant="subtitle1" color="black">
                    Guiding Questions
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    sx={{ whiteSpace: 'pre-line' }}
                    color="black"
                    variant="body1"
                  >
                    {section.guidingQuestions}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            )}
          </Box>
        </CardContent>
      </ExpandableCard>
    </Box>
  );
};

export default TrailRiskSection;
