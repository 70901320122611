import { Box, Typography } from '@mui/material';
import { colorThemes } from '../theme';
import { TRequirement } from './useIsoCompletionStatus';

const statusColors = {
  finished: '#2E7D32',
  unfinished: '#FF0000',
};

export function RequirementCard({
  onClick,
  specificationNumber,
  evidenceNumber,
  fullfilledEvidenceNumber,
  fullfilledSpecificationNumber,
  requirement: { title, description, owner, reviewer },
}: {
  requirement: TRequirement;
  onClick: () => void;
  specificationNumber: number;
  fullfilledSpecificationNumber: number;
  evidenceNumber: number;
  fullfilledEvidenceNumber: number;
}) {
  const isEvidenceFullfilled = fullfilledEvidenceNumber === evidenceNumber;
  const isSpecFullfilled =
    fullfilledSpecificationNumber === specificationNumber;

  return (
    <Box
      component="button"
      sx={{
        all: 'unset',
        bgcolor: colorThemes.GREY_100,
        color: colorThemes.DARK_BLUE_600,
        borderRadius: '8px',
        padding: '16px',
        display: 'grid',
        gridTemplateColumns: '5fr 1fr 1fr 1.5fr 1.5fr max-content',
        gap: 1,
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <Box display="flex" gap="14px" flexDirection="column">
        <Typography
          component="li"
          display="inline"
          key={title}
          fontSize="20px"
          fontWeight={800}
          lineHeight="24px"
          className="list-item"
        >
          {title}
        </Typography>
        <Typography>{description}</Typography>
      </Box>
      <Box display="flex" gap="14px" flexDirection="column">
        <Typography fontWeight={600}>Evidence</Typography>
        <Box display="flex" alignItems="center" gap="6px">
          <Box
            component="span"
            borderRadius="9999px"
            bgcolor={
              isEvidenceFullfilled
                ? statusColors.finished
                : statusColors.unfinished
            }
            width="8px"
            height="8px"
          />
          <span>
            {fullfilledEvidenceNumber} /{evidenceNumber}
          </span>
        </Box>
      </Box>
      <Box display="flex" gap="14px" flexDirection="column">
        <Typography fontWeight={600}>Specs</Typography>
        <Box display="flex" alignItems="center" gap="6px">
          <Box
            component="span"
            borderRadius="9999px"
            bgcolor={
              isSpecFullfilled ? statusColors.finished : statusColors.unfinished
            }
            width="8px"
            height="8px"
          />
          <span>
            {fullfilledSpecificationNumber} /{specificationNumber}
          </span>
        </Box>
      </Box>
      <Box display="flex" gap="14px" flexDirection="column">
        <Typography fontWeight={600}>Owner</Typography>
        <span>{owner || 'Not Assigned'}</span>
      </Box>
      <Box display="flex" gap="14px" flexDirection="column">
        <Typography fontWeight={600}>Approval</Typography>
        <span>{reviewer || 'Not Assigned'}</span>
      </Box>
      <Box
        width={20}
        height={20}
        borderRadius="4px"
        bgcolor={
          isSpecFullfilled && isEvidenceFullfilled
            ? statusColors.finished
            : statusColors.unfinished
        }
      />
    </Box>
  );
}
