import {
  Box,
  Card,
  CardContent,
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  Typography,
  Button,
} from '@mui/material';
import { useState } from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { colorThemes } from '../theme';
import { Task } from '../__generated__/gql/graphql';
import { Link } from 'react-router-dom';

export default function UserTasks({ tasks }: { tasks: Task[] }) {
  return (
    <Card
      sx={{
        height: '100%',
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'hidden',
          gap: '1rem',
        }}
      >
        <Typography variant="h5" component="div">
          Tasks
        </Typography>
        <Box
          sx={{
            width: '100%',
            paddingLeft: '8px',
            height: '80vh',
            overflowY: 'auto',
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{ marginBottom: '0.5rem' }}
          >
            {tasks?.filter(task => !task?.isCompleted).length} Open Tasks
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            {tasks
              ?.filter(task => !task?.isCompleted)
              .map(task => (
                <TaskCard key={task?.id} task={task} />
              ))}
          </Box>
          <Typography
            variant="h6"
            component="div"
            sx={{ marginTop: '1.5rem', marginBottom: '0.5rem' }}
          >
            {tasks?.filter(task => task?.isCompleted).length} Finished Tasks
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            {tasks
              ?.filter(task => task?.isCompleted)
              .map(task => (
                <TaskCard key={task?.id} task={task} />
              ))}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

const TaskCard = ({ task }: { task: Task }) => {
  const [open, setOpen] = useState(false);

  function handleClick() {
    setOpen(!open);
  }

  return (
    <Box>
      <ListItemButton
        onClick={handleClick}
        sx={{
          backgroundColor: task.isCompleted
            ? colorThemes.DARK_BLUE_400
            : colorThemes.DARK_BLUE_200,
          borderRadius: '8px',
          borderBottomLeftRadius: open ? '0px' : '8px',
          borderBottomRightRadius: open ? '0px' : '8px',
          padding: '4px',
          ':hover': {
            backgroundColor: colorThemes.DARK_BLUE_400,
          },
        }}
      >
        <ListItemIcon>
          <Radio
            checked={task.isCompleted ?? false}
            disabled={task.isCompleted ?? false}
          />
        </ListItemIcon>
        <ListItemText primary={task.name} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open}>
        <Box
          sx={{
            backgroundColor: task.isCompleted
              ? colorThemes.DARK_BLUE_400
              : colorThemes.DARK_BLUE_200,
            padding: '1.2rem',
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
          }}
        >
          <Typography variant="subtitle2" sx={{ whiteSpace: 'pre-wrap' }}>
            {task.description}
          </Typography>
          <Box
            sx={{ display: 'flex', justifyContent: 'end', marginTop: '1rem' }}
          >
            <Button
              variant="outlined"
              sx={{ paddingY: '0.2rem' }}
              component={Link}
              to={task.actionUrl || '/'}
              endIcon={
                <ArrowForwardIosRoundedIcon
                  fontSize="small"
                  sx={{ color: colorThemes.YELLOW_200 }}
                />
              }
            >
              To the task
            </Button>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};
