import { Box, Button } from '@mui/material';
import DocumentationCard from './common_components/DocumentationCard';
import { gql, useSuspenseQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { colorThemes } from '../theme';
import { AllDocumentationsQuery } from '../__generated__/gql/graphql';

const GET_DOCUMENTATIONS = gql(`
  query allDocumentations($projectId: String!) {
    allDocumentations(projectId: $projectId) {
      name
      id
      project {
        title
      }
    }
  }
`);

interface DocumentsListProps {
  onCreateNew: () => void;
}

export const DocumentsList = ({ onCreateNew }: DocumentsListProps) => {
  const { documentationId, projectId } = useParams();

  const { data: projectsData } = useSuspenseQuery<AllDocumentationsQuery>(
    GET_DOCUMENTATIONS,
    {
      variables: {
        projectId,
      },
    }
  );

  const navigate = useNavigate();

  const handleUpdateSelectedDocumentation = (id: string) => {
    navigate(`/documentation/${projectId}/${id}`);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        gap: 2,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button size="small" onClick={onCreateNew} variant="contained">
          New doc
          <AddIcon
            sx={{
              fontSize: 24,
              marginLeft: '8px',
              color: colorThemes.DARK_BLUE_600,
            }}
          />
        </Button>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
          gap: 2,
        }}
      >
        {(projectsData?.allDocumentations ?? []).map(documentation => (
          <DocumentationCard
            key={documentation!.id}
            id={documentation!.id}
            title={documentation?.name ?? ''}
            project={documentation!.project.title}
            selectDocumentation={handleUpdateSelectedDocumentation}
            isSelected={documentation!.id === documentationId}
          />
        ))}
      </Box>
    </Box>
  );
};
