import React, { FC } from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { gql, useMutation, useSuspenseQuery } from '@apollo/client';
import { colorThemes } from '../theme';
import { toast } from 'react-toastify';
import {
  CreateMaterializedProjectGovernanceMutation,
  EnabledProjectGovernanceFrameworksQuery,
} from '../__generated__/gql/graphql';

interface NewGovernanceFrameworkModalProps {
  open: boolean;
  onClose: () => void;
  projectId: string;
}

export const GET_ENABLED_RISK_PROJECT_GOVERNANCE_FRAMEWORKS = gql`
  query enabledProjectGovernanceFrameworks {
    enabledProjectGovernanceFrameworks {
      id
      templateId
      name
      description
    }
  }
`;

const CREATE_MATERIALIZED_PROJECT_GOVERNANVE = gql`
  mutation CreateMaterializedProjectGovernance($templateId: ID!) {
    createMaterializedProjectGovernanceFramework(templateId: $templateId) {
      framework {
        id
        name
      }
    }
  }
`;

const NewGovernanceFrameworkModal: FC<NewGovernanceFrameworkModalProps> = ({
  open,
  onClose,
  projectId,
}) => {
  const navigate = useNavigate();

  const { data: governanceFrameworkTemplates } =
    useSuspenseQuery<EnabledProjectGovernanceFrameworksQuery>(
      GET_ENABLED_RISK_PROJECT_GOVERNANCE_FRAMEWORKS
    );

  const [createAssessmentFrameworkCopy] =
    useMutation<CreateMaterializedProjectGovernanceMutation>(
      CREATE_MATERIALIZED_PROJECT_GOVERNANVE,
      {
        onCompleted: data => {
          toast.success('Framework created successfully.');
          navigate(
            `/project_governance/${projectId}/${
              data!.createMaterializedProjectGovernanceFramework!.framework!.id
            }`
          );
          onClose();
        },
        onError: error => {
          toast.error(`An unexpected error occurred: ${error.message}`);
        },
      }
    );

  const handleSelect = (templateId: string) => {
    createAssessmentFrameworkCopy({
      variables: {
        templateId,
      },
    });
  };

  const governanceFrameworks =
    governanceFrameworkTemplates.enabledProjectGovernanceFrameworks?.filter(
      (framework): framework is NonNullable<typeof framework> =>
        framework !== null
    ) ?? [];

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Select Project Governance Framework</DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{ minHeight: '200px', minWidth: '400px' }}
        >
          {governanceFrameworks.length === 0 ? (
            <Typography variant="body1" color="textSecondary">
              No Project Level Governance Frameworks enabled yet. Please enable
              the frameworks on the Profile page.
            </Typography>
          ) : (
            governanceFrameworks.map(({ id, name, description }) => (
              <Card
                key={id}
                sx={{ backgroundColor: colorThemes.DARK_BLUE_700 }}
              >
                <CardActionArea onClick={() => id && handleSelect(id)}>
                  <CardContent>
                    <Typography variant="h6" sx={{ color: 'white' }}>
                      {name || 'Unknown Type'}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {description || 'Unknown Description'}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            ))
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default NewGovernanceFrameworkModal;
