import { Grid, Typography } from '@mui/material';

import { ToastContainer } from 'react-toastify';

import { getFeatureFlag } from '../utils/featureFlags';
import FrameworkManagement from './FrameworkManagement';
import UserManagement from './UserManagement';

export const OrganizationSettingsPage = () => {
  const showFrameworkManagment = getFeatureFlag('SHOW_FRAMEWORK_MANAGMENT');
  return (
    <>
      <Typography variant="h4" marginBottom={3}>
        Organization Settings
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{
          width: '100%',
        }}
      >
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <UserManagement />
            </Grid>
            {showFrameworkManagment ? (
              <Grid item xs={12}>
                <FrameworkManagement />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <ToastContainer />
    </>
  );
};
