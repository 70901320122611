import { Box, Typography } from '@mui/material';
import { colorThemes } from '../theme';
import {
  MaterializedRequirement,
  RequirementCompletionStatusEnum,
} from '../__generated__/gql/graphql';

const statusColors = {
  finished: '#2E7D32',
  unfinished: '#FF0000',
};

export function RequirementCard({
  onClick,
  specificationNumber,
  evidenceNumber,
  requirement: { title, description, userStatus: status, owners, approvers },
}: {
  requirement: MaterializedRequirement;
  onClick: () => void;
  specificationNumber: number;
  evidenceNumber: number;
}) {
  const truncatedDescription =
    description!.length > 300
      ? `${description?.substring(0, 300)}...`
      : description;
  return (
    <Box
      component="button"
      sx={{
        all: 'unset',
        bgcolor: colorThemes.GREY_100,
        color: colorThemes.DARK_BLUE_600,
        borderRadius: '8px',
        padding: '16px',
        display: 'grid',
        gridTemplateColumns: '5fr 1fr 1fr 1.5fr 1.5fr max-content',
        gap: 1,
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <Box display="flex" gap="14px" flexDirection="column">
        <Typography
          component="li"
          display="inline"
          key={title}
          fontSize="20px"
          fontWeight={800}
          lineHeight="24px"
          className="list-item"
        >
          {title}
        </Typography>
        <Typography>{truncatedDescription}</Typography>
      </Box>
      <Box display="flex" gap="14px" flexDirection="column">
        <Typography fontWeight={600}>Evidence</Typography>
        <Box display="flex" alignItems="center" gap="6px">
          <Box
            component="span"
            borderRadius="9999px"
            bgcolor={statusColors.finished}
            width="8px"
            height="8px"
          />
          <span>{evidenceNumber}</span>
        </Box>
      </Box>
      <Box display="flex" gap="14px" flexDirection="column">
        <Typography fontWeight={600}>Specs</Typography>
        <Box display="flex" alignItems="center" gap="6px">
          <Box
            component="span"
            borderRadius="9999px"
            bgcolor={statusColors.finished}
            width="8px"
            height="8px"
          />
          <span>{specificationNumber}</span>
        </Box>
      </Box>
      <Box display="flex" gap="14px" flexDirection="column">
        <Typography fontWeight={600}>Owner</Typography>
        <span>
          {owners?.map(owner => owner?.initials).join(', ') || 'Not Assigned'}
        </span>
      </Box>
      <Box display="flex" gap="14px" flexDirection="column">
        <Typography fontWeight={600}>Approval</Typography>
        <span>
          {approvers?.map(approver => approver?.initials).join(', ') ||
            'Not Assigned'}
        </span>
      </Box>
      <Box
        width={20}
        height={20}
        borderRadius="4px"
        bgcolor={
          status?.completionStatus === RequirementCompletionStatusEnum.Approved
            ? statusColors.finished
            : statusColors.unfinished
        }
      />
    </Box>
  );
}
