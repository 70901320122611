import { Box } from '@mui/material';
import ClassificationQuestionaire from './ClassificationQuestionnaire';
import { useReducer } from 'react';
import {
  questionaireQuestions,
  questionActions,
  risk,
  possibleRiskOnQuestion,
  clearRisk,
} from './Questions';
import { useParams } from 'react-router-dom';
import { RiskClass } from '../__generated__/gql/graphql';

export interface Answer {
  id: number;
  values: number[];
}

type State = {
  questionPosition: number;
  answers: Answer[];
  history: number[];
};

type Action =
  | { type: 'NEXT_STEP'; id: number; values: number[] }
  | { type: 'BACK_STEP' };

const initialState: State = {
  questionPosition: 0,
  answers: [],
  history: [],
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'NEXT_STEP': {
      const { id, values } = action;
      const answers = [...state.answers];
      if (answers[id]) {
        answers[id] = { ...answers[id], values };
      } else {
        answers[id] = { id, values };
      }
      const nextPosition = questionActions[id]
        ? questionActions[id](answers)
        : state.questionPosition + 1;
      return {
        ...state,
        questionPosition: nextPosition,
        answers,
        history: [...state.history, state.questionPosition],
      };
    }
    case 'BACK_STEP': {
      const possibleRisks =
        possibleRiskOnQuestion[state.history[state.history.length - 1]] || [];
      possibleRisks.forEach(possibleRisk => {
        if (possibleRisk === risk) {
          clearRisk();
        }
      });
      const previousPosition = state.history[state.history.length - 1] || 0;
      const history = state.history.slice(0, -1);

      return {
        ...state,
        questionPosition: previousPosition,
        history,
      };
    }
    default:
      return state;
  }
};

interface RiskClassificationProps {
  setRiskClass?: (riskClass: RiskClass | undefined) => void;
  nextStepRiskClass?: () => void;
}

export default function RiskClassification({
  setRiskClass,
  nextStepRiskClass,
}: RiskClassificationProps) {
  const { projectId } = useParams<{
    projectId: string;
  }>();
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleBackStep = () => {
    dispatch({ type: 'BACK_STEP' });
  };

  const handleNextStep = (id: number, values: number[]) => {
    dispatch({ type: 'NEXT_STEP', id, values });
  };

  return (
    <Box
      sx={{
        height: '96%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ClassificationQuestionaire
        projectId={projectId}
        key={state.questionPosition}
        questionaireQuestion={questionaireQuestions[state.questionPosition]}
        handleNextStep={handleNextStep}
        handleBackStep={handleBackStep}
        answers={state.answers}
        questionPosition={state.questionPosition}
        setRiskClass={setRiskClass}
        nextStepRiskClass={nextStepRiskClass}
      />
    </Box>
  );
}
