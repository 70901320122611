import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { TabPanel } from '../components/Tabs';
import ExperimentSelector from '../components/ExperimentSelector';
import { LoadingButton } from '@mui/lab';
import { colorThemes } from '../theme';
import { Autorenew } from '@mui/icons-material';
import { NestedMenuItem } from 'mui-nested-menu';
import { Loading } from '../components/Loading';
import { gql, useMutation, useSuspenseQuery } from '@apollo/client';
import { DocumentationResultViewer } from './documentation_result/DocumentationResultViewer';
import { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DocumentationResultQuery } from '../__generated__/gql/graphql';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const EXPORT_DOCUMENTATION_TO_CONFLUENCE_MUTATION = gql(`
    mutation ExportDocumentationResultToConfluence($resultId: String!) {
      exportDocumentationResultToConfluence(resultId: $resultId) {
        link
      }
    }
  `);

const GENERATE_DOCUMENTATION_MUTATION = gql(`
  mutation GenerateDocumentation($projectId: String!, $documentationId: String!, $experimentId: String) {
    generateDocumentationResult(projectId: $projectId, documentationId: $documentationId, experimentId: $experimentId) {
      documentationResult {
        id,
        generatedDocumentation {
          name,
          text,
          outputFormat,
          artifacts{
            id
            url
            contentType
            creationTimestamp
            name
            tags
            fullPath
            size
            html
            ansiText
          }
        }
      }
    }
  }
`);

const GET_DOCUMENTATION = gql(`
  query documentationResult($documentation_id:String!, $experiment_id: String) {
    documentation(id:$documentation_id) {
      id
      documentationType
      components {
        type
        name
        dataSource
        query
        args
      }
      name
      project {
      id
      title
      experiments {
        id
      }
    }
      generatedDocumentations(experimentId: $experiment_id) {
        id
        creationTimestamp
        generatedDocumentation {
          name,
          text,
            outputFormat,
            artifacts {
              id
              url
              ansiText
              contentType
              creationTimestamp
              name
              tags
              fullPath
              size
              html
            }
        }
      }
    }
  }`);

interface DocumentationResultViewProps {
  setEditorContent: (content: string) => void;
}

export default function DocumentationResultView({
  setEditorContent,
}: DocumentationResultViewProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { documentationId, projectId } = useParams();
  const experimentIdFromParam = searchParams.get('experimentId');
  const navigate = useNavigate();

  const [selectedExperiment, setSelectedExperiment] = useState<
    string | undefined
  >(experimentIdFromParam || undefined);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(e.currentTarget);
  };
  const handleClose = () => setMenuAnchorEl(null);
  const menuIsOpen = Boolean(menuAnchorEl);

  const [exportDocumentationToConfluence, { loading: exportingToConfluence }] =
    useMutation(EXPORT_DOCUMENTATION_TO_CONFLUENCE_MUTATION, {
      onCompleted: () => {
        toast.success('Exported to Confluence successfully!');
        handleClose();
      },
      onError: error => {
        toast.error(`Failed to export: ${error.message}`);
        handleClose();
      },
    });

  const { data: documentation, refetch } =
    useSuspenseQuery<DocumentationResultQuery>(GET_DOCUMENTATION, {
      variables: {
        documentation_id: documentationId ?? '',
        experiment_id: selectedExperiment ?? '',
      },
      skip: !documentationId,
    });

  const [generateDocumentationMutation, { loading: isGenerationLoading }] =
    useMutation(GENERATE_DOCUMENTATION_MUTATION);

  const project = documentation?.documentation?.project?.title ?? '';
  const documentationName = documentation?.documentation?.name ?? '';
  const experiments = documentation?.documentation?.project.experiments ?? [];
  const documentationResultId =
    documentation?.documentation?.generatedDocumentations?.[0]?.id;

  const [documentationResult, setDocumentationResult] = useState(
    documentation?.documentation?.generatedDocumentations?.[0]
      ?.generatedDocumentation ?? null
  );

  const handleGenerateDocumentation = async () =>
    await generateDocumentationMutation({
      onCompleted: data => {
        setDocumentationResult(
          data?.generateDocumentationResult.documentationResult
            .generatedDocumentation
        );
        toast.success('Documentation generated');
      },
      onError: error => {
        toast.error('Failed to generate documentation');
        console.error(error);
      },
      variables: {
        documentationId: documentationId!,
        projectId: projectId!,
        experimentId: selectedExperiment,
      },
    });
  const experimentIds = experiments
    .map(experiment => experiment?.id)
    .filter(id => id != null) as string[];

  const handleChangeSelectedExperiment = async (experimentId: string) => {
    setSelectedExperiment(experimentId);
    searchParams.set('experimentId', experimentId);
    setSearchParams(searchParams);
    const res = await refetch({
      documentation_id: documentationId ?? '',
      experiment_id: experimentId ?? '',
    });
    setDocumentationResult(
      res?.data?.documentation?.generatedDocumentations?.[0]
        ?.generatedDocumentation ?? null
    );
  };

  const mergeToMarkdown = () => {
    let markDownString: string = '';

    documentationResult?.forEach(componentResult => {
      switch (componentResult?.outputFormat) {
        case 'Standard':
          markDownString += '# ' + componentResult.name + '\n';
          markDownString += JSON.parse(componentResult.text).text;
          break;
        default:
          markDownString += 'Not implemented';
      }
      markDownString += '\n';
    });

    return markDownString;
  };

  return (
    <>
      <TabPanel.Header>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Typography variant="subtitle1" color="black" textAlign="center">
            {documentationName}
          </Typography>

          {experimentIds.length > 0 && (
            <ExperimentSelector
              experimentIds={experimentIds}
              selectedExperiment={selectedExperiment}
              setSelectedExperiment={handleChangeSelectedExperiment}
            />
          )}

          <ButtonGroup>
            <LoadingButton
              loading={isGenerationLoading}
              loadingIndicator={<CircularProgress size={24} sx={{ mr: 1 }} />}
              onClick={() => handleGenerateDocumentation()}
              variant="contained"
              size="small"
              sx={{
                '&:hover': {
                  backgroundColor: colorThemes.YELLOW_200,
                },
                '&:disabled': {
                  backgroundColor: colorThemes.YELLOW_600,
                },
              }}
            >
              Regenerate
              <Autorenew
                sx={{
                  fontSize: 24,
                  marginLeft: '8px',
                  color: colorThemes.DARK_BLUE_600,
                }}
              />
            </LoadingButton>
            <Button variant="contained" onClick={e => handleClick(e)}>
              <MoreHorizIcon sx={{ color: colorThemes.DARK_BLUE_800 }} />
            </Button>
          </ButtonGroup>
          <Menu
            id="menu"
            anchorEl={menuAnchorEl}
            open={menuIsOpen}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                setEditorContent(mergeToMarkdown());
                handleClose();
                navigate(
                  `/documentation/${projectId}/${documentationId}/?view=edit`
                );
              }}
            >
              Copy to Edit Area
            </MenuItem>
            <NestedMenuItem label="Export to" parentMenuOpen={menuIsOpen}>
              <MenuItem
                disabled={exportingToConfluence}
                onClick={() => {
                  exportDocumentationToConfluence({
                    variables: {
                      resultId: documentationResultId ?? '',
                    },
                  });
                }}
              >
                Confluence
              </MenuItem>
            </NestedMenuItem>
          </Menu>
        </Box>
      </TabPanel.Header>
      <TabPanel.Content>
        {isGenerationLoading ? (
          <Loading
            message="This might take a few minutes"
            sx={{
              color: colorThemes.DARK_BLUE_400,
              fontWeight: 400,
            }}
          />
        ) : (
          <DocumentationResultViewer
            creationTimestamp={
              documentation?.documentation?.generatedDocumentations?.[0]
                ?.creationTimestamp
            }
            project={project}
            documentationResult={documentationResult}
          />
        )}
      </TabPanel.Content>
    </>
  );
}
