import { Box, Button, CircularProgress, Tab, Tabs } from '@mui/material';
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import DocumentationPageLayout from './common_components/DocumentationPageLayout';
import { NetworkStatus, gql, useMutation, useQuery } from '@apollo/client';
import {
  DataSourceEnum,
  DocumentComponentTypeEnum,
  DocumentationQuery,
  DocumentationTypeEnum,
} from '../__generated__/gql/graphql';
import { ToastContainer, toast } from 'react-toastify';
import DocumentationEntry from './types/DocumentationEntry';
import { Loading } from '../components/Loading';
import { ConfirmationDialog } from '../components/ConfirmationDialog';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { DocumentationSidebar } from './common_components/DocumentationSidebar';
import { ComponentsList } from './common_components/ComponentsList';
import { TabPanel } from '../components/Tabs';

import { LoadingButton } from '@mui/lab';
import { DocumentsList } from './DocumentsList';
import NoSelectedProject from '../components/NoSelectedProject';
import { EmptyDocumentState } from './common_components/EmptyDocumentState';
import DocumentationTemplateView from './DocumentationTemplateView';
import DocumentationResultView from './DocumentationResultView';
import DocumentationEditorView from './DocumentationEditorView';
import CreateDocumentationModal from './DocumentationCreationModal';

export const UPDATE_DOCUMENTATION_MUTATION = gql(`
  mutation UpdateDocumentation($components: [DocumentComponentInput], $id: String!, $name: String!) {
      updateDocumentation(components: $components, documentationId: $id, name: $name) {
      __typename
      documentation {
        __typename
        id
        components {
          type
          name
          dataSource
          query
          args
        }
        name
        id
        project {
          id
          title
          experiments {
            id
          }
        }
        editableFile {
          fileName
          content
        }
      }
    }
  }
`);

export const GET_DOCUMENTATION = gql(`
query Documentation($documentation_id:String!) {
  documentation(id:$documentation_id) {
    __typename
    documentationType
    components {
      type
      name
      dataSource
      query
      args
    }
    name
    id
    project {
      id
      title
      experiments {
        id
      }
    }
    editableFile {
      fileName
      content
    }
  }
}`);

export const DocumentationPage = () => {
  const { documentationId, projectId } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // only edit mode
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  // only edit mode
  const [touched, setTouched] = useState(false);

  const { data: documentation, networkStatus } = useQuery<DocumentationQuery>(
    GET_DOCUMENTATION,
    {
      variables: {
        documentation_id: documentationId ?? '',
      },
      skip: !documentationId,
      notifyOnNetworkStatusChange: true,
    }
  );

  // only edit mode
  const components = useMemo(
    () => documentation?.documentation?.components ?? [],
    [documentation]
  );

  const documentationEntries = useMemo(() => {
    return (
      components?.map((component, idx) => ({
        id: idx.toString(),
        name: component?.name ?? component?.type ?? 'CustomText',
        type: DocumentComponentTypeEnum[component?.type ?? 'CustomText'],
        query: component?.query ?? '',
        args: component?.args ?? [],
      })) ?? []
    );
  }, [components]);

  const [documentComponents, setDocumentComponents] =
    useState<DocumentationEntry[]>(documentationEntries);

  useEffect(() => {
    setDocumentComponents(documentationEntries);
  }, [documentationEntries]);

  const addComponentToItems = (newItem: DocumentationEntry) => {
    setTouched(true);
    setDocumentComponents([...documentComponents, newItem]);
  };

  const deleteById = (id: string) => {
    setTouched(true);
    const newState = deleteAtIndex(documentComponents, id);
    setDocumentComponents(newState);
  };
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [editorContent, setEditorContent] = useState(
    documentation?.documentation?.editableFile?.content as string
  );

  const onDragEnd = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setTouched(true);
      setDocumentComponents(reorder(documentComponents, dragIndex, hoverIndex));
    },
    [documentComponents, setDocumentComponents]
  );

  const [saveDocumentation, { loading: isSaveLoading }] = useMutation(
    UPDATE_DOCUMENTATION_MUTATION
  );

  const handleSaveDocumentation = async () => {
    saveDocumentation({
      onCompleted: () => {
        setShowConfirmationDialog(false);
        setTouched(false);
        searchParams.set('view', 'generated');
        toast.success('Documentation saved');
      },
      onError: () => toast.error('Failed'),
      variables: {
        components: documentComponents.map(item => ({
          query: item.query ?? '',
          dataSource:
            (item.dataSource as DataSourceEnum) ?? DataSourceEnum.Code,
          componentType: item.type,
          args: [],
          name: item.name,
        })),
        name: documentation?.documentation?.name ?? '', // TODO: fix me
        id: documentationId ?? '',
      },
    });
  };

  const handleSaveDocumentationItem = (updatedItem: DocumentationEntry) => {
    const newState = documentComponents.map(item => {
      if (item.id === updatedItem.id) {
        return updatedItem;
      }
      return item;
    });
    setDocumentComponents(newState);
    setTouched(true);
  };

  const handleCancelChanges = () => {
    if (touched) {
      setShowConfirmationDialog(true);
    } else {
      navigate(`/documentation/${projectId}/${documentationId}`);
    }
  };

  const handleCloseWithoutSaving = () => {
    setDocumentComponents(documentationEntries);
    setShowConfirmationDialog(false);
    setTouched(false);
    searchParams.set('view', 'generated');
  };
  const handleSaveAndClose = () => {
    handleSaveDocumentation();
  };

  const getDisplayedTabs = () => {
    const displayedTabs = [];
    if (showDocumentationTemplatePreview) displayedTabs.push('template');
    if (showGeneratedDocumentationPreview) displayedTabs.push('generated');
    if (showDocumentationEditorView) displayedTabs.push('edit');
    return displayedTabs;
  };

  const getTabValue = () => {
    const view = searchParams.get('view') as string;
    const displayedTabs = getDisplayedTabs();

    const tabIndex = displayedTabs.indexOf(view);
    if (tabIndex === -1) {
      return 0;
    }
    return tabIndex;
  };

  const documentType = documentation?.documentation?.documentationType;

  const showDocumentationTemplatePreview =
    documentType === DocumentationTypeEnum.Generated;
  const showGeneratedDocumentationPreview =
    documentType === DocumentationTypeEnum.Generated ||
    documentType === DocumentationTypeEnum.FixedGenerated;
  const showDocumentationEditorView =
    documentType === DocumentationTypeEnum.Generated ||
    documentType === DocumentationTypeEnum.Proxy ||
    documentType === DocumentationTypeEnum.FixedGenerated;

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    if (touched) {
      setShowConfirmationDialog(true);
    } else {
      searchParams.set('view', getDisplayedTabs()[newValue]);
    }
    setSearchParams(searchParams);
  };

  if (!projectId) {
    return <NoSelectedProject />;
  }

  return (
    <DocumentationPageLayout>
      <DocumentationPageLayout.Menu>
        {searchParams.get('view') !== 'template' ? (
          <DocumentationSidebar pageTitle="Documents">
            <DocumentsList onCreateNew={() => setIsCreateModalOpen(true)} />
          </DocumentationSidebar>
        ) : (
          <DocumentationSidebar
            pageTitle="Edit document"
            {...(touched && {
              cancelButtonProps: {
                action: handleCancelChanges,
                text: 'Cancel',
              },
              submitButtonProps: {
                action: handleSaveAndClose,
                text: 'Save',
                loading: isSaveLoading,
              },
            })}
          >
            <ComponentsList
              onAdd={addComponentToItems}
              usedComponents={documentComponents}
            />
          </DocumentationSidebar>
        )}
      </DocumentationPageLayout.Menu>

      <DocumentationPageLayout.Content>
        {(() => {
          if (networkStatus === NetworkStatus.loading) {
            return <Loading message="Loading..." />;
          } else if (
            documentationId === undefined &&
            networkStatus === NetworkStatus.ready
          ) {
            return (
              <EmptyDocumentState
                title="No documentation selected"
                text="Select documentation to view"
              />
            );
          } else {
            return (
              <Box
                sx={{
                  width: '100%',
                  maxWidth: '1000px',
                  backgroundColor: '#fff',
                  borderRadius: '16px',
                  overflowY: 'auto',
                }}
              >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={getTabValue()} onChange={handleChangeTab}>
                    {showDocumentationTemplatePreview && (
                      <Tab label="Template preview" />
                    )}
                    {showGeneratedDocumentationPreview && (
                      <Tab label="Generated document" />
                    )}
                    {showDocumentationEditorView && <Tab label="Edit" />}
                  </Tabs>
                </Box>
                <Suspense fallback={<Loading message="Loading..." />}>
                  {showDocumentationTemplatePreview && (
                    <TabPanel
                      value={getTabValue()}
                      index={getDisplayedTabs().indexOf('template')}
                    >
                      <DocumentationTemplateView
                        documentComponents={documentComponents}
                        deleteById={deleteById}
                        onDragEnd={onDragEnd}
                        handleSaveDocumentationItem={
                          handleSaveDocumentationItem
                        }
                        handleSaveDocumentation={handleSaveDocumentation}
                        isSaveDocumentationLoading={isSaveLoading}
                      />
                    </TabPanel>
                  )}
                  {showGeneratedDocumentationPreview && (
                    <TabPanel
                      value={getTabValue()}
                      index={getDisplayedTabs().indexOf('generated')}
                    >
                      <DocumentationResultView
                        setEditorContent={setEditorContent}
                      />
                    </TabPanel>
                  )}
                  {showDocumentationEditorView && (
                    <TabPanel
                      value={getTabValue()}
                      index={getDisplayedTabs().indexOf('edit')}
                    >
                      <DocumentationEditorView
                        documentationId={documentationId as string}
                        editorContent={editorContent}
                      />
                    </TabPanel>
                  )}
                </Suspense>
              </Box>
            );
          }
        })()}
      </DocumentationPageLayout.Content>
      <CreateDocumentationModal
        open={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        projectId={projectId || ''}
      />

      <ToastContainer />
      <ConfirmationDialog
        onClose={() => setShowConfirmationDialog(false)}
        showConfirmationDialog={showConfirmationDialog}
        title="You have unsaved changes"
        text="Are you sure you want to leave this page without saving?"
        actions={
          <>
            <Button onClick={() => setShowConfirmationDialog(false)}>
              Cancel
            </Button>
            <Button onClick={handleCloseWithoutSaving}>
              Close without saving
            </Button>
            <LoadingButton
              loading={isSaveLoading}
              loadingIndicator={<CircularProgress size={24} />}
              size="small"
              variant="contained"
              onClick={handleSaveAndClose}
              autoFocus
            >
              Save & Close
            </LoadingButton>
          </>
        }
      />
    </DocumentationPageLayout>
  );
};

export function deleteAtIndex(array: DocumentationEntry[], index: string) {
  const newArr = array.filter(item => item.id !== index);
  return newArr;
}

export function reorder<T>(
  list: T[],
  startIndex: number,
  endIndex: number
): T[] {
  const result: T[] = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}
