import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  Typography,
} from '@mui/material';
import { User } from '../__generated__/gql/graphql';
import {
  ResponsibilityDisplayMap,
  RoleDisplayMap,
} from '../user_management/UserRow';

export const Profile = ({ user }: { user: User }) => {
  return (
    <Card variant="outlined" sx={{ width: '100%' }}>
      <CardContent>
        <Typography variant="h5" component="div">
          Profile
        </Typography>

        <Box
          sx={{
            display: 'flex',
            gap: '32px',
            paddingTop: '32px',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              flex: '1 1 33%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Avatar sx={{ width: 128, height: 128, fontSize: '64px' }}>
              {user.name?.slice(0, 1)}
            </Avatar>
          </Box>
          <Box
            sx={{
              flex: '2 1 67%',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '16px',
                alignItems: 'start',
                flexDirection: 'column',
              }}
            >
              <Box sx={{ gap: '4px' }}>
                <Typography sx={{ fontWeight: '700' }}>Email:</Typography>
                <Typography>{user.email}</Typography>
              </Box>
              {user.name && (
                <Box sx={{ gap: '4px' }}>
                  <Typography sx={{ fontWeight: '700' }}>Name:</Typography>
                  <Typography>{user.name}</Typography>
                </Box>
              )}
              <Box sx={{ gap: '4px' }}>
                <Typography sx={{ fontWeight: '700' }}>
                  Organization:
                </Typography>
                <Typography>{user.orgganizationName}</Typography>
              </Box>
              <Box sx={{ gap: '4px' }}>
                <Typography sx={{ fontWeight: '700' }}>Roles:</Typography>
                <Typography>
                  {user.roles && user.roles.length > 0
                    ? user.roles
                        .map(role => {
                          if (role) {
                            return RoleDisplayMap[role];
                          }
                          return '';
                        })
                        .reduce(
                          (acc, curr) => (acc ? acc + ', ' + curr : curr),
                          ''
                        )
                    : ''}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ gap: '4px' }}>
              <Typography sx={{ fontWeight: '700' }}>
                Responsibilities:
              </Typography>
              {user.responsibilities?.map((responsibility, index) => {
                if (responsibility) {
                  return (
                    <Chip
                      label={ResponsibilityDisplayMap[responsibility]}
                      key={index}
                      sx={{ margin: '0.2rem' }}
                      color="info"
                    />
                  );
                }
                return <></>;
              })}
            </Box>

            {
              // we only show email here if we don't use it for the "header" instead of the name
              user.name && (
                <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                  <Typography>Email:</Typography>
                  <Typography>{user.email}</Typography>
                </Box>
              )
            }
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
