import { Chip } from '@mui/material';
import { colorThemes } from '../../theme';

interface Props {
  factResult: string;
}

export function FactViewer({ factResult }: Props): JSX.Element {
  return (
    <Chip
      label={factResult}
      variant="outlined"
      sx={{
        borderRadius: '4px',
        backgroundColor: colorThemes.DARK_BLUE_400,
        border: 'none',
      }}
    />
  );
}
