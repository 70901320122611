import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerProps,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { colorThemes } from '../theme';
import {
  CheckCircle,
  CheckCircleOutline,
  RadioButtonChecked,
} from '@mui/icons-material';
import { TRequirement } from './useIsoCompletionStatus';

function StatusBadge({
  number,
  isFullfilled,
}: {
  number: string;
  isFullfilled: boolean;
}) {
  return isFullfilled ? (
    <CheckCircle />
  ) : (
    <Box
      height="24px"
      width="24px"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Typography
        component="span"
        borderRadius="9999px"
        bgcolor={colorThemes.GREY_100}
        height="20px"
        width="20px"
        color="black"
        display="flex"
        justifyContent="center"
        fontSize="12px"
        alignItems="center"
      >
        {number}
      </Typography>
    </Box>
  );
}

export type SectionDrawerProps = DrawerProps & {
  requirement: TRequirement;
};

const cardProps = {
  padding: '12px',
  bgcolor: colorThemes.DARK_BLUE_800,
  borderRadius: '8px',
};

const headingProps = {
  component: 'h3',
  fontSize: '20px',
  fontWeight: 600,
};

export function RequirementDrawer({
  requirement: { owner, reviewer, title, description, guidance, evidence },
  ...props
}: SectionDrawerProps) {
  const isAssigned = !!(owner && reviewer && owner !== 'Not Assigned');
  const isDone = isAssigned && (evidence?.every(e => e?.isFulfilled) ?? true);
  const isApproved = isDone;

  return (
    <Drawer anchor="right" PaperProps={{ sx: { width: '66%' } }} {...props}>
      <Box padding="24px" color={colorThemes.GREY_100}>
        <Typography component="h2" fontSize="26px" fontWeight={700}>
          {title}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns="max-content max-content 1fr max-content max-content "
          gridTemplateRows="max-content max-content 1fr max-content max-content"
          columnGap="12px"
          marginTop="24px"
        >
          <span>Owner:</span>
          <span>{owner}</span>
          <div />
          <span>Created:</span>
          {/* TODO Add createdAt */}
          <span>20.01.2024</span>
          <span>Approver: </span>
          <span>{reviewer}</span>
          <div />
          <span>Last edited:</span>
          {/* TODO Add updatedAt */}
          <span>20.02.2024</span>
        </Box>
        <Divider sx={{ marginY: '12px', bgcolor: '#295581' }} />
        <Box display="flex" flexDirection="column" gap="11px">
          <Box display="grid" gridTemplateColumns="4fr 6fr" gap="20px">
            <Box {...cardProps} display="flex" flexDirection="column">
              <Typography {...headingProps}>Status</Typography>
              <Box
                display="grid"
                gridTemplateColumns="1fr 2fr 1fr 2fr 1fr"
                alignItems="center"
                gap="20px"
                flex={1}
                paddingX="8px"
                marginY="45px"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  gap="8px"
                  fontWeight={500}
                >
                  <StatusBadge number="1" isFullfilled={isAssigned} />
                  <Typography>Assigned</Typography>
                </Box>
                <Divider sx={{ height: '1px' }} />
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  gap="8px"
                  fontWeight={500}
                >
                  <StatusBadge number="2" isFullfilled={isDone} />
                  Done
                </Box>
                <Divider sx={{ height: '1px' }} />
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  gap="8px"
                  fontWeight={500}
                >
                  <StatusBadge number="3" isFullfilled={isApproved} />
                  <Typography>Approved</Typography>
                </Box>
              </Box>
            </Box>
            <Box {...cardProps}>
              <Typography {...headingProps} marginBottom="16px">
                Description
              </Typography>
              <Typography>{description}</Typography>
            </Box>
          </Box>
          <Box {...cardProps}>
            <Typography {...headingProps} marginBottom="16px">
              Guidance
            </Typography>
            <Typography>{guidance}</Typography>
          </Box>
          <Box display="flex" gap="11px">
            <Box
              {...cardProps}
              flex={1}
              display="flex"
              flexDirection="column"
              gap="16px"
            >
              <Typography {...headingProps}>Evidence</Typography>
              <Box display="grid" gridTemplateColumns="1fr 1fr" gap="40px">
                {evidence?.map(evidence => (
                  <Box
                    padding="12px"
                    bgcolor="#295581"
                    borderRadius="8px"
                    display="flex"
                    key={evidence?.id}
                  >
                    {evidence?.description}
                    {evidence.isFulfilled ? (
                      <CheckCircleOutline />
                    ) : (
                      <RadioButtonChecked />
                    )}
                  </Box>
                ))}
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                marginBottom="20px"
                gap="20px"
              >
                or
                <Button variant="contained">Add individual Evidence</Button>
              </Box>
            </Box>
            <Box {...cardProps} flex={1}>
              <Typography {...headingProps}>Specs</Typography>
              <List>
                {evidence
                  ?.flatMap(x => x?.specifications)
                  ?.map(spec => (
                    <ListItem
                      key={spec?.name}
                      sx={{ display: 'flex', gap: '4px' }}
                    >
                      {spec?.isFulfilled ? (
                        <CheckCircleOutline />
                      ) : (
                        <RadioButtonChecked />
                      )}
                      {spec?.name}
                    </ListItem>
                  ))}
              </List>
            </Box>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}
