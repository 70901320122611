import { Box, ButtonGroup, Typography } from '@mui/material';
import { TabPanel } from '../components/Tabs';
import { DocumentationStructureItem } from './component_cards/DocumentationStructureItem';
import DocumentationEntry from './types/DocumentationEntry';
import { LoadingButton } from '@mui/lab';
import { colorThemes } from '../theme';

type DocumentationTemplatePreviewProps = {
  documentComponents: DocumentationEntry[];
  deleteById: (id: string) => void;
  onDragEnd: (dragIndex: number, hoverIndex: number) => void;
  handleSaveDocumentationItem: (documentationItem: DocumentationEntry) => void;
  handleSaveDocumentation: () => void;
  isSaveDocumentationLoading: boolean;
};

export default function DocumentationTemplateView({
  documentComponents,
  deleteById,
  onDragEnd,
  handleSaveDocumentationItem,
  handleSaveDocumentation,
  isSaveDocumentationLoading,
}: DocumentationTemplatePreviewProps) {
  return (
    <>
      <TabPanel.Header>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography>Template</Typography>
          <ButtonGroup>
            <LoadingButton
              variant="contained"
              onClick={handleSaveDocumentation}
              loading={isSaveDocumentationLoading}
              sx={{
                '&:hover': {
                  backgroundColor: colorThemes.YELLOW_200,
                },
                '&:disabled': {
                  backgroundColor: colorThemes.YELLOW_600,
                },
              }}
            >
              Save
            </LoadingButton>
          </ButtonGroup>
        </Box>
      </TabPanel.Header>
      <TabPanel.Content>
        {documentComponents.map((component, index) => (
          <DocumentationStructureItem
            key={component.id}
            isDragEnabled={true}
            documentationEntry={component}
            onDelete={(id: string) => deleteById(id)}
            onDragEnd={onDragEnd}
            index={index}
            onSave={handleSaveDocumentationItem}
          />
        ))}
      </TabPanel.Content>
    </>
  );
}
