import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';
import { TabPanel } from '../components/Tabs';
import { Box, ButtonGroup, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { colorThemes } from '../theme';
import { gql, useMutation } from '@apollo/client';
import { useRef } from 'react';
import { toast } from 'react-toastify';

const UPDATE_EDITABLE_FILE = gql`
  mutation UpdateEditableFile(
    $documentationId: String!
    $editableFileContent: String!
  ) {
    updateDocumentation(
      documentationId: $documentationId
      editableFileContent: $editableFileContent
    ) {
      __typename
      documentation {
        __typename
        id
        components {
          type
          name
          dataSource
          query
          args
        }
        name
        id
        project {
          id
          title
          experiments {
            id
          }
        }
        editableFile {
          fileName
          content
        }
      }
    }
  }
`;

export default function DocumentationEditorView({
  editorContent,
  documentationId,
}: {
  documentationId: string;
  editorContent: string;
}) {
  const editorRef = useRef<Editor>(null);
  const [updateEditableFile, { loading }] = useMutation(UPDATE_EDITABLE_FILE);

  const handleSave = async () => {
    const content = editorRef.current?.getInstance().getMarkdown();
    try {
      await updateEditableFile({
        variables: {
          documentationId,
          editableFileContent: content || '',
        },
      });
      toast.success('File updated successfully');
    } catch (error) {
      toast.error('Error updating file');
      console.error('Error updating file:', error);
    }
  };

  return (
    <>
      <TabPanel.Header>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography>Editor</Typography>
          <ButtonGroup>
            <LoadingButton
              variant="contained"
              onClick={handleSave}
              loading={loading}
              sx={{
                '&:hover': {
                  backgroundColor: colorThemes.YELLOW_200,
                },
                '&:disabled': {
                  backgroundColor: colorThemes.YELLOW_600,
                },
              }}
            >
              Save
            </LoadingButton>
          </ButtonGroup>
        </Box>
      </TabPanel.Header>
      <TabPanel.Content>
        <Box
          sx={{
            overflowY: 'hidden',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Editor
            ref={editorRef}
            height="100%"
            initialValue={editorContent}
            previewStyle="vertical"
            initialEditType="wysiwyg"
            useCommandShortcut={true}
          />
        </Box>
      </TabPanel.Content>
    </>
  );
}
