import { Box, Button, Link, Typography } from '@mui/material';
import { FC } from 'react';
import { risk, RiskClassSpecialCase, role, specialCase } from './Questions';
import { colorThemes } from '../theme';
import { gql, useMutation } from '@apollo/client';
import { toast, ToastContainer } from 'react-toastify';
import {
  RiskClass,
  RiskClassCategoryEnum,
  RiskClassInput,
  RiskClassRoleEnum,
} from '../__generated__/gql/graphql';

interface QuestionaireResultProps {
  projectId?: string;
  setRiskClass?: (riskClass: RiskClass) => void;
  nextStepRiskClass?: () => void;
}

const UPDATE_PROJECT_MUTATION = gql`
  mutation UpdateRiskClassInProject(
    $riskClass: RiskClassInput
    $projectId: String!
  ) {
    updateProject(projectId: $projectId, riskClass: $riskClass) {
      __typename
      project {
        __typename
        riskClass {
          __typename
          riskClassRole
          riskClassCategory
        }
        id
      }
    }
  }
`;

export const getRiskClassCategoryText = (
  category: RiskClassCategoryEnum | undefined | null | string
) => {
  switch (category) {
    case 'MINIMAL':
      return 'Minimal';
    case 'LIMITED':
      return 'Limited';
    case 'HIGH_EXEMPTIONS':
      return 'High (Exemptions)';
    case 'HIGH':
      return 'High';
    case 'PROHIBITED':
      return 'Prohibited';
    case null:
      return 'Not Defined';
    default:
      return '';
  }
};
export const getRiskClassRoleText = (
  role: RiskClassRoleEnum | undefined | null
) => {
  switch (role) {
    case 'PROVIDER':
      return 'Provider';
    case 'DEPLOYER':
      return 'Deployer';
    case 'PROVIDER_AND_DEPLOYER':
      return 'Provider and Deployer';
    case 'AUTHORIZED_REPRESENTATIVE':
      return 'Authorized Representative';
    case 'IMPORTER':
      return 'Importer';
    case 'DISTRIBUTOR':
      return 'Distributor';
    case 'IMPORTER_AND_DISTRIBUTOR':
      return 'Importer and Distributor';
    case 'OUT_OF_SCOPE':
      return 'Out of Scope';
    default:
      return '';
  }
};

export const getRiskClassSpecialCaseText = (
  specialCase: RiskClassSpecialCase | undefined | null
) => {
  switch (specialCase) {
    case RiskClassSpecialCase.GPAI:
      return 'GPAI: Yes';
    case RiskClassSpecialCase.SYSTEMIC_RISK_GPAI:
      return 'GPAI: Yes (Systemic Risk)';
    case RiskClassSpecialCase.FREE_AND_OPEN_SOURCE_GPAI:
      return 'GPAI: Yes (Free & Open-Source)';
    case null:
      return 'GPAI: Not Defined';
    default:
      return '';
  }
};

const QuestionaireResult: FC<QuestionaireResultProps> = ({
  projectId,
  setRiskClass,
  nextStepRiskClass,
}) => {
  const riskClass: RiskClassInput = {
    riskClassCategory: risk,
    riskClassRole: role,
  };

  const [updateProject] = useMutation(UPDATE_PROJECT_MUTATION, {
    variables: {
      projectId,
      riskClass,
    },
  });
  const submitUpdateRiskClass = async () => {
    try {
      await updateProject();
      toast.success('Successfully saved Risk Class');
    } catch (e) {
      toast.error('Saving Risk Class failed');
    }
  };

  const submitCreateRiskClass = () => {
    if (setRiskClass) {
      setRiskClass(riskClass as RiskClass);
    }
    if (nextStepRiskClass) {
      nextStepRiskClass();
    }
  };

  return (
    <Box
      bgcolor={'rgba(0, 123, 255, 0.2)'}
      width={'80%'}
      p={4}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      flexDirection={'column'}
      borderRadius={'20px'}
      gap={2}
      border={'1px solid'}
      borderColor={colorThemes.ACTION_BLUE}
    >
      <Typography fontSize={'24px'}>
        Role: {getRiskClassRoleText(role)}
      </Typography>
      {role !== 'OUT_OF_SCOPE' &&
        (!risk ? (
          <Typography fontSize={'24px'}>
            {getRiskClassSpecialCaseText(specialCase)}
          </Typography>
        ) : (
          <Typography fontSize={'24px'}>
            Risk: {getRiskClassCategoryText(risk)}
          </Typography>
        ))}
      {setRiskClass ? (
        <Button
          sx={{ mt: '40px' }}
          variant={'contained'}
          color={'primary'}
          onClick={submitCreateRiskClass}
        >
          Save
        </Button>
      ) : (
        <Button
          sx={{ mt: '40px' }}
          variant={'contained'}
          color={'primary'}
          onClick={submitUpdateRiskClass}
          component={Link}
          href={'/projects'}
        >
          Save
        </Button>
      )}
      <ToastContainer />
    </Box>
  );
};

export default QuestionaireResult;
