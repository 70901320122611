import { gql, useSuspenseQuery } from '@apollo/client';
import { IsoCompletionStatusQuery } from '../__generated__/gql/graphql';
import React from 'react';

export const GET_ISO_COMPLETION_STATUS = gql(`
  query isoCompletionStatus {
    iso42001CompletionStatus {
      title
      requirements {
        title
        owner {
          name
        }
        reviewer {
          name
        }
        description
        evidence {
          id
          description
          url
          specifications {
            name
            isFulfilled
          }
          isFulfilled
        }
        guidance
      }
    }
  }
`);

export function useIsoCompletionStatus() {
  const { data: isoCompletionStatus } =
    useSuspenseQuery<IsoCompletionStatusQuery>(GET_ISO_COMPLETION_STATUS);

  // We are mapping over the data to add accumulated properties for requirements,
  // evidence and specifications.
  return React.useMemo(
    () =>
      isoCompletionStatus.iso42001CompletionStatus?.map(category => {
        const requirements = category?.requirements?.map(requirement => {
          const evidence = requirement?.evidence?.map(evidence => ({
            ...evidence,
            numberOfSpecs: evidence?.specifications?.length ?? 0,
            numberOfFulfilledSpecs:
              evidence?.specifications?.filter(
                specification => specification?.isFulfilled
              ).length ?? 0,
          }));

          return {
            ...requirement,
            title: requirement?.title ?? '',
            owner: requirement?.owner?.name ?? '',
            reviewer: requirement?.reviewer?.map(user => user?.name).join(', '),
            numberOfEvidence: requirement?.evidence?.length ?? 0,
            numberOfFulfilledEvidence:
              requirement?.evidence?.filter(evidence => evidence?.isFulfilled)
                .length ?? 0,
            numberOfSpecs:
              evidence?.reduce(
                (acc, evidence) => (acc += evidence?.numberOfSpecs),
                0
              ) ?? 0,
            numberOfFullfilledSpecs:
              evidence?.reduce(
                (acc, evidence) => (acc += evidence?.numberOfFulfilledSpecs),
                0
              ) ?? 0,
            evidence,
          };
        });

        return {
          ...category,
          numberOfRequirements: category?.requirements?.length ?? 0,
          numberOfFulfilledRequirements:
            requirements?.filter(
              requirement =>
                requirement?.numberOfFulfilledEvidence ===
                requirement?.numberOfEvidence
            ).length ?? 0,
          numberOfSpecs:
            requirements?.reduce(
              (acc, requirement) => (acc += requirement?.numberOfSpecs),
              0
            ) ?? 0,
          numberOfFullfilledSpecs: requirements?.reduce(
            (acc, requirement) => (acc += requirement?.numberOfFullfilledSpecs),
            0
          ),
          numberOfEvidence:
            requirements?.reduce(
              (acc, requirement) => (acc += requirement?.numberOfEvidence),
              0
            ) ?? 0,
          numberOfFullfilledEvidence:
            requirements?.reduce(
              (acc, requirement) =>
                (acc += requirement?.numberOfFulfilledEvidence),
              0
            ) ?? 0,
          requirements,
        };
      }) ?? [],
    [isoCompletionStatus]
  );
}

export type TIsoCompletionData = ReturnType<typeof useIsoCompletionStatus>;
export type TCategory = TIsoCompletionData[number];
export type TRequirement = NonNullable<TCategory['requirements']>[number];
export type TEvidence = NonNullable<TRequirement['evidence']>[number];
export type TSpecification = NonNullable<TEvidence['specifications']>[number];
