import { Box } from '@mui/material';
import { colorThemes } from '../../theme';

const DocumentationPageLayout = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <Box display="flex" height="100%">
      {children}
    </Box>
  );
};

const DocumentationPageLayoutMenu = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <Box
    sx={{
      minWidth: '300px',
      maxWidth: '300px',
      height: '100vh',
      backgroundColor: colorThemes.DARK_BLUE_600,
      borderWidth: '0 2px',
      borderColor: colorThemes.GREY_500,
      borderStyle: 'solid',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
    }}
  >
    {children}
  </Box>
);

const DocumentationPageLayoutContent = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <Box
    sx={{
      width: '100%',
      margin: 4,
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    {children}
  </Box>
);

DocumentationPageLayout.Menu = DocumentationPageLayoutMenu;
DocumentationPageLayout.Content = DocumentationPageLayoutContent;

export default DocumentationPageLayout;
