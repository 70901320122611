import {
  RiskClassCategoryEnum,
  RiskClassRoleEnum,
} from '../__generated__/gql/graphql';
import { Answer } from './RiskClassification';

export enum RiskClassSpecialCase {
  GPAI = 'GPAI System',
  SYSTEMIC_RISK_GPAI = 'Systemic Risk GPAI',
  FREE_AND_OPEN_SOURCE_GPAI = 'Free and Open-Source GPAI',
}

const questionType = {
  MULTIPLE_CHOICE: 'multiple-choice',
  SINGLE_CHOICE: 'single-choice',
} as const;

export type QuestionaireQuestion = {
  id: number;
  title: string;
  description: string;
  options: { name: string; value: number }[];
  type: keyof typeof questionType;
  category: number;
};

export const RiskQuestionnaireCategories = [
  'Role',
  'Exemptions and Prohibitions',
  'GPAI System',
  'High Risk AI System',
  'Limited Risk AI System',
];

export const possibleRiskOnQuestion: {
  [key: number]: RiskClassCategoryEnum[];
} = {
  9: [RiskClassCategoryEnum.Minimal],
  10: [RiskClassCategoryEnum.Minimal],
  12: [RiskClassCategoryEnum.Prohibited],
  19: [RiskClassCategoryEnum.High],
  21: [RiskClassCategoryEnum.High],
  22: [RiskClassCategoryEnum.Limited],
  23: [RiskClassCategoryEnum.Limited, RiskClassCategoryEnum.Limited],
};
export const questionaireQuestions: QuestionaireQuestion[] = [
  {
    id: 0,
    title: 'Are you developing or procuring an AI system?',
    description:
      'Developing or procuring the AI system has an impact on your potential obligations under the EU AI Act.\n\nHint: Fine-tuning is likely considered developing, while retrieval augmented generation likely is not considered developing under the Act. If you are merely integrating and using a third-party AI system without any modification, you are not considered as a developer.',
    options: [
      {
        name: "I'm the developer of the AI system (or someone developed it in my name)",
        value: 0,
      },
      {
        name: "I'm the developer of the AI system (or someone developed it in my name), and I'm using it myself",
        value: 1,
      },
      { name: "I'm procuring and using the AI system myself", value: 2 },
      { name: 'None of these', value: 3 },
    ],
    type: 'SINGLE_CHOICE',
    category: 0,
  },
  {
    id: 1,
    title: 'Do you make the AI system available within the EU?',
    description:
      'Meaning: Are you the first one supplying the AI system for distribution or use on the EU market in the course of a commercial activity (whether for payment or free of charge)?',
    options: [
      { name: "Yes, I'm making it available in the EU", value: 0 },
      { name: "No, I'm not the first", value: 1 },
    ],
    type: 'SINGLE_CHOICE',
    category: 0,
  },
  {
    id: 2,
    title: 'Are you putting the AI system into service in the EU?',
    description:
      'Meaning: Are you the first one supplying the AI system directly to a deployer ("user"), including yourself?\n\nHint: Both for internal or external use of the AI system.',
    options: [
      { name: "Yes, I'm putting it into service", value: 0 },
      { name: "No, I'm not the first", value: 1 },
    ],
    type: 'SINGLE_CHOICE',
    category: 0,
  },
  {
    id: 3,
    title: 'Are you doing so under your own name or trademark?',
    description: 'Meaning: The AI system is associated with your organization.',
    options: [
      { name: "Yes, it's under my name or brand", value: 0 },
      { name: "No, it's the name or brand of someone else", value: 1 },
    ],
    type: 'SINGLE_CHOICE',
    category: 0,
  },
  {
    id: 4,
    title:
      "Did you substantially modify the AI system or change it's intended purpose?",
    description:
      "Changing an AI system (in it's intended purpose) after it has been already placed on the market or been put into operation can impact your obligations under the EU AI Act.",
    options: [
      {
        name: "Yes, I changed the system a lot or changed it's purpose",
        value: 0,
      },
      { name: 'No, I did not', value: 1 },
    ],
    type: 'SINGLE_CHOICE',
    category: 0,
  },
  {
    id: 5,
    title:
      'Will you integrate the AI system as a safety component into the following products and put your name or trademark on it?',
    description:
      "The relevant products include (see Annex I, section A of the AI Act):\nMachinery, toy safety, recreational craft or personal watercraft, lifts and it's safety components, equipment and protective systems in potentially explosive atmospheres, radio equipment, pressure equipment, cableway installations, personal protective equipment, appliances to burn gaseous fuels, medical devices and in-vitro diagnostic medical devices.",
    options: [
      {
        name: 'Yes, it will be integrated as a product safety component in one of these categories',
        value: 0,
      },
      { name: 'No, it will not', value: 1 },
    ],
    type: 'SINGLE_CHOICE',
    category: 0,
  },
  {
    id: 6,
    title: 'Are you located or established in the EU?',
    description: '',
    options: [
      { name: 'Yes, I am', value: 0 },
      { name: 'No, I am not located or established in the EU', value: 1 },
    ],
    type: 'SINGLE_CHOICE',
    category: 0,
  },
  {
    id: 7,
    title:
      'Will the output of the AI system be used (by recipients) in the EU?',
    description:
      'This can also be true even though you are not based in the EU.',
    options: [
      { name: 'Yes, it will likely be used within the EU', value: 0 },
      { name: "No, it's not", value: 1 },
    ],
    type: 'SINGLE_CHOICE',
    category: 0,
  },
  {
    id: 8,
    title:
      'Are you carrying out the AI Act obligations on behalf of an AI system provider?',
    description:
      'Meaning: You are authorized by written mandate to represent a provider who is based outside the EU.',
    options: [
      { name: "Yes, I'm representing someone else", value: 0 },
      { name: "No, I'm not", value: 1 },
    ],
    type: 'SINGLE_CHOICE',
    category: 0,
  },
  {
    id: 9,
    title:
      'Are you using the AI system purely for personal, non-professional activities?',
    description: 'There are some exemptions (see Article 2 in the AI Act).',
    options: [
      {
        name: 'Yes, I only use it for personal purposes',
        value: 0,
      },
      { name: 'No, I also use it in a professional context', value: 1 },
    ],
    type: 'SINGLE_CHOICE',
    category: 1,
  },
  {
    id: 10,
    title: 'Does your AI system pursue one of the following purposes?',
    description: 'There are some exemptions (see Article 2 in the AI Act).',
    options: [
      {
        name: "It's only used for scientific research and development",
        value: 0,
      },
      {
        name: "It's exclusively used for military, defense or national security purposes",
        value: 1,
      },
      {
        name: "It's used for law enforcement or judicial cooperation by a non-EU public authority or international organization together with the EU (Member States)",
        value: 2,
      },
      {
        name: 'None of these',
        value: 3,
      },
    ],
    type: 'MULTIPLE_CHOICE',
    category: 1,
  },
  {
    id: 11,
    title: 'Is your AI system released under a free and open-source license?',
    description: 'There are some exemptions (see Article 2 in the AI Act).',
    options: [
      {
        name: "Yes, it's for free and open- source",
        value: 0,
      },
      { name: "No, it's not", value: 1 },
    ],
    type: 'SINGLE_CHOICE',
    category: 1,
  },
  {
    id: 12,
    title: 'Can your AI system be used for one of the following purposes?',
    description:
      'There are some prohibited use-cases (see Article 5 in the AI Act).',
    options: [
      {
        name: "Manipulate or deceive a person (subconsciously), such that this person's behavior or decision-making is distorted, leading to significant harm to someone",
        value: 0,
      },
      {
        name: "Exploit the vulnerabilities of a person due to their age, disability, or social or economic situation, such that this person's behavior or decision-making is distorted, leading to significant harm to someone",
        value: 1,
      },
      {
        name: 'Score or classify people based on social behavior or personality traits ("Social Scoring"), leading to unjustified or unfavorable treatment of them',
        value: 2,
      },
      {
        name: 'Assess or predict the risk of a person committing a crime based on personality traits',
        value: 3,
      },
      {
        name: 'Create or expand facial recognition databases through untargeted scraping of facial images',
        value: 4,
      },
      {
        name: 'Inferring emotions of people at the workplace or educational institutions, except for medical or safety purposes',
        value: 5,
      },
      {
        name: 'Biometric categorization systems deducing or inferring race, political opinions, trade union membership, religious beliefs, sex life, or sexual orientation',
        value: 6,
      },
      {
        name: 'Real-time remote biometric identification in public spaces for law enforcement',
        value: 7,
      },
      {
        name: 'None of the above',
        value: 8,
      },
    ],
    type: 'MULTIPLE_CHOICE',
    category: 1,
  },
  {
    id: 13,
    title: 'Is your AI system a GPAI system?',
    description:
      'A general-purpose AI system is based on a foundational model, which is trained on a large amount of data and can perform various distinct tasks (see Article 3 in the AI Act).',
    options: [
      {
        name: "Yes, it's a GPAI / foundation model",
        value: 0,
      },
      { name: "No, it's not", value: 1 },
    ],
    type: 'SINGLE_CHOICE',
    category: 2,
  },
  {
    id: 14,
    title: 'Does your GPAI model have high capabilities?',
    description:
      'GPAI models that have high capabilities pose systemic risks (see Article 51 in the AI Act).\n\nThe model has high capabilities if one of these conditions is met:\n- The cumulative amount of computing power(used for training) is above 10^ 25 FLOPS\n- The European Commission classifies the GPAI model as a model with high capabilities(e.g.due to the number of parameters, the input or output modalities, or to the reach in the EU market, for instance when the GPAI model is accessible to at least 10,000 business users)',
    options: [
      {
        name: 'Yes, it has high capabilities',
        value: 0,
      },
      { name: 'No, it does not classify as such', value: 1 },
    ],
    type: 'SINGLE_CHOICE',
    category: 2,
  },
  {
    id: 15,
    title: 'Can you argue that your GPAI model does not have a systemic risk?',
    description:
      'GPAI models that have high capabilities pose systemic risks (see Article 51 in the AI Act). If you have sufficiently substantiated arguments that demonstrate to the European Commission that your GPAI model cannot pose a systemic risk, you can avoid additional obligations (see Article 52 in the AI Act).',
    options: [
      {
        name: 'Yes, it definitely has no systemic risk',
        value: 0,
      },
      { name: 'It likely poses a systemic risk', value: 1 },
    ],
    type: 'SINGLE_CHOICE',
    category: 2,
  },
  {
    id: 16,
    title: 'Is the GPAI free and open-source?',
    description:
      'GPAI models that are released under a free and open-source license that allows for the access, usage, modification, and distribution of the model, and whose parameters, including the weights, the information on the model architecture, and the information on model usage, are made publicly available, can avoid additional obligations (see Article 53 in the AI Act).',
    options: [
      {
        name: 'Yes, the GPAI model is free, open-source and meets the conditions above',
        value: 0,
      },
      {
        name: 'No, it does not meet the conditions above',
        value: 1,
      },
    ],
    type: 'SINGLE_CHOICE',
    category: 2,
  },
  {
    id: 17,
    title:
      'Is your AI system used in one of the following products or sectors?',
    description:
      'There are some use-cases that are always classified as high-risk if they act as a safety component of already regulated products or if the system itself is an already regulated product (see Article 6 or Annex I in the AI Act)',
    options: [
      {
        name: 'Civil aviation (security)',
        value: 0,
      },
      {
        name: 'Two-, three- and four-wheeled vehicles',
        value: 1,
      },
      {
        name: 'Agricultural and forestry vehicles',
        value: 2,
      },
      {
        name: 'Motor vehicles and motor vehicle trailers, as well as systems, components or separate technical units of these vehicles',
        value: 3,
      },
      {
        name: 'Marine equipment',
        value: 4,
      },
      {
        name: 'Interoperability of the railway system',
        value: 5,
      },
      {
        name: 'None of the above',
        value: 6,
      },
    ],
    type: 'MULTIPLE_CHOICE',
    category: 3,
  },
  {
    id: 18,
    title:
      'Is your AI system used in one of the following products or sectors?',
    description:
      'There are some use-cases that are always classified as high-risk if they act as a safety component of already regulated products or if the system itself is an already regulated product (see Article 6 or Annex I in the AI Act)',
    options: [
      {
        name: 'Machinery',
        value: 0,
      },
      {
        name: 'Toys',
        value: 1,
      },
      {
        name: 'Recreational craft and personal watercraft',
        value: 2,
      },
      {
        name: 'Lifts and safety components of lifts',
        value: 3,
      },
      {
        name: 'Equipment and protective systems in potentially explosive atmospheres',
        value: 4,
      },
      {
        name: 'Radio equipment',
        value: 5,
      },
      {
        name: 'Pressure equipment',
        value: 6,
      },
      {
        name: 'Ropeways / cableway installations',
        value: 7,
      },
      {
        name: 'Personal protective equipment',
        value: 8,
      },
      {
        name: 'Appliance for burning gaseous fuels',
        value: 9,
      },
      {
        name: 'Medical devices',
        value: 10,
      },
      {
        name: 'In vitro diagnostic medical devices',
        value: 11,
      },
      {
        name: 'None of the above',
        value: 12,
      },
    ],
    type: 'MULTIPLE_CHOICE',
    category: 3,
  },
  {
    id: 19,
    title: 'Do you need to undergo a third-party conformity assessment?',
    description:
      "When you are required to undergo a third-party conformity assessment for your regulated product from the prior question, you'll likely need to comply with the EU AI Act.Please check the applicable laws of your product category(see Annex I, Section A in the AI Act).",
    options: [
      {
        name: 'Yes, a conformity assessment is required',
        value: 0,
      },
      {
        name: "No, it's not",
        value: 1,
      },
    ],
    type: 'SINGLE_CHOICE',
    category: 3,
  },
  {
    id: 20,
    title: 'Is your AI system used in one of the following areas?',
    description:
      'There are some use-cases that are classified as high-risk because they can significantly harm people or their fundamental rights if the AI system fails or is misused. (see Article 6 or Annex III in the AI Act)',
    options: [
      {
        name: 'Biometrics, incl. emotion recognition',
        value: 0,
      },
      {
        name: 'Critical infrastructure, incl. digital infrastructure, road traffic and energy supply',
        value: 1,
      },
      {
        name: 'Education, incl. evaluating learning outcomes and access to education',
        value: 2,
      },
      {
        name: 'Human Resources, incl. recruitment, task allocation and promotion or termination decisions',
        value: 3,
      },
      {
        name: 'Essential private and public benefits or services, incl. finance, insurance, healthcare and emergency services',
        value: 4,
      },
      {
        name: 'Law enforcement',
        value: 5,
      },
      {
        name: 'Migration, asylum and border controls, incl. security risk assessments and examination of residence permits',
        value: 6,
      },
      {
        name: 'Administration of justice and democratic processes, incl. interpreting facts and the law and elections',
        value: 7,
      },
      {
        name: 'None of the above',
        value: 8,
      },
    ],
    type: 'MULTIPLE_CHOICE',
    category: 3,
  },
  {
    id: 21,
    title: 'Can you argue that the high-risk AI system is not harmful?',
    description:
      'A high-risk AI system is not considered posing significant harm to the health, safety or fundamental rights of people if at least one of these conditions is met:\n\n- The AI system performs a narrow procedural task\n- The AI system only improves the result of a previous human activity/n- The AI system only detects deviations from previous decision patterns and does not replace or influence the human assessment/n- The AI system is intended to perform a preparatory task to an assessment relevant for the purposes of the use cases listed in Annex III',
    options: [
      {
        name: "It's not harmful, even though it falls under the high-risk category (i.e. the conditions above are met)",
        value: 0,
      },
      {
        name: 'It could be harmful if the system fails or is misused',
        value: 1,
      },
    ],
    type: 'SINGLE_CHOICE',
    category: 3,
  },
  {
    id: 22,
    title: 'Can your AI system be used for one of the following purposes?',
    description:
      'There are some use-cases that are subject to transparency obligations (see Article 50 in the AI Act).',
    options: [
      {
        name: 'Interacts with people',
        value: 0,
      },
      {
        name: 'Generates synthetic content (e.g., text, video, audio, images)',
        value: 1,
      },
      {
        name: 'None of these',
        value: 2,
      },
    ],
    type: 'MULTIPLE_CHOICE',
    category: 4,
  },
  {
    id: 23,
    title: 'Can your AI system be used for one of the following purposes?',
    description:
      'There are some use-cases that are subject to transparency obligations (see Article 50 in the AI Act).',
    options: [
      {
        name: 'Recognizes the emotions of a person or categorizes biometrics',
        value: 0,
      },
      {
        name: 'Generates or manipulates content (video, audio, images), which can be used for Deep Fakes',
        value: 1,
      },
      {
        name: 'Generates or manipulates text, used to inform the public',
        value: 2,
      },
      {
        name: 'None of these',
        value: 3,
      },
    ],
    type: 'MULTIPLE_CHOICE',
    category: 4,
  },
  {
    id: 24,
    title: 'Your Result:',
    description: '',
    options: [],
    type: 'SINGLE_CHOICE',
    category: 5,
  },
];

export let role: RiskClassRoleEnum;
export let risk: RiskClassCategoryEnum | undefined;
export let specialCase: RiskClassSpecialCase;
function calculateRole(answers: Answer[]): RiskClassRoleEnum {
  if (
    (answers[0]?.values.includes(3) &&
      answers[1]?.values.includes(1) &&
      answers[2]?.values.includes(0) &&
      answers[3]?.values.includes(1) &&
      answers[4]?.values.includes(1) &&
      answers[5]?.values.includes(1)) ||
    (answers[0]?.values.includes(3) &&
      answers[1]?.values.includes(0) &&
      answers[3]?.values.includes(1) &&
      answers[4]?.values.includes(1) &&
      answers[5]?.values.includes(1) &&
      answers[6]?.values.includes(0))
  ) {
    role = RiskClassRoleEnum.Importer;
  }
  if (
    (answers[0]?.values.includes(3) &&
      answers[1]?.values.includes(1) &&
      answers[2]?.values.includes(0) &&
      answers[3]?.values.includes(1) &&
      answers[4]?.values.includes(1) &&
      answers[5]?.values.includes(1)) ||
    (answers[0]?.values.includes(3) &&
      answers[1]?.values.includes(0) &&
      answers[3]?.values.includes(1) &&
      answers[4]?.values.includes(1) &&
      answers[5]?.values.includes(1) &&
      answers[6]?.values.includes(1))
  ) {
    if (role === RiskClassRoleEnum.Importer) {
      role = RiskClassRoleEnum.ImporterAndDistributor;
    } else role = RiskClassRoleEnum.Distributor;
    return role;
  }
  if (
    (answers[0]?.values.includes(1) &&
      answers[1]?.values.includes(0) &&
      answers[6]?.values.includes(1) &&
      answers[7]?.values.includes(0)) ||
    (answers[0]?.values.includes(1) &&
      answers[1]?.values.includes(0) &&
      answers[6]?.values.includes(0)) ||
    (answers[0]?.values.includes(1) &&
      answers[1]?.values.includes(1) &&
      answers[2]?.values.includes(0) &&
      answers[6]?.values.includes(0)) ||
    (answers[0]?.values.includes(1) &&
      answers[1]?.values.includes(1) &&
      answers[2]?.values.includes(0) &&
      answers[6]?.values.includes(1) &&
      answers[7]?.values.includes(0)) ||
    (answers[0]?.values.includes(1) &&
      answers[1]?.values.includes(1) &&
      answers[2]?.values.includes(1) &&
      answers[7]?.values.includes(0)) ||
    (answers[0]?.values.includes(1) &&
      answers[1]?.values.includes(1) &&
      answers[2]?.values.includes(1) &&
      answers[6]?.values.includes(1) &&
      answers[7]?.values.includes(0)) ||
    (answers[0]?.values.includes(1) &&
      answers[1]?.values.includes(0) &&
      answers[6]?.values.includes(1) &&
      answers[7]?.values.includes(1)) ||
    (answers[0]?.values.includes(1) &&
      answers[1]?.values.includes(1) &&
      answers[2]?.values.includes(0) &&
      answers[6]?.values.includes(1) &&
      answers[7]?.values.includes(1)) ||
    (answers[0]?.values.includes(0) && answers[1]?.values.includes(0)) ||
    (answers[0]?.values.includes(0) &&
      answers[1]?.values.includes(1) &&
      answers[2]?.values.includes(0)) ||
    (answers[0]?.values.includes(0) &&
      answers[1]?.values.includes(1) &&
      answers[2]?.values.includes(1) &&
      answers[7]?.values.includes(0)) ||
    (answers[0]?.values.includes(3) &&
      answers[1]?.values.includes(0) &&
      answers[3]?.values.includes(0)) ||
    (answers[0]?.values.includes(3) &&
      answers[1]?.values.includes(0) &&
      answers[3]?.values.includes(1) &&
      answers[4]?.values.includes(0)) ||
    (answers[0]?.values.includes(3) &&
      answers[1]?.values.includes(1) &&
      answers[2]?.values.includes(0) &&
      answers[3]?.values.includes(0)) ||
    (answers[0]?.values.includes(3) &&
      answers[1]?.values.includes(0) &&
      answers[3]?.values.includes(1) &&
      answers[4]?.values.includes(1) &&
      answers[5]?.values.includes(0)) ||
    (answers[0]?.values.includes(3) &&
      answers[1]?.values.includes(1) &&
      answers[2]?.values.includes(0) &&
      answers[3]?.values.includes(1) &&
      answers[4]?.values.includes(0)) ||
    (answers[0]?.values.includes(3) &&
      answers[1]?.values.includes(1) &&
      answers[2]?.values.includes(0) &&
      answers[3]?.values.includes(1) &&
      answers[4]?.values.includes(1) &&
      answers[5]?.values.includes(0))
  ) {
    role = RiskClassRoleEnum.Provider;
  }
  if (
    (answers[0]?.values.includes(2) &&
      answers[6]?.values.includes(1) &&
      answers[7]?.values.includes(0)) ||
    (answers[0]?.values.includes(2) && answers[6]?.values.includes(0)) ||
    (answers[0]?.values.includes(1) &&
      answers[1]?.values.includes(0) &&
      answers[6]?.values.includes(1) &&
      answers[7]?.values.includes(0)) ||
    (answers[0]?.values.includes(1) &&
      answers[1]?.values.includes(0) &&
      answers[6]?.values.includes(0)) ||
    (answers[0]?.values.includes(1) &&
      answers[1]?.values.includes(1) &&
      answers[2]?.values.includes(0) &&
      answers[6]?.values.includes(0)) ||
    (answers[0]?.values.includes(1) &&
      answers[1]?.values.includes(1) &&
      answers[2]?.values.includes(0) &&
      answers[6]?.values.includes(1) &&
      answers[7]?.values.includes(0)) ||
    (answers[0]?.values.includes(1) &&
      answers[1]?.values.includes(1) &&
      answers[2]?.values.includes(1) &&
      answers[6]?.values.includes(0)) ||
    (answers[0]?.values.includes(1) &&
      answers[1]?.values.includes(1) &&
      answers[2]?.values.includes(1) &&
      answers[6]?.values.includes(1) &&
      answers[7]?.values.includes(0))
  ) {
    if (role === RiskClassRoleEnum.Provider) {
      role = RiskClassRoleEnum.ProviderAndDeployer;
    } else {
      role = RiskClassRoleEnum.Deployer;
    }
    return role;
  }
  if (!role) {
    role = RiskClassRoleEnum.OutOfScope;
  }
  return role;
}
const RESULT_PAGE = 24;
export const questionActions: {
  [key: number]: (answers: Answer[]) => number;
} = {
  0: answers => {
    if (answers[0]?.values.includes(2)) return 6;
    else return 1;
  },
  1: answers => {
    if (answers[0]?.values.includes(3) && answers[1]?.values.includes(0))
      return 3;
    else if (
      (answers[0]?.values.includes(0) || answers[0]?.values.includes(1)) &&
      answers[1]?.values.includes(0)
    )
      return 6;
    else return 2;
  },
  2: answers => {
    if (
      answers[0]?.values.includes(0) ||
      answers[0]?.values.includes(1) ||
      (answers[0]?.values.includes(3) &&
        answers[1]?.values.includes(1) &&
        answers[2]?.values.includes(1))
    )
      return 6;
    else return 3;
  },
  7: answers => {
    if (
      answers[0]?.values.includes(3) &&
      answers[1]?.values.includes(1) &&
      answers[2]?.values.includes(1)
    )
      return 8;
    else if (
      (answers[0]?.values.includes(1) &&
        answers[1]?.values.includes(1) &&
        answers[2]?.values.includes(1) &&
        answers[6]?.values.includes(1) &&
        answers[7]?.values.includes(1)) ||
      (answers[0]?.values.includes(0) &&
        answers[1]?.values.includes(1) &&
        answers[2]?.values.includes(1) &&
        answers[7]?.values.includes(1)) ||
      (answers[0]?.values.includes(2) &&
        answers[6]?.values.includes(1) &&
        answers[7]?.values.includes(1))
    ) {
      role = RiskClassRoleEnum.OutOfScope;
      return RESULT_PAGE;
    } else {
      role = calculateRole(answers);
      return 9;
    }
  },
  8: answers => {
    if (answers[8]?.values.includes(1)) {
      role = RiskClassRoleEnum.OutOfScope;
      return RESULT_PAGE;
    } else {
      role = RiskClassRoleEnum.AuthorizedRepresentative;
      return 9;
    }
  },
  // Category exemptions
  9: answers => {
    if (answers[9]?.values.includes(0) && role === RiskClassRoleEnum.Deployer) {
      risk = RiskClassCategoryEnum.Minimal; // PrivateUseExemptionDeployer
      return RESULT_PAGE;
    } else return 10;
  },
  10: answers => {
    if (!answers[10]?.values.includes(3)) {
      risk = RiskClassCategoryEnum.Minimal; // OtherExemption
      return RESULT_PAGE;
    } else return 11;
  },
  12: answers => {
    if (!answers[12]?.values.includes(8)) {
      risk = RiskClassCategoryEnum.Prohibited;
      return RESULT_PAGE;
    } else return 13;
  },
  // Category GPAI
  13: answers => {
    if (answers[13]?.values.includes(1)) return 17;
    else return 14;
  },
  14: answers => {
    if (answers[14]?.values.includes(1) && answers[11]?.values.includes(0))
      return 16;
    else if (answers[14]?.values.includes(0)) return 15;
    else {
      specialCase = RiskClassSpecialCase.GPAI;
      return 17;
    }
  },
  15: answers => {
    if (answers[15]?.values.includes(1) && answers[11]?.values.includes(0))
      return 16;
    else {
      specialCase = RiskClassSpecialCase.SYSTEMIC_RISK_GPAI;
      return 17;
    }
  },
  16: answers => {
    if (answers[16]?.values.includes(0)) {
      specialCase = RiskClassSpecialCase.FREE_AND_OPEN_SOURCE_GPAI;
      return 17;
    } else {
      specialCase = RiskClassSpecialCase.GPAI;
      return 17;
    }
  },
  // Category: high risk ai system
  17: answers => {
    if (!answers[17]?.values.includes(6) && answers[13]?.values.includes(1)) {
      risk = RiskClassCategoryEnum.HighExemptions; // HighRiskExemption
      return RESULT_PAGE;
    } else if (
      !answers[17]?.values.includes(6) &&
      answers[13]?.values.includes(0) &&
      (role === RiskClassRoleEnum.Provider ||
        role === RiskClassRoleEnum.ProviderAndDeployer ||
        role === RiskClassRoleEnum.Deployer)
    ) {
      risk = RiskClassCategoryEnum.HighExemptions; // HighRiskExemption
      return 22;
    } else return 18;
  },
  18: answers => {
    if (answers[18]?.values.includes(12)) return 20;
    else return 19;
  },
  19: answers => {
    if (
      answers[19]?.values.includes(0) &&
      (role === RiskClassRoleEnum.Provider ||
        role === RiskClassRoleEnum.ProviderAndDeployer)
    ) {
      risk = RiskClassCategoryEnum.High;
      return 22;
    } else if (
      answers[19]?.values.includes(0) &&
      role === RiskClassRoleEnum.Deployer
    ) {
      risk = RiskClassCategoryEnum.High;
      return 23;
    } else if (answers[19]?.values.includes(0)) {
      risk = RiskClassCategoryEnum.High;
      return RESULT_PAGE;
    } else return 20;
  },
  20: answers => {
    if (!answers[20]?.values.includes(8)) {
      return 21;
    } else if (
      answers[20]?.values.includes(8) &&
      (role === RiskClassRoleEnum.Provider ||
        role === RiskClassRoleEnum.ProviderAndDeployer)
    )
      return 22;
    else if (
      answers[20]?.values.includes(8) &&
      role === RiskClassRoleEnum.Deployer
    )
      return 23;
    else {
      risk = RiskClassCategoryEnum.Minimal;
      return RESULT_PAGE;
    }
  },
  21: answers => {
    if (answers[21]?.values.includes(1)) {
      risk = RiskClassCategoryEnum.High;
      if (
        role === RiskClassRoleEnum.AuthorizedRepresentative ||
        role === RiskClassRoleEnum.Importer ||
        role === RiskClassRoleEnum.Distributor ||
        role === RiskClassRoleEnum.ImporterAndDistributor
      ) {
        risk = RiskClassCategoryEnum.High;
        return RESULT_PAGE;
      }
    }
    if (
      role === RiskClassRoleEnum.Provider ||
      role === RiskClassRoleEnum.ProviderAndDeployer
    )
      return 22;
    else if (role === RiskClassRoleEnum.Deployer) return 23;
    else {
      risk = RiskClassCategoryEnum.Minimal;
      return RESULT_PAGE;
    }
  },
  22: answers => {
    if (
      answers[22]?.values.includes(2) &&
      role === RiskClassRoleEnum.Provider
    ) {
      if (!specialCase && !risk) {
        risk = RiskClassCategoryEnum.Minimal;
      }
      return RESULT_PAGE;
    } else if (
      (answers[22]?.values.includes(0) || answers[22]?.values.includes(1)) &&
      role === RiskClassRoleEnum.Provider
    ) {
      if (risk !== RiskClassCategoryEnum.High) {
        risk = RiskClassCategoryEnum.Limited; // LimitedRiskAISystemProvider
      }
      return RESULT_PAGE;
    } else return 23;
  },
  23: answers => {
    if (
      (answers[22]?.values.includes(0) || answers[22]?.values.includes(1)) &&
      answers[23]?.values.includes(3) &&
      role === RiskClassRoleEnum.ProviderAndDeployer
    ) {
      if (risk !== RiskClassCategoryEnum.High) {
        risk = RiskClassCategoryEnum.Limited; // LimitedRiskAISystemProvider
      }
      return RESULT_PAGE;
    } else if (
      (answers[22]?.values.includes(0) || answers[22]?.values.includes(1)) &&
      !answers[23]?.values.includes(3) &&
      role === RiskClassRoleEnum.ProviderAndDeployer
    ) {
      if (risk !== RiskClassCategoryEnum.High) {
        risk = RiskClassCategoryEnum.Limited; // LimitedRiskAISystemProvider and LimitedRiskAISystemDeployer
      }
      return RESULT_PAGE;
    } else if (
      (!answers[23]?.values.includes(3) &&
        role === RiskClassRoleEnum.Deployer) ||
      (answers[22]?.values.includes(2) &&
        !answers[23]?.values.includes(3) &&
        role === RiskClassRoleEnum.ProviderAndDeployer)
    ) {
      if (risk !== RiskClassCategoryEnum.High) {
        risk = RiskClassCategoryEnum.Limited; // LimitedRiskAISystemDeployer
      }
      return RESULT_PAGE;
    } else {
      if (!specialCase && !risk) {
        risk = RiskClassCategoryEnum.Minimal;
      }
      return RESULT_PAGE;
    }
  },
};

export function clearRisk() {
  risk = undefined;
}
