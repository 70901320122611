import { Card, CardContent, Typography } from '@mui/material';

type Props = {
  nodeId: string;
};

export default function RootNodeCard({ nodeId }: Props) {
  return (
    <Card sx={{ width: '150px' }}>
      <CardContent>
        <Typography variant="h5" component="div">
          Root Node
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {nodeId}
        </Typography>
      </CardContent>
    </Card>
  );
}
