import { gql, useSuspenseQuery } from '@apollo/client';
import {
  Backdrop,
  Box,
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useState } from 'react';
import EditLimitations from './edit_modals/EditLimitations';
import EditProjectTitle from './edit_modals/EditProjectTitle';
import EditProjectDescription from './edit_modals/EditProjectDescription';
import EditIntentedUseCases from './edit_modals/EditIntentedUseCases';
import DashboardProjectCharter from './DashboardProjectCharter';
import EditBusinessMeasurement from './edit_modals/EditBusinessMeasurement';
import EditUnintentedUseCases from './edit_modals/EditUnintentedUseCases';
import {
  DashboardAnalyticsQuery,
  DashboardDetailsQuery,
  RiskAssessmentFrameworkMaterialized,
} from '../__generated__/gql/graphql';
import { useParams } from 'react-router-dom';
import RiskAssessmentScore from './riskassessment_chart/RiskAssessmentScoreOverview';

export const GET_DETAILS = gql(`
  query dashboardDetails($projectId: String!) {
      project(id: $projectId) {
        id
        title
        limitations
        description
        intentedUseCases
        unintentedUseCases
        useCases,
        businessMeasurement {
          truePositive,
          trueNegative,
          falsePositive,
          falseNegative,
        }
        riskAssessmentFrameworks{
          name
          riskAssessmentReference{
            id
            riskSections{
              ... on StandardRiskSection {
              frequency
              impactSystem
              impactUser
              impactBusiness
              severity
              residualRisk
              acceptRiskWithoutTreatment
            }
            ... on TrailRiskSection {
              frequency
              severity
              residualRisk
              acceptRiskWithoutTreatment
            }
          }
        }
      }
    }
  }
`);

export const GET_ANS = gql(`
  query dashboardAnalytics($projectId: String!) {
    dashboardAnalytics(id: $projectId){
      numExperiments
      numTotalInstanceRuns
      numTotalInstanceRunsCompleted
      bestExperiment {
        experimentId
        businessValue
      }
    }
  }
`);

export default function DashboardDetails() {
  const { projectId } = useParams<{ projectId: string }>() as {
    projectId: string;
  };

  const { data: dashboardDetails } = useSuspenseQuery<DashboardDetailsQuery>(
    GET_DETAILS,
    {
      variables: { projectId },
    }
  );

  const { data: dashboardAnalytics } =
    useSuspenseQuery<DashboardAnalyticsQuery>(GET_ANS, {
      variables: { projectId },
    });
  const [titleEditOpen, setTitleEditOpen] = useState(false);
  const [descriptionEditOpen, setDescriptionEditOpen] = useState(false);
  const [limitationsEditOpen, setLimitationsEditOpen] = useState(false);
  const [intentedUseCasesEditOpen, setIntentedUseCasesEditOpen] =
    useState(false);
  const [unintentedUseCasesEditOpen, setUnintentedUseCasesEditOpen] =
    useState(false);
  const [showProjectCharter, setShowProjectCharter] = useState(false);
  const [businessMeasurementEditOpen, setBusinessMeasurementEditOpen] =
    useState(false);

  const handleEditTitleClose = () => {
    setTitleEditOpen(false);
  };
  const handleEditDescriptionClose = () => {
    setDescriptionEditOpen(false);
  };
  const handleEditLimitationsClose = () => {
    setLimitationsEditOpen(false);
  };
  const handleEditIntentedUseCasesClose = () => {
    setIntentedUseCasesEditOpen(false);
  };
  const handleEditUnintentedUseCasesClose = () => {
    setUnintentedUseCasesEditOpen(false);
  };
  const handleProjectCharterClose = () => {
    setShowProjectCharter(false);
  };
  const handleBusinessMeasurementClose = () => {
    setBusinessMeasurementEditOpen(false);
  };

  const bestExperiment = dashboardAnalytics?.dashboardAnalytics?.bestExperiment;

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <Box
        sx={{
          display: 'flex',
          marginLeft: '2%',
          marginRight: '2%',
          marginTop: '1%',
          flexDirection: 'row',
          width: '100%',
        }}
      >
        <Card sx={{ width: '20%', mr: '2%' }}>
          <CardContent>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="body2" color="text.secondary">
                Project Title
              </Typography>
              <IconButton
                style={{ marginTop: '-8px' }}
                color="primary"
                aria-label="edit"
                onClick={() => setTitleEditOpen(true)}
              >
                <ModeEditIcon />
              </IconButton>
            </Box>
            <Typography variant="h5" component="div" sx={{ marginTop: '18px' }}>
              {dashboardDetails?.project?.title ?? ''}
            </Typography>
          </CardContent>
        </Card>

        <Card sx={{ width: '18%', mr: '2%' }}>
          <CardContent>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: '8px',
              }}
            >
              <Typography variant="body2" color="text.secondary">
                Best Experiment
              </Typography>
              {bestExperiment && (
                <IconButton
                  style={{ marginTop: '-8px' }}
                  color="primary"
                  aria-label="edit"
                  onClick={() => setBusinessMeasurementEditOpen(true)}
                >
                  <ModeEditIcon />
                </IconButton>
              )}
            </Box>
            {bestExperiment ? (
              <>
                <Typography variant="h5" component="div">
                  {bestExperiment.experimentId ?? ''}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  marginTop="10px"
                >
                  Business Value
                </Typography>
                <Typography variant="h5" component="div">
                  {bestExperiment.businessValue?.toFixed(3) ?? ''}
                </Typography>
              </>
            ) : (
              <Typography variant="h5" component="div">
                No business measurement given
              </Typography>
            )}
          </CardContent>
        </Card>

        <Card sx={{ width: '18%', mr: '2%' }}>
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              Total Amount of Experiments
            </Typography>
            <Typography variant="h5" component="div" sx={{ marginTop: '18px' }}>
              {dashboardAnalytics?.dashboardAnalytics?.numExperiments ?? 0}
            </Typography>
          </CardContent>
        </Card>

        <Card sx={{ width: '16%', marginRight: '2%' }}>
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              Total Amount of Instance Runs
            </Typography>
            <Typography variant="h5" component="div" sx={{ marginTop: '18px' }}>
              {dashboardAnalytics?.dashboardAnalytics?.numTotalInstanceRuns ??
                0}
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ width: '16%' }}>
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              Total Amount of Instance Runs Completed
            </Typography>
            <Typography variant="h5" component="div">
              {dashboardAnalytics?.dashboardAnalytics
                ?.numTotalInstanceRunsCompleted ?? 0}
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginLeft: '2%',
          marginRight: '2%',
          marginTop: '2%',
          height: '450px',
          maxHeight: '700px',
          overflow: 'auto',
          width: '100%',
        }}
      >
        <Card
          sx={{
            overflow: 'auto',
            width: '64%',
            marginRight: '2%',
          }}
        >
          <CardContent>
            <RiskAssessmentScore
              riskAssessmentFrameworks={(
                dashboardDetails?.project?.riskAssessmentFrameworks ?? []
              ).filter(
                (framework): framework is RiskAssessmentFrameworkMaterialized =>
                  framework !== null
              )}
            />
          </CardContent>
        </Card>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '30%',
            overflow: 'auto',
            gap: '2rem',
          }}
        >
          <Card sx={{ overflow: 'auto', height: '45%' }}>
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  marginLeft: '10px',
                  marginRight: '10px',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="body2" color="text.secondary">
                  Intented Use Cases
                </Typography>
                <IconButton
                  style={{ marginTop: '-8px' }}
                  color="primary"
                  aria-label="edit"
                  size="small"
                  onClick={() => setIntentedUseCasesEditOpen(true)}
                >
                  <ModeEditIcon />
                </IconButton>
              </Box>
              {(dashboardDetails?.project?.intentedUseCases ?? []).map(
                (e: any) => (
                  <Stack sx={{ marginTop: '10px' }} direction="row" key={e}>
                    <NavigateNextIcon sx={{ marginTop: '5px' }} />
                    <Typography variant="h6">{e}</Typography>
                  </Stack>
                )
              )}
            </CardContent>
          </Card>

          <Card sx={{ overflow: 'auto', height: '45%' }}>
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  marginLeft: '10px',
                  marginRight: '10px',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="body2" color="text.secondary">
                  Unintented Use Cases
                </Typography>
                <IconButton
                  style={{ marginTop: '-8px' }}
                  color="primary"
                  aria-label="edit"
                  size="small"
                  onClick={() => setUnintentedUseCasesEditOpen(true)}
                >
                  <ModeEditIcon />
                </IconButton>
              </Box>
              {(dashboardDetails?.project?.unintentedUseCases ?? []).map(
                (e: any) => (
                  <Stack sx={{ marginTop: '10px' }} direction="row" key={e}>
                    <NavigateNextIcon sx={{ marginTop: '5px' }} />
                    <Typography variant="h6">{e}</Typography>
                  </Stack>
                )
              )}
            </CardContent>
          </Card>
          <Card sx={{ overflow: 'auto', height: '45%', paddingBottom: '10px' }}>
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  marginLeft: '10px',
                  marginRight: '10px',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="body2" color="text.secondary">
                  Limitations
                </Typography>
                <IconButton
                  style={{ marginTop: '-8px' }}
                  color="primary"
                  aria-label="edit"
                  size="small"
                  onClick={() => setLimitationsEditOpen(true)}
                >
                  <ModeEditIcon />
                </IconButton>
              </Box>

              {(dashboardDetails?.project?.limitations ?? []).map((e: any) => (
                <Stack sx={{ marginTop: '10px' }} direction="row" key={e}>
                  <NavigateNextIcon sx={{ marginTop: '5px' }} />
                  <Typography variant="h6">{e}</Typography>
                </Stack>
              ))}
            </CardContent>
          </Card>
        </Box>
      </Box>
      {/* <Box
        sx={{
          marginBottom: '2%',
          marginLeft: '2%',
          marginRight: '2%',
          marginTop: '2%',
          width: '96%',
        }}
      >
        <Card sx={{ overflow: 'auto' }}>
          <CardContent>
            <PreviewChart />
          </CardContent>
        </Card>
      </Box> */}
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={titleEditOpen}
      >
        <EditProjectTitle
          projectID={projectId}
          title={dashboardDetails?.project?.title ?? ''}
          onClose={handleEditTitleClose}
        />
      </Backdrop>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={descriptionEditOpen}
      >
        <EditProjectDescription
          projectId={projectId}
          description={dashboardDetails?.project?.description ?? ''}
          onClose={handleEditDescriptionClose}
        />
      </Backdrop>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={limitationsEditOpen}
      >
        <Box sx={{ width: '50%' }}>
          <EditLimitations
            projectId={projectId}
            limitations={dashboardDetails?.project?.limitations ?? []}
            onClose={handleEditLimitationsClose}
          />
        </Box>
      </Backdrop>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={intentedUseCasesEditOpen}
      >
        <Box sx={{ width: '50%' }}>
          <EditIntentedUseCases
            projectId={projectId}
            useCases={dashboardDetails?.project?.intentedUseCases ?? []}
            onClose={handleEditIntentedUseCasesClose}
          />
        </Box>
      </Backdrop>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={unintentedUseCasesEditOpen}
      >
        <Box sx={{ width: '50%' }}>
          <EditUnintentedUseCases
            projectId={projectId}
            useCases={dashboardDetails?.project?.unintentedUseCases ?? []}
            onClose={handleEditUnintentedUseCasesClose}
          />
        </Box>
      </Backdrop>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={businessMeasurementEditOpen}
      >
        {bestExperiment && (
          <EditBusinessMeasurement
            projectId={projectId}
            experimentId={bestExperiment.experimentId ?? ''}
            businessMeasurement={dashboardDetails?.project?.businessMeasurement}
            onClose={handleBusinessMeasurementClose}
          />
        )}
      </Backdrop>
      <DashboardProjectCharter
        isOpen={showProjectCharter}
        onClose={handleProjectCharterClose}
      />
    </Box>
  );
}
