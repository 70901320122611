import { useMemo, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Grid,
  Divider,
  Typography,
  IconButton,
} from '@mui/material';
import { useRecoilValue } from 'recoil';
import DeleteIcon from '@mui/icons-material/Delete';
import { defaultMetricState } from './TreeSettingsAtoms';
import ExperimentDeletionDialog from './dialogs/ExperimentDeletionDialog';

type VisualizerGraphCardProps = {
  id: string;
  title?: string;
  description?: string;
  hypothesis?: string;
  mainMetric?: any;
  onClick: (experimentId: string) => void;
  isSelectionMode: boolean;
  isExperimentSelected: boolean;
  onSelection: (selected: boolean) => void;
};

export default function VisualizerGraphCard({
  id,
  title,
  description,
  hypothesis,
  mainMetric,
  onClick,
  isSelectionMode,
  isExperimentSelected,
  onSelection,
}: VisualizerGraphCardProps) {
  const defaultMetric = useRecoilValue(defaultMetricState);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const metricEntries = useMemo(
    () => Object.entries<number>(mainMetric),
    [mainMetric]
  );
  const handleConfirmationDialogClose = () => {
    setShowConfirmationDialog(false);
  };
  const getMetricValue = () => {
    const entry = metricEntries.find(
      metricEntry => metricEntry[0] === defaultMetric
    );
    if (entry) {
      return Math.round(entry[1] * 1000) / 1000;
    }
    return 'NaN';
  };
  const [isDeleteButtonVisible, setIsDeleteButtonVisible] = useState(false);
  const IconPlaceholder = () => (
    <div style={{ width: 24, height: 24, opacity: 0 }}></div>
  );
  return (
    <>
      <Card
        sx={{ width: 275 }}
        onClick={() => onClick(id)}
        onMouseLeave={() => setIsDeleteButtonVisible(false)}
        onMouseEnter={() => setIsDeleteButtonVisible(true)}
      >
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ flex: 1, mr: 1, overflow: 'hidden' }}>
              <Typography
                variant="body2"
                color="text.secondary"
                marginBottom={1}
                sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                  textOverflow: 'ellipsis',
                }}
              >
                Hypothesis: {hypothesis}
              </Typography>
            </Box>
            <Box sx={{ minHeight: 48, minWidth: 48, textAlign: 'right' }}>
              {isSelectionMode ? (
                <Checkbox
                  checked={isExperimentSelected}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    onSelection(!isExperimentSelected);
                  }}
                />
              ) : isDeleteButtonVisible ? (
                <IconButton
                  onClick={() => setShowConfirmationDialog(true)}
                  aria-label="delete"
                  sx={{ marginTop: '-12px' }}
                >
                  <DeleteIcon />
                </IconButton>
              ) : (
                <IconPlaceholder />
              )}
            </Box>
          </Box>

          <Divider
            color="text.secondary"
            sx={{ borderBottomWidth: 1, bgcolor: 'white', marginBottom: 2 }}
          />
          <Typography variant="h5" color="text.secondary" marginBottom="8px">
            {title}
          </Typography>
          <Grid container gap="8px" direction="row" alignItems="baseline">
            <Typography
              variant="h3"
              color="text.secondary"
              style={{ display: 'inline-block' }}
            >
              {getMetricValue()}
            </Typography>
            <Typography>{defaultMetric}</Typography>
          </Grid>
          <Typography
            color="text.secondary"
            variant="body2"
            sx={{ textAlign: 'right' }}
          >
            {description}
          </Typography>
        </CardContent>
      </Card>
      <ExperimentDeletionDialog
        showConfirmationDialog={showConfirmationDialog}
        onClose={handleConfirmationDialogClose}
        experimentId={id}
      />
    </>
  );
}
