import React from 'react';
import {
  Typography,
  Divider,
  Grid,
  FormControlLabel,
  Switch,
  Card,
  CardContent,
} from '@mui/material';
import { colorThemes } from '../theme';

interface Framework {
  frameworkName: string;
  isEnabled: boolean;
  templateId: string;
  description: string | null;
}

interface FrameworkListProps {
  title: string;
  frameworks: Framework[];
  loading: boolean;
  onToggle: (id: string, isEnabled: boolean) => void;
}

const FrameworkList: React.FC<FrameworkListProps> = ({
  title,
  frameworks,
  loading,
  onToggle,
}) => (
  <Card sx={{ backgroundColor: colorThemes.DARK_BLUE_400 }}>
    <CardContent>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      {frameworks.map(
        ({ frameworkName, isEnabled, templateId, description }, index) => (
          <React.Fragment key={templateId}>
            {index > 0 && <Divider sx={{ my: 2 }} />}
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs>
                <Typography variant="body1">{frameworkName}</Typography>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={loading}
                      checked={isEnabled}
                      onChange={e => onToggle(templateId, e.target.checked)}
                      color="primary"
                    />
                  }
                  label={isEnabled ? 'Enabled' : 'Disabled'}
                />
              </Grid>
            </Grid>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              {description}
            </Typography>
          </React.Fragment>
        )
      )}
    </CardContent>
  </Card>
);

export default FrameworkList;
