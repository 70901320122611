import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { toast } from 'react-toastify';
import {
  gql,
  useMutation,
  useSuspenseQuery,
  ApolloCache,
  FetchResult,
} from '@apollo/client';
import {
  GetFrameworkTemplatesQuery,
  UpdateEnabledFrameworksMutation,
} from '../__generated__/gql/graphql';
import { GET_ENABLED_RISK_ASSESSMENT_FRAMEWORKS } from '../risk_management/NewRiskAssessmentModal';
import FrameworkList from './FrameworkList';

const GET_FRAMEWORK_TEMPLATES = gql`
  query getFrameworkTemplates {
    organization {
      riskFrameworks: availableFrameworkTemplates(frameworkType: ASSESSMENT) {
        frameworkName
        isEnabled
        templateId
        description
      }
      governanceFrameworks: availableFrameworkTemplates(
        frameworkType: GOVERNANCE
      ) {
        frameworkName
        isEnabled
        templateId
        description
      }
      projectGovernanceFrameworks: availableFrameworkTemplates(
        frameworkType: PROJECT_GOVERNANCE
      ) {
        frameworkName
        isEnabled
        templateId
        description
      }
    }
  }
`;

const UPDATE_FRAMEWORKS_MUTATION = gql`
  mutation UpdateEnabledFrameworks(
    $enabledGovernanceFrameworks: [String!]!
    $enabledRiskFrameworks: [String!]!
    $enabledProjectGovernanceFrameworks: [String!]!
  ) {
    enableFrameworks(
      governanceFrameworkTemplates: $enabledGovernanceFrameworks
      riskAssessmentFrameworkTemplates: $enabledRiskFrameworks
      projectGovernanceFrameworkTemplates: $enabledProjectGovernanceFrameworks
    ) {
      governanceFrameworkTemplates {
        frameworkName
        templateId
        isEnabled
      }
      riskAssessmentFrameworkTemplates {
        frameworkName
        templateId
        isEnabled
      }
    }
  }
`;

interface Framework {
  frameworkName: string;
  isEnabled: boolean;
  templateId: string;
  description: string | null;
}

const FrameworkManagement: React.FC = () => {
  const { data } = useSuspenseQuery<GetFrameworkTemplatesQuery>(
    GET_FRAMEWORK_TEMPLATES
  );
  const [updateFrameworks, { loading, client }] =
    useMutation<UpdateEnabledFrameworksMutation>(UPDATE_FRAMEWORKS_MUTATION, {
      onCompleted: () => toast.success('Frameworks updated successfully!'),
      onError: error =>
        toast.error(`An unexpected error occurred: ${error.message}`),
      update: updateCacheAfterMutation,
      refetchQueries: [{ query: GET_ENABLED_RISK_ASSESSMENT_FRAMEWORKS }],
    });

  const handleToggle = async (
    id: string,
    isEnabled: boolean
  ): Promise<void> => {
    const enabledGovernanceIds = getEnabledIds(
      data?.organization?.governanceFrameworks as Framework[],
      id,
      isEnabled
    );
    const enabledRiskIds = getEnabledIds(
      data?.organization?.riskFrameworks as Framework[],
      id,
      isEnabled
    );

    const enabledProjectGovernanceIds = getEnabledIds(
      data?.organization?.projectGovernanceFrameworks as Framework[],
      id,
      isEnabled
    );

    try {
      await updateFrameworks({
        variables: {
          enabledGovernanceFrameworks: enabledGovernanceIds,
          enabledRiskFrameworks: enabledRiskIds,
          enabledProjectGovernanceFrameworks: enabledProjectGovernanceIds,
        },
      });
    } catch (error) {
      console.error('Error updating frameworks:', error);
      toast.error('Error updating frameworks');
      client.resetStore();
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Framework Management
        </Typography>
        <Box display={'flex'} gap={'20px'} flexDirection={'column'}>
          <Box mt={4}>
            <FrameworkList
              title="Governance Frameworks"
              frameworks={
                (data?.organization?.governanceFrameworks as Framework[]) || []
              }
              loading={loading}
              onToggle={handleToggle}
            />
          </Box>
          <Box mt={4}>
            <FrameworkList
              title="Risk Assessment Frameworks"
              frameworks={
                (data?.organization?.riskFrameworks as Framework[]) || []
              }
              loading={loading}
              onToggle={handleToggle}
            />
          </Box>
          <Box mt={4}>
            <FrameworkList
              title="Project Governance Frameworks"
              frameworks={
                (data?.organization
                  ?.projectGovernanceFrameworks as Framework[]) || []
              }
              loading={loading}
              onToggle={handleToggle}
            />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

const getEnabledIds = (
  frameworks: Framework[] | undefined | null,
  id: string,
  isEnabled: boolean
): string[] =>
  frameworks
    ?.map(fw => (fw.templateId === id ? { ...fw, isEnabled } : fw))
    .filter(fw => fw.isEnabled)
    .map(fw => fw.templateId) || [];

const updateCacheAfterMutation = (
  cache: ApolloCache<any>,
  result: FetchResult<UpdateEnabledFrameworksMutation>
): void => {
  const data = result.data;
  if (!data) return;

  const updatedGovernanceFrameworks =
    data?.enableFrameworks?.governanceFrameworkTemplates;
  const updatedRiskFrameworks =
    data?.enableFrameworks?.riskAssessmentFrameworkTemplates;

  cache.writeQuery({
    query: GET_FRAMEWORK_TEMPLATES,
    data: {
      organization: {
        __typename: 'Organization',
        governanceFrameworks: updatedGovernanceFrameworks,
        riskFrameworks: updatedRiskFrameworks,
      },
    },
  });
};

export default FrameworkManagement;
