import { Typography, Grid, Box } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { DocumentComponentTypeEnum } from '../../__generated__/gql/graphql';
import { DocumentationItem } from '../component_cards/DocumentationItem';
import DocumentationEntry from '../types/DocumentationEntry';

export interface DocumentationComponent {
  id: string;
  name: string;
}

export const SIDEBAR_DROPPABLE_ID = 'sidebar_droppable';

export const isCustomComponent = (componentName: string) => {
  return componentName.startsWith('Custom');
};

const renderComponentGroup = (
  entries: DocumentationEntry[],
  onAdd: (component: DocumentationEntry) => void
) => (
  <Box
    sx={{
      display: 'flex',
      flexWrap: 'wrap',
      gap: '16px',
    }}
  >
    {entries.map(component => (
      <DocumentationItem
        key={component.id}
        documentationEntry={component}
        handleAdd={() => onAdd(component)}
        isDragEnabled={false}
      />
    ))}
  </Box>
);

interface ComponentsListProps {
  onAdd: (component: DocumentationEntry) => void;
  usedComponents: DocumentationEntry[];
}

export const ComponentsList = ({
  onAdd,
  usedComponents,
}: ComponentsListProps) => {
  const customizableComponents: DocumentationEntry[] = Object.values(
    DocumentComponentTypeEnum
  )
    .filter(enumValue => isCustomComponent(enumValue))
    .map(enumValue => ({
      id: uuidv4(),
      name: enumValue,
      query: '',
      dataSource: undefined,
      type: enumValue,
    }));

  const predefinedComponents: DocumentationEntry[] = Object.values(
    DocumentComponentTypeEnum
  )
    .filter(enumValue => !isCustomComponent(enumValue))
    .filter(enumValue => !usedComponents.find(c => c.type === enumValue))
    .map(enumValue => ({
      id: uuidv4(),
      name: enumValue,
      query: '',
      dataSource: undefined,
      type: enumValue,
    }));

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        overflowY: 'auto',
        gap: 2,
      }}
    >
      <Grid container padding={0} justifyContent="space-between" gap={3}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: '16px',
          }}
        >
          <Typography variant="subtitle1">Predefined Components</Typography>
          {renderComponentGroup(predefinedComponents, onAdd)}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: '16px',
          }}
        >
          <Typography variant="subtitle1">Customizable Components</Typography>
          {renderComponentGroup(customizableComponents, onAdd)}
        </Box>
      </Grid>
    </Box>
  );
};
