import posthog from 'posthog-js';

export const getFeatureFlag = (flagName: string): boolean => {
  const usePosthog = window._env_.USE_POSTHOG === 'true';
  if (usePosthog) {
    return posthog.isFeatureEnabled(flagName) || false;
  } else {
    return window._env_[flagName] === 'true';
  }
};
