import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/material';
import { colorThemes } from '../../theme';
import { AddCircle, BuildCircle, RemoveCircle } from '@mui/icons-material';
import { forwardRef, useState } from 'react';
import { DocumentationEditModal } from './DocumentationEditModal';
import DocumentationEntry from '../types/DocumentationEntry';
import { DocumentComponentNameMap } from '../templates/DocumentationTemplates';

interface DocumentationItemProps {
  isDragging?: boolean;
  isDragEnabled?: boolean;
  key?: string;
  onDelete?: (id: string) => void;
  handleAdd?: () => void;
  editable?: boolean;
  documentationEntry: DocumentationEntry;
  onSave?: (documentationEntry: DocumentationEntry) => void;
}

export const DocumentationItem = forwardRef(
  (props: DocumentationItemProps, ref) => {
    const {
      isDragging,
      isDragEnabled = true,
      onDelete,
      handleAdd,
      editable,
      documentationEntry,
      onSave,
      ...otherProps
    } = props;

    const [openEditModal, setOpenEditModal] = useState(false);

    return (
      <>
        <Box
          {...otherProps}
          ref={ref}
          sx={{
            position: 'relative',
            width: '100%',
          }}
        >
          <Card
            sx={{
              height: 55,
              padding: 1,
              width: '100%',
              background: colorThemes.DARK_BLUE_700,
              border: `1px dashed ${
                isDragging ? colorThemes.YELLOW_200 : colorThemes.DARK_BLUE_700
              }`,
              ...(isDragEnabled && {
                '&:hover': {
                  cursor: 'move',
                  backgroundColor: colorThemes.DARK_BLUE_800,
                },
              }),
            }}
          >
            <Typography variant="subtitle1">
              {DocumentComponentNameMap[documentationEntry.type]}
            </Typography>
          </Card>

          <Box
            display="flex"
            gap="4px"
            sx={{
              position: 'absolute',
              right: 0,
              bottom: '-4px',
              padding: 0,
            }}
          >
            {isDragEnabled && editable && (
              <IconButton
                sx={{
                  padding: 0,
                }}
                onClick={() => setOpenEditModal(true)}
              >
                <BuildCircle sx={{ color: colorThemes.GREY_200 }} />
              </IconButton>
            )}

            {isDragEnabled && onDelete && (
              <IconButton
                sx={{
                  padding: 0,
                }}
                onClick={() => onDelete(documentationEntry.id)}
              >
                <RemoveCircle sx={{ color: colorThemes.GREY_200 }} />
              </IconButton>
            )}
          </Box>

          {handleAdd && (
            <IconButton
              sx={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                padding: 0,
              }}
              onClick={handleAdd}
            >
              <AddCircle sx={{ color: colorThemes.GREY_200 }} />
            </IconButton>
          )}
        </Box>

        {isDragEnabled && editable && onSave && (
          <DocumentationEditModal
            open={openEditModal}
            setOpen={setOpenEditModal}
            documentationEntry={documentationEntry}
            onSave={onSave}
          />
        )}
      </>
    );
  }
);
