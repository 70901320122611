import { Clear } from '@mui/icons-material';
import { Box, IconButton, Divider } from '@mui/material';
import { FC, useState } from 'react';
import { TextEvidence } from '../__generated__/gql/graphql';

interface EvidenceCardProps {
  evidence: TextEvidence;
  handleEvidenceDelete: (id: string) => void;
}

const EvidenceCard: FC<EvidenceCardProps> = ({
  evidence,
  handleEvidenceDelete,
}) => {
  const [open, setOpen] = useState(false);

  const handleToggleOpen = () => {
    if (evidence?.description) {
      setOpen(!open);
    }
  };

  return (
    <Box
      sx={{
        bgcolor: '#295581',
        color: 'white',
        p: 1.5,
        borderRadius: 1,
        boxShadow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '100%',
        cursor: evidence?.description ? 'pointer' : 'default',
      }}
      onClick={handleToggleOpen}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {evidence?.title}
        <IconButton
          size="small"
          sx={{ color: 'white' }}
          onClick={e => {
            e.stopPropagation();
            handleEvidenceDelete(evidence?.id);
          }}
        >
          <Clear fontSize="inherit" />
        </IconButton>
      </Box>
      {open && (
        <>
          <Divider sx={{ width: '100%', my: 1, borderColor: 'white' }} />
          <Box sx={{ width: '100%', whiteSpace: 'pre-wrap' }}>
            {evidence?.description}
          </Box>
        </>
      )}
    </Box>
  );
};

export default EvidenceCard;
