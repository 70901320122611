import { useState } from 'react';
import {
  Box,
  Typography,
  CardContent,
  Card,
  CardHeader,
  ButtonGroup,
  Button,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import FormNavigationSidebar, {
  buildRiskSectionId,
} from './FormNavigationSidebar';
import { colorThemes } from '../../theme';
import { scrollbarSx } from '../../components/StyledScrollable';
import { ToastContainer, toast } from 'react-toastify';
import CategoryProgressBar from './CategoryProgressBar';
import { gql, useMutation } from '@apollo/client';
import { useParams, Link } from 'react-router-dom';
import type {
  RiskAssessment,
  RiskAssessmentFrameworkMaterialized,
  TrailRiskSection as TrailRiskSectionType,
  StandardRiskSection as StandardRiskSectionType,
} from '../../__generated__/gql/graphql';
import { RiskAssessmentCatalogueTypeEnum } from '../../__generated__/gql/graphql';
import TrailRiskSection, { TRAIL_SECTION_FIELDS } from './TrailRiskSection';
import { useUnsavedChanges } from '../../hooks/useUnsavedChanges';
import StandardRiskSection, {
  STANDARD_SECTION_FIELDS,
} from './StandardRiskSection';

const UPSERT_RISK_ASSESSMENT = gql`
  mutation UpsertRiskAssessment(
    $frameworkId: ID!
    $riskAssessmentId: ID
    $riskAssessmentCatalogueType: RiskAssessmentCatalogueTypeEnum!
    $riskSections: [RiskSectionInput]!
  ) {
    upsertRiskAssessment(
      frameworkId: $frameworkId
      riskAssessmentId: $riskAssessmentId
      riskAssessmentCatalogueType: $riskAssessmentCatalogueType
      riskSections: $riskSections
    ) {
      riskAssessment {
        id
        riskSections {
          ... on StandardRiskSection {
            frequency
            impactSystem
            impactUser
            impactBusiness
            severity
          }
          ... on TrailRiskSection {
            frequency
            severity
          }
        }
      }
    }
  }
`;

interface RiskAssessmentProps {
  frameworkCatalogue?: RiskAssessmentFrameworkMaterialized | null;
  formValues: StandardRiskSectionType[] | TrailRiskSectionType[] | [];
  type: RiskAssessmentCatalogueTypeEnum;
}

const riskAssessmentConfig = {
  [RiskAssessmentCatalogueTypeEnum.Standard]: {
    component: StandardRiskSection,
    sectionFields: STANDARD_SECTION_FIELDS,
  },
  [RiskAssessmentCatalogueTypeEnum.Trail]: {
    component: TrailRiskSection,
    sectionFields: TRAIL_SECTION_FIELDS,
  },
};

export const RISK_FORM_PREFIX = 'riskSections';

export default function RiskAssessment({
  frameworkCatalogue,
  formValues,
  type,
}: RiskAssessmentProps) {
  const { projectId, assessmentFrameworkId } = useParams<{
    projectId: string;
    assessmentFrameworkId: string;
  }>();
  const [saveRiskAssessment, { loading }] = useMutation(
    UPSERT_RISK_ASSESSMENT,
    { refetchQueries: ['RiskAssessmentFrameworkMaterialized'] }
  );
  const methods = useForm<RiskAssessment>({
    defaultValues: {
      riskSections: formValues,
    },
  });

  const { isDirty } = methods.formState;

  const [activeSection, setActiveSection] = useState<string>(
    buildRiskSectionId(0, 0)
  );

  const handleNavigate = (categoryIndex: number, sectionIndex: number) => {
    const id = buildRiskSectionId(categoryIndex, sectionIndex);
    setActiveSection(id);
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSave = async () => {
    try {
      const data = methods.getValues();
      await saveRiskAssessment({
        variables: {
          frameworkId: assessmentFrameworkId,
          riskAssessmentId: frameworkCatalogue?.riskAssessmentReference?.id,
          riskAssessmentCatalogueType: type,
          riskSections: data.riskSections,
        },
      });
      methods.reset(data);
      toast.success('Data saved successfully!');
    } catch (error) {
      toast.error('An error occurred while saving the Risk Assessment.');
    }
  };

  const { component: RiskSectionComponent, sectionFields } =
    riskAssessmentConfig[type];

  let sectionIndexCounter = 0;

  useUnsavedChanges({ isDirty });

  return (
    <FormProvider {...methods}>
      <Box
        sx={{ marginBottom: '20px' }}
        display="flex"
        justifyContent="space-between"
      >
        <Typography variant="h4">{type} Risk Assessment</Typography>
        <ButtonGroup>
          <Button
            onClick={handleSave}
            disabled={loading}
            startIcon={loading && <CircularProgress size={20} />}
          >
            Save
          </Button>
          <Tooltip
            title={
              !frameworkCatalogue?.riskAssessmentReference?.id
                ? 'You need to save the risk assessment first before moving on to treatment'
                : ''
            }
            placement="top"
          >
            <span>
              <Button
                component={Link}
                to={`/risk_treatment/${projectId}/${frameworkCatalogue?.name}/${assessmentFrameworkId}`}
                disabled={!frameworkCatalogue?.riskAssessmentReference?.id}
              >
                Treatment
              </Button>
            </span>
          </Tooltip>
        </ButtonGroup>
      </Box>
      <Box display="flex" justifyContent="space-around" gap="12px">
        <Card
          sx={{
            maxHeight: '90vh',
            width: '300px',
            position: 'sticky',
            overflow: 'auto',
            ...scrollbarSx,
          }}
        >
          <FormNavigationSidebar
            categories={
              frameworkCatalogue?.categories
                ?.filter(
                  (cat): cat is NonNullable<typeof cat> =>
                    cat !== null && !!cat.categoryName && !!cat.sections
                )
                .map(cat => ({
                  categoryName: cat.categoryName!,
                  sections:
                    cat.sections
                      ?.filter(
                        (s): s is NonNullable<typeof s> =>
                          s !== null && !!s.label
                      )
                      .map(s => ({
                        label: s.label!,
                      })) ?? [],
                })) ?? []
            }
            activeSection={activeSection}
            onNavigate={handleNavigate}
          />
        </Card>
        <Box
          sx={{
            width: '100%',
            ...scrollbarSx,
            overflowY: 'auto',
            height: '90vh',
          }}
        >
          <Box
            style={{ width: '100%' }}
            display="flex"
            gap="12px"
            flexDirection="column"
          >
            {frameworkCatalogue?.categories
              ?.filter(
                (category): category is NonNullable<typeof category> =>
                  category !== null && !!category.categoryName
              )
              .map((category, catIdx) => {
                const normalizedCategory = {
                  ...category,
                  sections: (category.sections ?? []).map(section => ({
                    label: section?.label ?? 'Unknown Label',
                    id: section?.id ?? 'Unknown ID',
                    description: section?.description ?? 'No description',
                    guidingQuestions:
                      section?.guidingQuestions ?? 'No questions',
                  })),
                };

                return (
                  <Card
                    key={catIdx}
                    sx={{ bgcolor: colorThemes.DARK_BLUE_400 }}
                  >
                    <CardHeader
                      title={
                        <Box display="flex" justifyContent="space-between">
                          {category.categoryName}
                          <CategoryProgressBar
                            sectionIndexCounter={sectionIndexCounter}
                            category={normalizedCategory}
                            sectionFields={sectionFields}
                          />
                        </Box>
                      }
                    />
                    <CardContent>
                      <Box display="flex" gap="12px" flexDirection="column">
                        {normalizedCategory.sections.map((section, secIdx) => {
                          const index = sectionIndexCounter++;
                          return (
                            <div
                              id={buildRiskSectionId(catIdx, secIdx)}
                              key={section.label}
                            >
                              <RiskSectionComponent
                                key={index}
                                name={`${RISK_FORM_PREFIX}.${index}`}
                                section={section}
                                control={methods.control}
                                category={category.categoryName}
                              />
                            </div>
                          );
                        })}
                      </Box>
                    </CardContent>
                  </Card>
                );
              })}
          </Box>
        </Box>
      </Box>
      <ToastContainer />
    </FormProvider>
  );
}
