import { Box, Button, Card, MenuItem, Modal, TextField } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import DocumentationEntry from '../types/DocumentationEntry';
import { DataSourceEnum } from '../../__generated__/gql/graphql';

export const DocumentationEditModal = ({
  open,
  setOpen,
  documentationEntry,
  onSave,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  documentationEntry: DocumentationEntry;
  onSave: (documentationEntry: DocumentationEntry) => void;
}) => {
  const handleClose = (_event: object, reason: string) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  type DocumentationEditModalType = {
    name?: string;
    query?: string;
    dataSource?: DataSourceEnum;
    args?: (string | null)[];
  };

  const initialValue = useMemo<DocumentationEditModalType>(
    () => ({
      name: documentationEntry.name ?? documentationEntry.type,
      query: documentationEntry.query ?? '',
      dataSource: documentationEntry.dataSource ?? DataSourceEnum.Code,
      args: documentationEntry.args ?? [],
    }),
    [documentationEntry]
  );

  const [componentState, setComponentState] =
    useState<DocumentationEditModalType>();

  useEffect(() => {
    setComponentState(initialValue);
  }, [initialValue]);

  const [touched, setTouched] = useState<boolean>(false);

  const updateField = (
    fieldToUpdate: string,
    newValue: string | DataSourceEnum
  ) => {
    const newState = { ...componentState, [fieldToUpdate]: newValue };
    setComponentState(newState);
    setTouched(true);
  };

  const handleCancel = () => {
    setTouched(false);
    if (setOpen) {
      setOpen(false);
    }
  };

  const handleSave = () => {
    onSave({ ...documentationEntry, ...componentState });
    if (touched) {
      setTouched(false);
    }

    if (setOpen) {
      setOpen(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card
        sx={{
          width: '600px',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          padding: '24px',
        }}
      >
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          value={componentState?.name}
          onChange={e => {
            updateField('name', e.target.value);
            setTouched(true);
          }}
        />
        <TextField
          label="Query"
          variant="outlined"
          fullWidth
          value={componentState?.query}
          onChange={e => {
            updateField('query', e.target.value);
            setTouched(true);
          }}
        />
        <TextField
          label="Data Source"
          variant="outlined"
          select
          fullWidth
          value={componentState?.dataSource}
          onChange={e => {
            updateField('dataSource', e.target.value as DataSourceEnum);
            setTouched(true);
          }}
        >
          {Object.keys(DataSourceEnum).map(key => (
            <MenuItem key={key} value={key}>
              {key}
            </MenuItem>
          ))}
        </TextField>

        <Box width="100%" display="flex" justifyContent="flex-end" gap="16px">
          <Button onClick={handleCancel} variant="outlined" color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </Box>
      </Card>
    </Modal>
  );
};
