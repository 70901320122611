import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import theme from '../theme';

interface ProjectGridItemProps {
  title: string;
  loading?: boolean;
  content: React.ReactNode;
  icon?: React.ReactNode;
}

export default function GridItem({
  title,
  loading = false,
  content,
  icon,
}: ProjectGridItemProps) {
  return (
    <Grid
      item
      xs={12}
      sx={{
        marginTop: '20px',
        width: '100%',
        height: '95%',
        bgcolor: '#001529',
        padding: 2,
        borderRadius: theme.spacing(1),
        position: 'relative',
      }}
    >
      {icon && (
        <Box
          sx={{
            position: 'absolute',
            top: 2,
            right: 2,
          }}
        >
          {icon}
        </Box>
      )}
      <Typography variant="h5">{title}</Typography>
      <br />
      {loading ? <CircularProgress color="inherit" /> : content}
    </Grid>
  );
}
