import { gql, useSuspenseQuery } from '@apollo/client';
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import EditLimitations from './edit_modals/EditLimitations';
import EditIntentedUseCases from './edit_modals/EditIntentedUseCases';
import { DashboardCharterDetailsQuery } from '../__generated__/gql/graphql';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
export const GET_DETAILS = gql(`
  query dashboardCharterDetails($projectId: String!) {
  project(id: $projectId) {
    title
    limitations
    description
    intentedUseCases
    unintentedUseCases
    background
    risks {
      mitigation
      impact
      riskDescription
      likelihood
    }
}
}
`);

export default function DashboardProjectCharter({ isOpen, onClose }: Props) {
  const { projectId } = useParams();
  const { data: dashboardDetails } =
    useSuspenseQuery<DashboardCharterDetailsQuery>(GET_DETAILS, {
      variables: { projectId },
    });

  const [limitationsEditOpen, setLimitationsEditOpen] = useState(false);
  const [intentedUseCasesEditOpen, setIntentedUseCasesEditOpen] =
    useState(false);
  const [unintentedUseCasesEditOpen, setUnintentedUseCasesEditOpen] =
    useState(false);
  const handleEditLimitationsClose = () => {
    setLimitationsEditOpen(false);
  };
  const handleEditIntentedUseCasesClose = () => {
    setIntentedUseCasesEditOpen(false);
  };
  const handleEditUnintentedUseCasesClose = () => {
    setUnintentedUseCasesEditOpen(false);
  };
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '2rem',
      }}
    >
      <Card
        sx={{
          background: '#001529',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '80%',
          height: '70%',
          overflow: 'auto',
          border: '1px white solid',
          borderRadius: '5px',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            sx={{ marginTop: '20px', marginLeft: '20px' }}
            variant="h6"
            color="white"
          >
            Project Charter
          </Typography>
          <IconButton onClick={onClose} aria-label="delete">
            <CloseOutlinedIcon />
          </IconButton>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginLeft: '0',
            marginTop: '10px',
          }}
        >
          <Card
            sx={{
              overflow: 'scroll',
              width: '35%',
              height: '100%',
              marginLeft: '100px',
              maxHeight: '300px',
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  marginLeft: '10px',
                  marginRight: '10px',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  sx={{ marginLeft: '-10px' }}
                  variant="body2"
                  color="text.secondary"
                >
                  Project Background
                </Typography>
                <IconButton
                  style={{ marginTop: '-8px' }}
                  color="primary"
                  aria-label="edit"
                  size="small"
                >
                  <ModeEditIcon />
                </IconButton>
              </Box>
              {dashboardDetails?.project?.background}
            </CardContent>
          </Card>

          {intentedUseCasesEditOpen ? (
            <Box
              sx={{
                overflow: 'scroll',
                width: '35%',
                height: '100%',
                marginLeft: '100px',
                maxHeight: '300px',
              }}
            >
              <EditIntentedUseCases
                useCases={dashboardDetails?.project?.intentedUseCases ?? []}
                projectId={projectId as string}
                onClose={handleEditIntentedUseCasesClose}
              />
            </Box>
          ) : (
            <Card
              sx={{
                overflow: 'scroll',
                width: '35%',
                height: '100%',
                marginLeft: '100px',
                maxHeight: '300px',
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    marginLeft: '10px',
                    marginRight: '10px',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    sx={{ marginLeft: '-10px' }}
                    variant="body2"
                    color="text.secondary"
                  >
                    Intented Use Cases
                  </Typography>
                  <IconButton
                    style={{ marginTop: '-8px' }}
                    color="primary"
                    aria-label="edit"
                    size="small"
                    onClick={() => setIntentedUseCasesEditOpen(true)}
                  >
                    <ModeEditIcon />
                  </IconButton>
                </Box>
                {dashboardDetails?.project?.intentedUseCases?.map((e: any) => (
                  <Stack sx={{ marginTop: '10px' }} direction="row">
                    <NavigateNextIcon sx={{ marginTop: '5px' }} />
                    <Typography variant="h6">{e}</Typography>
                  </Stack>
                ))}
              </CardContent>
            </Card>
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginLeft: '0',
            marginTop: '10px',
          }}
        >
          <Card
            sx={{
              overflow: 'scroll',
              width: '35%',
              height: '100%',
              marginLeft: '100px',
              maxHeight: '300px',
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  marginLeft: '10px',
                  marginRight: '10px',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  sx={{ marginLeft: '-10px' }}
                  variant="body2"
                  color="text.secondary"
                >
                  Risks & Benefits for End Users [Risk, Likelihood, Impact,
                  Mitigation]
                </Typography>
                <IconButton
                  style={{ marginTop: '-8px' }}
                  color="primary"
                  aria-label="edit"
                  size="small"
                >
                  <ModeEditIcon />
                </IconButton>
              </Box>
              {dashboardDetails?.project?.risks?.map((e: any) => (
                <Stack sx={{ marginTop: '10px' }} direction="row">
                  <NavigateNextIcon sx={{ marginTop: '5px' }} />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginTop: '0',
                      marginBottom: '1rem',
                      width: '92%',
                    }}
                  >
                    <Box
                      sx={{
                        marginLeft: '10px',
                        overflowX: 'scroll',
                        width: '20%',
                        border: '1px white solid',
                        borderRadius: '3px',
                        textAlign: 'center',
                        m: 1,
                      }}
                    >
                      {e.riskDescription}
                    </Box>
                    <Box
                      sx={{
                        width: '20%',
                        border: '1px white solid',
                        borderRadius: '3px',
                        textAlign: 'center',
                        m: 1,
                      }}
                    >
                      {e.likelihood}
                    </Box>
                    <Box
                      sx={{
                        width: '20%',
                        border: '1px white solid',
                        borderRadius: '3px',
                        textAlign: 'center',
                        m: 1,
                      }}
                    >
                      {e.impact}
                    </Box>
                    <Box
                      sx={{
                        overflowX: 'scroll',
                        width: '20%',
                        border: '1px white solid',
                        borderRadius: '3px',
                        textAlign: 'center',
                        m: 1,
                      }}
                    >
                      {e.mitigation}
                    </Box>
                  </Box>
                </Stack>
              ))}
            </CardContent>
          </Card>

          {unintentedUseCasesEditOpen ? (
            <Box
              sx={{
                overflow: 'scroll',
                width: '35%',
                height: '100%',
                marginLeft: '100px',
                maxHeight: '300px',
              }}
            >
              <EditIntentedUseCases
                useCases={dashboardDetails?.project?.unintentedUseCases ?? []}
                projectId={projectId as string}
                onClose={handleEditUnintentedUseCasesClose}
              />
            </Box>
          ) : (
            <Card
              sx={{
                overflow: 'scroll',
                width: '35%',
                height: '100%',
                marginLeft: '100px',
                maxHeight: '300px',
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    marginLeft: '10px',
                    marginRight: '10px',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    sx={{ marginLeft: '-10px' }}
                    variant="body2"
                    color="text.secondary"
                  >
                    Unintened Use Cases
                  </Typography>
                  <IconButton
                    style={{ marginTop: '-8px' }}
                    color="primary"
                    aria-label="edit"
                    size="small"
                    onClick={() => setUnintentedUseCasesEditOpen(true)}
                  >
                    <ModeEditIcon />
                  </IconButton>
                </Box>
                {dashboardDetails?.project?.unintentedUseCases?.map(
                  (e: any) => (
                    <Stack sx={{ marginTop: '10px' }} direction="row">
                      <NavigateNextIcon sx={{ marginTop: '5px' }} />
                      <Typography variant="h6">{e}</Typography>
                    </Stack>
                  )
                )}
              </CardContent>
            </Card>
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginLeft: '0',
            marginTop: '10px',
            marginBottom: '20px',
          }}
        >
          <Card
            sx={{
              overflow: 'scroll',
              width: '35%',
              height: '100%',
              marginLeft: '100px',
              maxHeight: '300px',
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  marginLeft: '10px',
                  marginRight: '10px',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  sx={{ marginLeft: '-10px' }}
                  variant="body2"
                  color="text.secondary"
                >
                  Roles & Responsibilities [Role, Responsible]
                </Typography>
                <IconButton
                  style={{ marginTop: '-8px' }}
                  color="primary"
                  aria-label="edit"
                  size="small"
                >
                  <ModeEditIcon />
                </IconButton>
              </Box>
              {/* {dashboardDetails?.project?.roles?.map((e: any) => (
                <Stack sx={{ marginTop: '10px' }} direction="row">
                  <NavigateNextIcon sx={{ marginTop: '5px' }} />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginTop: '0',
                      marginBottom: '1rem',
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        marginLeft: '10px',
                        overflowX: 'scroll',
                        width: '40%',
                        border: '1px white solid',
                        borderRadius: '3px',
                        textAlign: 'center',
                        m: 1,
                      }}
                    >
                      {e.role}
                    </Box>
                    <Box
                      sx={{
                        width: '40%',
                        border: '1px white solid',
                        borderRadius: '3px',
                        textAlign: 'center',
                        m: 1,
                      }}
                    >
                      {e.responsible}
                    </Box>
                  </Box>
                </Stack>
              ))} */}
            </CardContent>
          </Card>

          {limitationsEditOpen ? (
            <Box
              sx={{
                overflow: 'scroll',
                width: '35%',
                height: '100%',
                marginLeft: '100px',
                maxHeight: '300px',
                marginBottom: '50px',
              }}
            >
              <EditLimitations
                limitations={dashboardDetails?.project?.limitations ?? []}
                projectId={projectId as string}
                onClose={handleEditLimitationsClose}
              />
            </Box>
          ) : (
            <Card
              sx={{
                overflow: 'scroll',
                width: '35%',
                height: '100%',
                marginLeft: '100px',
                maxHeight: '300px',

                marginBottom: '50px',
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    marginLeft: '10px',
                    marginRight: '10px',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    sx={{ marginLeft: '-10px' }}
                    variant="body2"
                    color="text.secondary"
                  >
                    Limitations
                  </Typography>
                  <IconButton
                    style={{ marginTop: '-8px' }}
                    color="primary"
                    aria-label="edit"
                    size="small"
                    onClick={() => setLimitationsEditOpen(true)}
                  >
                    <ModeEditIcon />
                  </IconButton>
                </Box>
                {dashboardDetails?.project?.limitations?.map((e: any) => (
                  <Stack sx={{ marginTop: '10px' }} direction="row">
                    <NavigateNextIcon sx={{ marginTop: '5px' }} />
                    <Typography variant="h6">{e}</Typography>
                  </Stack>
                ))}
              </CardContent>
            </Card>
          )}
        </Box>
      </Card>
    </Modal>
  );
}
