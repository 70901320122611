import { Grid, Typography } from '@mui/material';
import ManageApiKeys from './ManageApiKeys';
import { Profile } from './Profile';
import { Integrations } from './Integrations';
import { gql, useSuspenseQuery } from '@apollo/client';
import { GetCurrentUserQuery, Task } from '../__generated__/gql/graphql';
import { ToastContainer } from 'react-toastify';
import UserTasks from './UserTasks';

export const GET_USER_DETAILS = gql`
  query GetCurrentUser {
    currentUser {
      email
      id
      apiKeys {
        name
        apiKeyHash
        creationTimestamp
      }
      roles
      responsibilities
      tasks {
        id
        name
        description
        taskType
        actionUrl
        isCompleted
      }
    }
  }
`;
export const ProfilePage = () => {
  const { data: userData } =
    useSuspenseQuery<GetCurrentUserQuery>(GET_USER_DETAILS);
  return (
    <>
      <Typography variant="h4" marginBottom={3}>
        Profile
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{
          width: '100%',
        }}
      >
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {userData.currentUser && <Profile user={userData.currentUser} />}
            </Grid>
            <Grid item xs={12}>
              <Integrations />
            </Grid>
            <Grid item xs={12}>
              <ManageApiKeys />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          {userData.currentUser && (
            <UserTasks
              tasks={
                userData?.currentUser?.tasks?.filter(
                  (task): task is Task => task !== null && task !== undefined
                ) ?? []
              }
            />
          )}
        </Grid>
      </Grid>
      <ToastContainer />
    </>
  );
};
