import { Box, Card, CardContent, Typography } from '@mui/material';
import { colorThemes } from '../../theme';
import TextResultCard from './TextResultCard';
import {
  MLFramework,
  MermaidGraphicOutput,
  Standard,
  TaskType,
} from '../../__generated__/pydantic_parsers';
import { DocumentationComponentResult } from '../../__generated__/gql/graphql';
import ArtifactViewer from './ArtifactViewer';
import { FactViewer } from './FactViewer';
import MermaidGraphic from '../../components/MermaidGraphic';

interface DocumentationResultCardProps {
  component: DocumentationComponentResult;
}

export default function DocumentationResultCard({
  component,
}: DocumentationResultCardProps) {
  let content = null;
  // TODO: generate enums in prompt templates and replaces hard coded
  // string values with enums
  switch (component.outputFormat) {
    case 'Standard':
      content = (
        <TextResultCard parsedOutput={JSON.parse(component.text) as Standard} />
      );
      break;
    case 'TaskType':
      content = (
        <CardContent>
          <FactViewer
            factResult={(JSON.parse(component.text) as TaskType).task_type}
          />
        </CardContent>
      );
      break;
    case 'MLFramework':
      content = (
        <CardContent>
          <FactViewer
            factResult={
              (JSON.parse(component.text) as MLFramework).ml_framework
            }
          />
        </CardContent>
      );
      break;
    case 'MermaidGraphicOutput':
      content = (
        <CardContent>
          <MermaidGraphic
            chart={
              (JSON.parse(component.text) as MermaidGraphicOutput).md_string
            }
          />
        </CardContent>
      );
      break;
    default:
      content = <CardContent>{component.text}</CardContent>;
  }
  return (
    <Card sx={{ backgroundColor: '#E9F2F7', width: '100%' }}>
      <CardContent
        style={{
          display: 'flex',
          alignItems: 'start',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <Typography color={colorThemes.DARK_BLUE_600} variant="h6">
          {component.name}
        </Typography>
        {(!component.artifacts || component.artifacts.length === 0) && (
          <Box color={colorThemes.DARK_BLUE_600}>{content}</Box>
        )}
        {component.artifacts?.map(artifact => (
          <ArtifactViewer key={artifact?.id} artifact={artifact!} />
        ))}
      </CardContent>
    </Card>
  );
}
