import { useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  Modal,
  CardHeader,
  Box,
  Tooltip,
} from '@mui/material';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import ProjectTypeStage from './ProjectTypeStage';
import ModelTypeStage from './ModelTypeStage';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

import {
  OrganizationDepartmentsQuery,
  ProjectTypeEnum,
  RiskClass,
  UsersBasicInfoQuery,
} from '../../__generated__/gql/graphql';
import { gql, useMutation } from '@apollo/client';
import ModelSpesificationStage from './ModelSpesificationStage';
import UserFacingModalStage from './UserFacingModalStage';
import { GET_PROJECTS } from '../ProjectView';
import { toast } from 'react-toastify';
import RiskClassification from '../../risk_classification/RiskClassification';

const CREATE_PROJECT = gql(`
  mutation CreateProject(
  $title: String!,
  $description: String,
  $department: String,
  $is_purchased: Boolean,
  $project_type: ProjectTypeEnum,
  $stage: StageEnum,
  $responsible: String,
  $allowed_data_types: [AllowedDataTypeEnum],
  $allowed_task_types: [AllowedTaskTypeEnum],
  $usage_policy: String,
  $steps_description: String,
  $risk_class: RiskClassInput
) {
  createProject(
    title: $title,
    description: $description,
    department: $department,
    isPurchased: $is_purchased,
    projectType: $project_type,
    stage: $stage,
    responsible: $responsible,
    allowedDataTypes: $allowed_data_types,
    allowedTaskTypes: $allowed_task_types,
    usagePolicy: $usage_policy,
    stepDescription: $steps_description,
    riskClass: $risk_class
  ) {
    project {
      id
    }
  }
}
`);

enum StageTypes {
  ProjectType,
  ModelType,
  ModelSpecificationStage,
  UserFacingModelStage,
  RiskClassQuestionnaire,
}

interface ProjectCreationModalProps {
  isOpen: boolean;
  onClose: () => void;
  userData: UsersBasicInfoQuery;
  departmentsData: OrganizationDepartmentsQuery;
}

function ProjectCreationModal({
  isOpen,
  onClose,
  userData,
  departmentsData,
}: ProjectCreationModalProps) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formSchema = {
    isPurchased: null,
    purchasedModel: ProjectTypeEnum.Custom,
    title: '',
    description: '',
    responsiblePerson: '',
    department: '',
    usagePolicy: '',
    allowedDataTypes: [],
    allowedTaskTypes: [],
    stage: null,
    stepsDescription: '',
  };

  const methods = useForm({ defaultValues: formSchema });

  const { control, getValues } = methods;

  const [stage, setStage] = useState<StageTypes>(StageTypes.ProjectType);

  const [createProject] = useMutation(CREATE_PROJECT, {
    refetchQueries: [{ query: GET_PROJECTS }],
    onCompleted: () => {
      onClose();
      toast.success('Project created successfully');
    },
    onError: () => {
      toast.error('Error creating project');
    },
  });

  const title = useWatch({ control, name: 'title' });
  const description = useWatch({ control, name: 'description' });
  const responsiblePerson = useWatch({ control, name: 'responsiblePerson' });

  const isLastPage =
    stage === StageTypes.UserFacingModelStage ||
    stage === StageTypes.ModelSpecificationStage;

  function onNextProjectSelection() {
    if (methods.getValues('isPurchased')) {
      setStage(StageTypes.ModelType);
    } else {
      setStage(StageTypes.ModelSpecificationStage);
    }
  }

  function onNextModelSelection() {
    setStage(StageTypes.ModelSpecificationStage);
  }

  function onNextUserFacingModal() {
    setStage(StageTypes.UserFacingModelStage);
  }

  function onNextRiskClassQuestionnaire() {
    setStage(StageTypes.RiskClassQuestionnaire);
  }

  function nextStepRiskClass() {
    setStage(StageTypes.ModelSpecificationStage);
  }
  const BackButton = () => {
    if (stage === StageTypes.ProjectType) {
      return null;
    }
    function navigateBack() {
      switch (stage) {
        case StageTypes.ModelType:
          setStage(StageTypes.ProjectType);
          break;
        case StageTypes.ModelSpecificationStage:
          if (methods.getValues('isPurchased')) {
            setStage(StageTypes.ModelType);
          } else {
            setStage(StageTypes.ProjectType);
          }
          break;
        case StageTypes.UserFacingModelStage:
          setStage(StageTypes.ModelSpecificationStage);
          break;
        case StageTypes.RiskClassQuestionnaire:
          setStage(StageTypes.ModelSpecificationStage);
          break;
      }
    }
    return (
      <Button
        startIcon={<ArrowBackRoundedIcon />}
        onClick={navigateBack}
        variant="outlined"
      >
        Back
      </Button>
    );
  };
  const [riskClass, setRiskClass] = useState<RiskClass | undefined>();
  const handleCreateProject = () => {
    const projectData = {
      title: getValues('title'),
      description: getValues('description'),
      department: getValues('department'),
      is_purchased: getValues('isPurchased'),
      project_type: getValues('purchasedModel'),
      stage: getValues('stage'),
      responsible: getValues('responsiblePerson'),
      allowed_data_types: getValues('allowedDataTypes'),
      allowed_task_types: getValues('allowedTaskTypes'),
      usage_policy: getValues('usagePolicy'),
      steps_description: getValues('stepsDescription'),
      risk_class: riskClass,
    };

    createProject({
      variables: projectData,
    });
  };

  const isCreateButtonDisabled = !(title && description && responsiblePerson);

  const getDisabledReason = () => {
    if (!title) return 'Title is required';
    if (!description) return 'Description is required';
    if (!responsiblePerson) return 'Responsible person is required';
    return '';
  };

  const stages: Record<StageTypes, JSX.Element> = {
    [StageTypes.ProjectType]: (
      <ProjectTypeStage onClick={onNextProjectSelection} />
    ),
    [StageTypes.ModelType]: <ModelTypeStage onClick={onNextModelSelection} />,
    [StageTypes.ModelSpecificationStage]: (
      <ModelSpesificationStage
        onClick={onNextUserFacingModal}
        usersData={userData}
        departmentsData={departmentsData}
        onNextRiskClassQuestionnaire={onNextRiskClassQuestionnaire}
        riskClass={riskClass}
      />
    ),
    [StageTypes.RiskClassQuestionnaire]: (
      <RiskClassification
        setRiskClass={setRiskClass}
        nextStepRiskClass={nextStepRiskClass}
      />
    ),
    [StageTypes.UserFacingModelStage]: <UserFacingModalStage />,
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        methods.reset();
        onClose();
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card
        sx={{
          maxHeight: '90%',
          minHeight: '70%',
          width: '80%',
          overflowY: 'scroll',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CardHeader title="Create a new project" />
        <CardContent
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <FormProvider {...methods}>{stages[stage]}</FormProvider>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 'auto',
            }}
          >
            <BackButton />
            {isLastPage && (
              <Tooltip
                title={isCreateButtonDisabled ? getDisabledReason() : ''}
              >
                <span>
                  <Button
                    onClick={handleCreateProject}
                    variant="contained"
                    disabled={isCreateButtonDisabled}
                  >
                    Create Project
                  </Button>
                </span>
              </Tooltip>
            )}
          </Box>
        </CardContent>
      </Card>
    </Modal>
  );
}

export default ProjectCreationModal;
