import { Box, Typography } from '@mui/material';
import { colorThemes } from '../../theme';
import { DocumentationResultObject } from '../../__generated__/gql/graphql';
import DocumentationResultCard from './DocumentationResultCard';
import { v4 as uuidv4 } from 'uuid';
import { formatDateTime } from '../../utils/dateTimeHelpers';

export const DocumentationResultViewer = ({
  creationTimestamp,
  project,
  documentationResult,
}: {
  project: string;
  creationTimestamp: string;
  documentationResult:
    | DocumentationResultObject['generatedDocumentation']
    | null
    | undefined;
}) => {
  const formattedDateTime = formatDateTime(creationTimestamp);

  return (
    <>
      <Typography variant="subtitle2" color={colorThemes.DARK_BLUE_600}>
        Project {project}
      </Typography>
      {creationTimestamp && (
        <Typography variant="subtitle1" color="textSecondary">
          Generated: {formattedDateTime}
        </Typography>
      )}
      <Box width="100%" sx={styles.container}>
        {documentationResult ? (
          documentationResult
            .filter(componentResult => componentResult !== null)
            .map(componentResult => (
              <DocumentationResultCard
                component={componentResult!}
                key={`componentResult-${componentResult?.name}-${uuidv4()}`}
              />
            ))
        ) : (
          <Typography variant="h5" sx={styles.text}>
            No documentation generated yet. Click the Generate Documentation
            button to get started.
          </Typography>
        )}
      </Box>
    </>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: '12px',
    flex: 1,
  },
  text: {
    textAlign: 'center',
    color: colorThemes.DARK_BLUE_600,
    fontSize: '1.2rem',
  },
};
