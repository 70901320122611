import React, { useState } from 'react';
import { useMutation, gql, useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Drawer,
  DrawerProps,
  Grid,
  List,
  ListItem,
  TextField,
  Typography,
} from '@mui/material';
import { colorThemes } from '../theme';
import AddIcon from '@mui/icons-material/Add';
import {
  RequirementCompletionStatusEnum,
  EvidenceModelTypes,
  MaterializedRequirement,
} from '../__generated__/gql/graphql';
import RequirementProgressStepper from './RequirementProgressStepper';
import { NavigateNext } from '@mui/icons-material';
import EvidenceCard from './EvidenceCard';
import { DateTime } from 'luxon';

const UPDATE_GOVERNANCE_REQUIREMENT_STATUS = gql`
  mutation UpdateGovernanceRequirementStatus(
    $materializedRequirementId: String!
    $markedAsCompleted: Boolean
    $completionStatus: RequirementCompletionStatusEnum
  ) {
    updateGovernanceRequirementStatus(
      materializedRequirementId: $materializedRequirementId
      markedAsCompleted: $markedAsCompleted
      completionStatus: $completionStatus
    ) {
      governanceRequirementStatus {
        materializedRequirementId
        markedAsCompleted
        completionStatus
      }
    }
  }
`;

const ADD_GOVERNANCE_EVIDENCE = gql`
  mutation AddGovernanceEvidence(
    $materializedRequirementId: String!
    $evidenceTitle: String
    $evidenceDescription: String
    $evidenceFile: String
    $evidenceType: EvidenceModelTypes
  ) {
    addGovernanceEvidence(
      materializedRequirementId: $materializedRequirementId
      evidenceTitle: $evidenceTitle
      evidenceDescription: $evidenceDescription
      evidenceFile: $evidenceFile
      evidenceType: $evidenceType
    ) {
      __typename
      governanceRequirementEvidence {
        __typename
        materializedRequirementId
        evidenceFiles {
          __typename
          id
        }
        evidenceTexts {
          __typename
          id
          title
          description
        }
      }
    }
  }
`;

const DELETE_GOVERNANCE_EVIDENCE = gql`
  mutation DeleteGovernanceEvidence(
    $materializedRequirementId: String!
    $evidenceId: String!
    $evidenceType: EvidenceModelTypes!
  ) {
    deleteGovernanceEvidence(
      materializedRequirementId: $materializedRequirementId
      evidenceId: $evidenceId
      evidenceType: $evidenceType
    ) {
      __typename
      governanceRequirementEvidence {
        __typename
        materializedRequirementId
        evidenceFiles {
          __typename
          id
        }
        evidenceTexts {
          __typename
          id
          title
          description
        }
      }
    }
  }
`;

const formatDateTime = (isoString: string) => {
  return DateTime.fromISO(isoString).toLocaleString(DateTime.DATETIME_MED);
};

export type SectionDrawerProps = DrawerProps & {
  requirement: MaterializedRequirement;
};

const cardProps = {
  padding: '12px',
  bgcolor: colorThemes.DARK_BLUE_800,
  borderRadius: '8px',
};

const headingProps = {
  component: 'h3' as const,
  fontSize: '20px',
  fontWeight: 600,
};

export function RequirementDrawer({
  requirement: {
    id,
    title,
    description,
    guidance,
    specifications,
    userStatus: status,
    userEvidence: evidence,
    approvers,
    owners,
  },
  ...props
}: SectionDrawerProps) {
  const client = useApolloClient();
  const [updateStatus] = useMutation(UPDATE_GOVERNANCE_REQUIREMENT_STATUS);
  const [addEvidence] = useMutation(ADD_GOVERNANCE_EVIDENCE);
  const [deleteEvidence] = useMutation(DELETE_GOVERNANCE_EVIDENCE);

  const evidencesCast = evidence?.evidenceTexts ?? [];
  const completionStatus =
    status?.completionStatus ?? RequirementCompletionStatusEnum.NotStarted;

  const [evidenceTitle, setEvidenceTitle] = useState<string>('');
  const [evidenceDescription, setEvidenceDescription] = useState<string>('');
  const [showTextField, setShowTextField] = useState<boolean>(false);

  const getButtonText = () => {
    switch (completionStatus) {
      case RequirementCompletionStatusEnum.NotStarted:
        return 'Mark as Assigned';
      case RequirementCompletionStatusEnum.Assigned:
        return 'Mark as Completed';
      case RequirementCompletionStatusEnum.Completed:
        return 'Mark as Approved';
      case RequirementCompletionStatusEnum.Approved:
        return 'Approved';
      default:
        return 'Update Status';
    }
  };

  const getNextStatus = () => {
    switch (completionStatus) {
      case RequirementCompletionStatusEnum.NotStarted:
        return RequirementCompletionStatusEnum.Assigned;
      case RequirementCompletionStatusEnum.Assigned:
        return RequirementCompletionStatusEnum.Completed;
      case RequirementCompletionStatusEnum.Completed:
        return RequirementCompletionStatusEnum.Approved;
      default:
        return completionStatus;
    }
  };

  const handleUpdateStatus = async (
    newStatus: RequirementCompletionStatusEnum
  ) => {
    try {
      const result = await updateStatus({
        variables: {
          materializedRequirementId: id,
          completionStatus: newStatus,
          markedAsCompleted:
            newStatus === RequirementCompletionStatusEnum.Completed,
        },
      });

      // Update the cache
      const updatedStatus =
        result.data.updateGovernanceRequirementStatus
          .governanceRequirementStatus;

      client.cache.modify({
        id: client.cache.identify({ __typename: 'GovernanceRequirement', id }),
        fields: {
          status: () => ({
            __typename: 'GovernanceRequirementStatus',
            ...updatedStatus,
          }),
        },
      });

      toast.success('Status update successful');
    } catch (error) {
      toast.error('Failed to update status');
      console.error(error);
    }
  };

  const handleEvidenceDelete = async (evidenceId: string) => {
    try {
      const result = await deleteEvidence({
        variables: {
          materializedRequirementId: id,
          evidenceId,
          evidenceType: EvidenceModelTypes.Text,
        },
      });
      const updatedEvidence =
        result.data.deleteGovernanceEvidence.governanceRequirementEvidence;

      client.cache.modify({
        id: client.cache.identify({
          __typename: 'MaterializedRequirement',
          id,
        }),
        fields: {
          userEvidence: () => ({
            __typename: 'GovernanceRequirementEvidence',
            ...updatedEvidence,
          }),
        },
      });
      toast.success('Evidence deleted successfully');
    } catch (error) {
      toast.error('Failed to delete evidence');
      console.error(error);
    }
  };

  const handleEvidenceAdd = async () => {
    try {
      const result = await addEvidence({
        variables: {
          materializedRequirementId: id,
          evidenceDescription,
          evidenceTitle,
          evidenceType: EvidenceModelTypes.Text,
        },
      });
      const updatedEvidence =
        result.data.addGovernanceEvidence.governanceRequirementEvidence;

      client.cache.modify({
        id: client.cache.identify({
          __typename: 'MaterializedRequirement',
          id,
        }),
        fields: {
          userEvidence: () => ({
            __typename: 'GovernanceRequirementEvidence',
            ...updatedEvidence,
          }),
        },
      });
      setEvidenceDescription('');
      setEvidenceTitle('');
      setShowTextField(false);
      toast.success('Evidence added successfully');
    } catch (error) {
      toast.error('Failed to add evidence');
      console.error(error);
    }
  };

  return (
    <Drawer anchor="right" PaperProps={{ sx: { width: '66%' } }} {...props}>
      <Box padding="24px" color={colorThemes.GREY_100}>
        <Box display={'flex'} width={'100%'} justifyContent={'space-between'}>
          <Typography component="h2" fontSize="26px" fontWeight={700}>
            {title}
          </Typography>
          <ButtonGroup>
            <Button
              variant="outlined"
              onClick={() => handleUpdateStatus(getNextStatus())}
              disabled={
                completionStatus === RequirementCompletionStatusEnum.Approved
              }
            >
              {getButtonText()}
            </Button>
          </ButtonGroup>
        </Box>
        <Box
          sx={{
            marginTop: 3,
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography>
              <strong>Owner:</strong>{' '}
              {owners?.map(owner => owner?.name).join(', ')}
            </Typography>
            <Typography>
              <strong>Approver:</strong>{' '}
              {approvers?.map(approver => approver?.name).join(', ')}
            </Typography>
          </Box>
          <Box>
            <Typography>
              <strong>Created:</strong>{' '}
              {status?.created ? formatDateTime(status.created) : ''}
            </Typography>
            <Typography>
              <strong>Last edited:</strong>{' '}
              {status?.lastModified ? formatDateTime(status.lastModified) : ''}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ marginY: '12px', bgcolor: '#295581' }} />
        <Box display="flex" flexDirection="column" gap="16px">
          <Box display="grid" gridTemplateColumns="4fr 6fr" gap="16px">
            <Box {...cardProps} display="flex" flexDirection="column">
              <Typography {...headingProps}>Status</Typography>
              <RequirementProgressStepper status={completionStatus} />
            </Box>
            <Box {...cardProps}>
              <Typography {...headingProps} marginBottom="16px">
                Description
              </Typography>
              <Typography>{description}</Typography>
            </Box>
          </Box>
          <Box {...cardProps}>
            <Typography {...headingProps} marginBottom="16px">
              Guidance
            </Typography>
            <Typography>{guidance}</Typography>
          </Box>
          <Box display="grid" gridTemplateColumns="6fr 4fr" gap="20px">
            <Box
              {...cardProps}
              flex={1}
              display="flex"
              flexDirection="column"
              gap="16px"
            >
              <Box sx={{ '& > *': { mb: 2 } }}>
                <Typography {...headingProps}>Evidence</Typography>
                <Box display="flex" flexDirection={'column'} gap={2}>
                  {evidencesCast.map((evidence, index) => (
                    <EvidenceCard
                      key={index}
                      evidence={evidence!}
                      handleEvidenceDelete={handleEvidenceDelete}
                    />
                  ))}
                </Box>
                {evidencesCast.length === 0 && (
                  <Box
                    sx={{
                      textAlign: 'center',
                      p: 4,
                      borderRadius: 1,
                    }}
                  >
                    <Typography color="text.secondary" sx={{ mb: 2 }}>
                      No evidence added yet.
                    </Typography>
                    {!showTextField && (
                      <Button
                        variant="outlined"
                        startIcon={<AddIcon />}
                        onClick={() => setShowTextField(true)}
                      >
                        Add Evidence
                      </Button>
                    )}
                  </Box>
                )}
                {evidencesCast.length > 0 && (
                  <Box
                    sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}
                  >
                    {!showTextField && (
                      <Button
                        variant="outlined"
                        startIcon={<AddIcon />}
                        onClick={() => setShowTextField(true)}
                      >
                        Add More Evidence
                      </Button>
                    )}
                  </Box>
                )}
                {showTextField && (
                  <Box
                    display="flex"
                    flexDirection={'column'}
                    width={'100%'}
                    gap={2}
                  >
                    <Box display="flex" gap={2}>
                      <TextField
                        variant="outlined"
                        size="medium"
                        label="Add Evidence Title"
                        value={evidenceTitle}
                        onChange={e => setEvidenceTitle(e.target.value)}
                        sx={{ width: '85%' }}
                      />
                      <Button
                        variant="outlined"
                        onClick={handleEvidenceAdd}
                        endIcon={<NavigateNext />}
                        sx={{ width: '15%' }}
                      >
                        Add
                      </Button>
                    </Box>
                    <TextField
                      variant="outlined"
                      size="medium"
                      label="Add Description"
                      value={evidenceDescription}
                      onChange={e => setEvidenceDescription(e.target.value)}
                      multiline
                      rows={4}
                      sx={{ width: '100%' }}
                    />
                  </Box>
                )}
              </Box>
            </Box>
            <Box {...cardProps} flex={1}>
              <Typography {...headingProps}>Specs</Typography>
              <List>
                {specifications?.map(spec => (
                  <ListItem
                    key={spec?.description}
                    sx={{ display: 'flex', gap: '4px' }}
                  >
                    {spec?.description}
                  </ListItem>
                ))}
              </List>
            </Box>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}
