import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  BoxProps,
  Link,
  styled,
} from '@mui/material';
import { colorThemes } from '../theme';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link as ReactRouterLink } from 'react-router-dom';
import ScrollToHashElement from './ScrollToHashElement';
import { ProgressBar } from '../components/ProgressBar';
import { RequirementDrawer } from './RequirementDrawer';
import { RequirementCard } from './RequirementCard';
import {
  TIsoCompletionData,
  TRequirement,
  useIsoCompletionStatus,
} from './useIsoCompletionStatus';

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const CustomList = styled('ol')({
  counterReset: 'section',
  listStyleType: 'none',
  '& .list-item::before': {
    counterIncrement: 'section',
    content: 'counters(section, ".") ". "',
    fontSize: 'inherit',
  },
});

const IsoPage: React.FC = () => {
  const sortedData = useIsoCompletionStatus();

  return (
    <Box display="flex" height="100%" flexDirection="column">
      <Typography variant="h4" marginBottom={3}>
        ISO 42001
      </Typography>
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gap={2}
        flex="1"
        color={colorThemes.GREY_100}
      >
        <ScrollToHashElement />
        <Box
          gridColumn="span 3"
          sx={{ backgroundColor: colorThemes.DARK_BLUE_600 }}
          borderRadius="8px"
          padding="8px"
        >
          <Typography variant="h4" component="h2" marginBottom="11px">
            Chapters
          </Typography>
          <List disablePadding>
            {sortedData.map(category => (
              <ListItem disableGutters key={category?.title}>
                <Link
                  to={`#${category?.title?.replace(' ', '')}`}
                  underline="hover"
                  color="inherit"
                  component={ReactRouterLink}
                  sx={{ alignItems: 'center', display: 'flex' }}
                >
                  <RadioButtonUncheckedIcon
                    sx={{ marginRight: '9px' }}
                    fontSize="small"
                  />
                  {category?.title}
                </Link>
              </ListItem>
            ))}
          </List>
        </Box>
        <Box
          gridColumn="span 9"
          gap="32px"
          display="flex"
          flexDirection="column"
          overflow="hidden"
        >
          <Box display="flex" gap={1}>
            <RequirementsSummaryCard />
            <SpecSummaryCard />
          </Box>
          <CustomList
            sx={{
              padding: 4,
              bgcolor: colorThemes.DARK_BLUE_600,
              borderRadius: '8px',
              display: 'flex',
              height: '100%',
              flexDirection: 'column',
              overflow: 'auto',
            }}
          >
            {sortedData.map(category => (
              <Category category={category} key={category.title} />
            ))}
          </CustomList>
        </Box>
      </Box>
    </Box>
  );
};

const RequirementsSummaryCard: React.FC = () => {
  const isoCompletionData = useIsoCompletionStatus();

  const requirementNumbers = isoCompletionData.reduce(
    (acc, category) => ({
      total: acc.total + (category?.numberOfRequirements ?? 0),
      fulfilled: acc.fulfilled + (category.numberOfFulfilledRequirements ?? 0),
    }),
    { total: 0, fulfilled: 0 }
  );

  return (
    <SummaryCard
      flex={1}
      description="ISO 42001"
      progressLabel="Requirements fulfilled"
      totalNumber={requirementNumbers.total}
      fulfilledNumber={requirementNumbers.fulfilled}
    />
  );
};

const SpecSummaryCard: React.FC = () => {
  const isoCompletionData = useIsoCompletionStatus();

  const specNumbers = isoCompletionData.reduce(
    (acc, category) => ({
      total: acc.total + (category?.numberOfSpecs ?? 0),
      fulfilled: acc.fulfilled + (category?.numberOfFullfilledSpecs ?? 0),
    }),
    { total: 0, fulfilled: 0 }
  );

  return (
    <SummaryCard
      flex={1}
      description="ISO 42001"
      progressLabel="Specifications met"
      totalNumber={specNumbers.total}
      fulfilledNumber={specNumbers.fulfilled}
    />
  );
};

const Category: React.FC<{ category: TIsoCompletionData[number] }> = ({
  category,
}) => {
  return (
    <Accordion TransitionProps={{ unmountOnExit: true }} key={category?.title}>
      <AccordionSummary aria-controls={category?.title} id={category?.title}>
        <Box
          display="grid"
          gridTemplateColumns="1fr 200px"
          alignItems="center"
          paddingRight="8px"
          width="100%"
        >
          <Typography
            id={category?.title?.replace(' ', '')}
            component="li"
            fontWeight={800}
            fontSize="26px"
            display="inline"
            className="list-item"
          >
            {category?.title}
          </Typography>
          <ProgressBar
            value={
              (category.numberOfFullfilledEvidence /
                category.numberOfEvidence) *
              100
            }
            variant="determinate"
          />
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <CustomList sx={{ display: 'grid', gap: '8px', paddingLeft: 0 }}>
          {category?.requirements?.map(requirement => (
            <Requirement
              key={requirement?.description}
              requirement={requirement}
              totalNumberOfSpecs={requirement.numberOfSpecs}
            />
          ))}
        </CustomList>
      </AccordionDetails>
    </Accordion>
  );
};

const SummaryCard: React.FC<
  {
    progressLabel: string;
    description: string;
    totalNumber: number;
    fulfilledNumber: number;
  } & BoxProps
> = ({
  progressLabel,
  description,
  totalNumber,
  fulfilledNumber,
  ...props
}) => {
  return (
    <Box
      padding={4}
      bgcolor={colorThemes.DARK_BLUE_600}
      borderRadius="8px"
      display="flex"
      flexDirection="column"
      gap="4px"
      {...props}
    >
      <Typography variant="h4" component="h2">
        {fulfilledNumber}/{totalNumber}
      </Typography>
      <Typography component="p">{progressLabel}</Typography>
      <ProgressBar
        variant="determinate"
        value={(fulfilledNumber / totalNumber) * 100}
      />
      <Typography component="p">{description}</Typography>
    </Box>
  );
};

const Requirement: React.FC<{
  requirement: TRequirement;
  totalNumberOfSpecs: number;
}> = ({ requirement, totalNumberOfSpecs }) => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  return (
    <>
      <RequirementCard
        requirement={requirement}
        specificationNumber={totalNumberOfSpecs}
        fullfilledSpecificationNumber={requirement.numberOfFullfilledSpecs}
        evidenceNumber={requirement.numberOfEvidence}
        fullfilledEvidenceNumber={requirement.numberOfFulfilledEvidence}
        onClick={() => setIsDrawerOpen(prev => !prev)}
      />
      <RequirementDrawer
        requirement={requirement}
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      />
    </>
  );
};

export default IsoPage;
