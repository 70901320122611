import { useRef } from 'react';
import type { Identifier, XYCoord } from 'dnd-core';

import { useDrag, useDrop } from 'react-dnd';
import DocumentationEntry from '../types/DocumentationEntry';
import { DocumentComponentTypeEnum } from '../../__generated__/gql/graphql';
import { DocumentationItem } from './DocumentationItem';
import { isCustomComponent } from '../common_components/ComponentsList';

interface DocumentationStructureItemProps {
  documentationEntry: DocumentationEntry;
  isDragEnabled: boolean;
  key?: string;
  onDelete: (id: string) => void;
  onDragEnd: (dragIndex: number, hoverIndex: number) => void;
  index: number;
  onSave: (documentationEntry: DocumentationEntry) => void;
}

export interface CardProps {
  id: string;
  text: string;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const DocumentationStructureItem = ({
  documentationEntry,
  isDragEnabled,
  onDelete,
  onDragEnd,
  index,
  onSave,
}: DocumentationStructureItemProps) => {
  // the following code is a slightly changed Simple example
  // to be found here: https://react-dnd.github.io/react-dnd/examples/sortable/simple

  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'Box',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      onDragEnd(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [collected, drag] = useDrag({
    type: 'Box',
    canDrag: isDragEnabled,
    item: () => {
      return { id: documentationEntry.id, index };
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <DocumentationItem
      data-handler-id={handlerId}
      ref={ref}
      isDragEnabled={isDragEnabled}
      onDelete={onDelete}
      documentationEntry={documentationEntry}
      editable={isCustomComponent(
        DocumentComponentTypeEnum[documentationEntry.type]
      )}
      onSave={onSave}
      {...collected}
    />
  );
};
