import { Box } from '@mui/material';
import { colorThemes } from '../theme';

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ height: '90%' }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box width={'100%'} height={'100%'}>
          {children}
        </Box>
      )}
    </div>
  );
};

type TabPanelHeaderProps = {
  children?: React.ReactNode;
};

const TabPanelHeader = (props: TabPanelHeaderProps) => {
  const { children } = props;

  return (
    <Box
      sx={{
        backgroundColor: '#EFEFEF',
        padding: 2,
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        color: 'black',
        height: '64px',
      }}
    >
      {children}
    </Box>
  );
};

type TabPanelContentProps = {
  children?: React.ReactNode;
};

const TabPanelContent = (props: TabPanelContentProps) => {
  const { children } = props;

  return (
    <Box
      sx={{
        padding: 2,
        ...styles.container,
      }}
    >
      {children}
    </Box>
  );
};

TabPanel.Header = TabPanelHeader;
TabPanel.Content = TabPanelContent;

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: '12px',
    flex: 1,
  },
};
