import { Select, MenuItem } from '@mui/material';
import { useState } from 'react';
import { colorThemes } from '../theme';

interface Props {
  experimentIds: string[];
  selectedExperiment?: string;
  setSelectedExperiment: (newSelection: string) => void;
}

const NO_EXPERIMENTS_AVAILABLE_ITEM_KEY = 'No experiments available';

export default function ExperimentSelector({
  experimentIds,
  selectedExperiment,
  setSelectedExperiment,
}: Props): JSX.Element {
  const latestExperimentId = experimentIds[experimentIds.length - 1];
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const defaultValue =
    selectedExperiment ??
    (experimentIds.length > 0
      ? experimentIds[experimentIds.length - 1]
      : NO_EXPERIMENTS_AVAILABLE_ITEM_KEY);

  return (
    <Select
      size="small"
      value={defaultValue}
      displayEmpty
      open={isDropdownOpen}
      onOpen={() => setIsDropdownOpen(true)}
      onClose={() => setIsDropdownOpen(false)}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 200,
            overflow: 'auto',
          },
        },
      }}
      sx={{
        width: 120,
        color: 'black',
        padding: '4px 8px',

        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'black',
        },

        '& .MuiOutlinedInput-input': {
          padding: '0',
        },

        '&:hover': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'black',
            border: 2,
          },
        },

        '& .MuiSvgIcon-root': {
          color: 'black',
        },

        '.MuiPaper-root-MuiPopover-paper-MuiMenu-paper': {
          backgroundColor: colorThemes.GREY_100,
          color: 'black',
        },
      }}
      onChange={event => setSelectedExperiment(event.target.value)}
      renderValue={selected =>
        selected === latestExperimentId
          ? isDropdownOpen
            ? `Latest -- ${selected}`
            : 'Latest'
          : selected
      }
    >
      {experimentIds.length > 0 ? (
        experimentIds.map(id => (
          <MenuItem key={id} value={id}>
            {id === latestExperimentId ? `Latest -- ${id}` : id}
          </MenuItem>
        ))
      ) : (
        <MenuItem disabled value={NO_EXPERIMENTS_AVAILABLE_ITEM_KEY}>
          No experiments available
        </MenuItem>
      )}
    </Select>
  );
}
