import { useEffect, useState } from 'react';

import Xarrow, { useXarrow } from 'react-xarrows';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useQuery } from '@apollo/client';
import { Box, Button, Card, CardContent, Tooltip } from '@mui/material';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { ErrorBoundary } from 'react-error-boundary';
import VisualizerGraphCard from './VisualizerGraphCard';
import { gql } from '../__generated__/gql';
import { Experiment } from '../__generated__/gql/graphql';
import RootNodeCard from './RootNodeCard';
import ExperimentComparisonView from './experiment_comparison_view/ExperimentComparisonView';
import {
  autoRefreshEnabled,
  autoRefreshInterval,
  defaultAppliedRules,
  detailExperimentState,
} from './TreeSettingsAtoms';
import { Loading } from '../components/Loading';
import ExperimentDetailViewSuspenseWrapper from './ExperimentDetailView/ExperimentDetailViewSuspenseWrapper';

export const GET_LOCATIONS = gql(`
query TreeVisualizer_items($project_id: String!, $filters: [Rule]) {
  project(id: $project_id, filters: $filters) {
    id
    title
    businessMeasurement {
      truePositive
      trueNegative
      falsePositive
      falseNegative
    }
    experiments {
      id
      title
      parentExperimentId
      hypothesis
      creationTimestamp
      instanceRuns {
        metrics
        parameters
        metricsHistory{
          metricName
          history {
            value 
            step
            timeStamp
          }
        }
      }
    }
  }
}
`);

function constructTree(nodes: Array<any>): Array<Array<Experiment>> {
  const nodeMap = new Map();
  const childMap: Map<string, Array<Experiment>> = new Map();
  const rootNodes: Array<any> = [];
  let maxDepth = 0;

  nodes.forEach(node => {
    childMap.set(node.id, []);
    nodeMap.set(node.id, node);
    if (!node.parentExperimentId) {
      rootNodes.push(node);
    }
  });

  nodes.forEach(node => {
    const parentId = node.parentExperimentId;
    if (parentId) {
      const parent = nodeMap.get(parentId);
      if (parent) {
        childMap.get(parent.id)?.push(node);
      }
    }
  });

  const result = [];
  const queue = [...rootNodes];
  let depth = 0;

  while (queue.length > 0) {
    result[depth] = queue.slice();
    if (depth > maxDepth) {
      maxDepth = depth;
    }
    depth += 1;
    const nextQueue: Array<Experiment> = [];
    queue.forEach(node => {
      nextQueue.push(...(childMap.get(node.id) ?? []));
    });
    queue.length = 0;
    queue.push(...nextQueue);
  }

  while (result.length <= maxDepth) {
    result.push([]);
  }

  return result;
}

type Props = {
  projectId: string;
};

export default function TreeVisualizerGraph({ projectId }: Props) {
  const enableAutoRefresh = useRecoilValue(autoRefreshEnabled);
  const refreshInterval = useRecoilValue(autoRefreshInterval);
  const updateXarrow = useXarrow();
  const appliedRules = useRecoilValue(defaultAppliedRules);
  const [detailExperiment, setDetailExperiment] = useRecoilState(
    detailExperimentState
  );
  const { loading, error, data, startPolling, stopPolling, refetch } = useQuery(
    GET_LOCATIONS,
    {
      variables: { project_id: projectId, filters: appliedRules },
      pollInterval: 2500,
      onCompleted: () => {
        setDetailExperiment(undefined as unknown as Experiment);
      },
    }
  );

  useEffect(() => {
    if (enableAutoRefresh) {
      startPolling(refreshInterval);
    } else {
      stopPolling();
    }
  }, [
    refreshInterval,
    enableAutoRefresh,
    startPolling,
    stopPolling,
    projectId,
  ]);

  useEffect(() => {
    refetch();
    stopPolling();
  }, [appliedRules, refetch, stopPolling]);

  const [isInSelectionMode, setIsInSelectionMode] = useState(false);
  const [selectedExperiments, setSelectedExperiments] = useState<Experiment[]>(
    []
  );
  const [showComparison, setShowComparison] = useState(false);

  const resetSelection = () => {
    setSelectedExperiments([]);
    setIsInSelectionMode(false);
  };

  useEffect(resetSelection, [projectId]);

  if (loading) {
    return <Loading message="Loading project experiments ..." />;
  }
  if (error) {
    return <p>Error :{error.message}</p>;
  }

  const { project } = data!;
  const experiments = project?.experiments ?? [];
  const nodes: Array<Array<Experiment>> = constructTree(experiments);
  const businessMeasurement = project?.businessMeasurement;

  const onUnselectExperiment = (experimentId: string) => {
    selectedExperiments.splice(
      selectedExperiments.findIndex(
        (selectedExperiment: any) => selectedExperiment.id === experimentId
      ),
      1
    );
    setSelectedExperiments([...selectedExperiments]);
    if (selectedExperiments.length < 2) {
      setShowComparison(false);
    }
  };

  return (
    <Box
      flex={1}
      onScroll={() => updateXarrow()}
      justifyContent="space-between"
      flexDirection="row"
      margin="10px"
    >
      <Box
        display="flex"
        width="fit-content"
        height="fit-content"
        minWidth="100%"
        minHeight="85vh"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        flexGrow={1}
      >
        {nodes.map((layerNodes, depth) => (
          <Box
            key={depth}
            flex={1}
            gap="8px"
            sx={{
              display: 'flex',
              ml: 10,
              mr: 10,
              flexDirection: 'column',
              justifyContent: 'space-around',
              '&:hover': { cursor: 'pointer' },
            }}
          >
            {layerNodes.map((node, nodeColumdIdx) => (
              <div key={node.id} id={node.id} style={{ width: 'fit-content' }}>
                <ErrorBoundary
                  fallback={
                    <Card sx={{ width: 275 }}>
                      <CardContent>
                        Could not load card content for node id: {node.id}
                      </CardContent>
                    </Card>
                  }
                >
                  {nodeColumdIdx === 0 && depth === 0 ? (
                    <RootNodeCard nodeId={node.id} />
                  ) : (
                    <VisualizerGraphCard
                      id={node.id}
                      title={node.title}
                      description={node.id}
                      hypothesis={node.hypothesis!}
                      mainMetric={node.instanceRuns![0]?.metrics}
                      onClick={() => {
                        setDetailExperiment(node);
                      }}
                      isSelectionMode={isInSelectionMode}
                      isExperimentSelected={
                        selectedExperiments.find(
                          (selectedExperiment: Experiment) =>
                            selectedExperiment.id === node.id
                        ) !== undefined
                      }
                      onSelection={(selected: boolean) => {
                        if (selected) {
                          setSelectedExperiments([
                            ...selectedExperiments,
                            node,
                          ]);
                        } else {
                          onUnselectExperiment(node.id);
                        }
                      }}
                    />
                  )}
                </ErrorBoundary>
              </div>
            ))}
          </Box>
        ))}

        {nodes
          .flat()
          .map(node =>
            node.parentExperimentId !== null ? (
              <Xarrow
                color="white"
                showHead={false}
                curveness={0.5}
                key={node.parentExperimentId!}
                start={node.parentExperimentId!}
                startAnchor="right"
                endAnchor="left"
                end={node.id}
              />
            ) : null
          )}
      </Box>
      {detailExperiment && (
        <ExperimentDetailViewSuspenseWrapper
          projectId={projectId}
          experiment={detailExperiment}
          businessMeasurement={businessMeasurement}
        />
      )}
      <ExperimentComparisonView
        experiments={selectedExperiments}
        onUnselectExperiment={onUnselectExperiment}
        isOpen={showComparison}
        onClose={() => setShowComparison(false)}
      />
      <Box
        sx={{
          position: 'fixed',
          bottom: '30px',
          right: '30px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '5px',
        }}
      >
        {isInSelectionMode ? (
          <>
            <Tooltip title="At least 2 experiments required for comparison">
              <span>
                <Button
                  variant="outlined"
                  disabled={selectedExperiments.length < 2}
                  onClick={() => setShowComparison(true)}
                >
                  COMPARE
                </Button>
              </span>
            </Tooltip>
            <Button variant="outlined" color="error" onClick={resetSelection}>
              CANCEL
            </Button>
          </>
        ) : (
          <Button
            variant="outlined"
            startIcon={<CompareArrowsIcon />}
            onClick={() => setIsInSelectionMode(true)}
          >
            COMPARE
          </Button>
        )}
      </Box>
    </Box>
  );
}
