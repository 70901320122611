import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Divider,
  Link,
  Typography,
} from '@mui/material';
import { colorThemes } from '../../theme';
import { LoadingButton } from '@mui/lab';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Home } from '@mui/icons-material';

export const DocumentationSidebar = ({
  pageTitle,
  breadcrumbs,
  children,
  cancelButtonProps,
  submitButtonProps,
}: {
  pageTitle: string;
  breadcrumbs?: {
    backlink: {
      url: string;
      text: string;
    };
    current: {
      text: string;
    };
  };
  children: React.ReactNode;
  cancelButtonProps?: {
    action: () => void;
    text: string;
  };
  submitButtonProps?: {
    action: () => void;
    text: string;
    loading?: boolean;
  };
}) => {
  return (
    <>
      <Typography variant="h4" marginBottom={1} color="white">
        {pageTitle}
      </Typography>

      {breadcrumbs && (
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link
            underline="hover"
            key="1"
            color="inherit"
            component={ReactRouterLink}
            to="/"
          >
            <Home sx={{ fontSize: '12px', marginBottom: ' -2px' }} />
          </Link>
          ,
          <Link
            key="2"
            underline="hover"
            fontSize="12px"
            color="text.primary"
            component={ReactRouterLink}
            to={breadcrumbs.backlink.url}
          >
            {breadcrumbs.backlink.text}
          </Link>
          ,
          <Typography
            key="3"
            fontSize="12px"
            color="text.primary"
            padding="0"
            component="span"
          >
            {breadcrumbs.current.text}
          </Typography>
          ,
        </Breadcrumbs>
      )}

      <Divider
        sx={{ borderColor: colorThemes.DARK_BLUE_100, marginBottom: 1 }}
      />

      {children}

      {(cancelButtonProps || submitButtonProps) && (
        <Divider
          sx={{
            borderColor: colorThemes.DARK_BLUE_100,
            marginBottom: 2,
            marginTop: 'auto',
          }}
        />
      )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {cancelButtonProps && (
          <Button onClick={cancelButtonProps.action} sx={{ cursor: 'pointer' }}>
            {cancelButtonProps.text}
          </Button>
        )}

        {submitButtonProps && (
          <LoadingButton
            onClick={submitButtonProps.action}
            loading={submitButtonProps.loading}
            loadingIndicator={<CircularProgress size={24} sx={{ mr: 1 }} />}
            sx={{
              borderRadius: '8px',
              paddingLeft: '16px',
              paddingRight: '16px',
              '&:hover': {
                backgroundColor: colorThemes.YELLOW_200,
              },
            }}
            variant="contained"
          >
            {submitButtonProps.text}
          </LoadingButton>
        )}
      </Box>
    </>
  );
};
