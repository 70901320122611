import { Box, Typography } from '@mui/material';
import { colorThemes } from '../../theme';

export const EmptyDocumentState = ({
  title,
  text,
}: {
  title: string;
  text: string;
}) => {
  return (
    <Box
      sx={{
        borderRadius: '16px',
        width: '100%',
        minWidth: '800px',
        minHeight: '800px',
        maxWidth: '1000px',
        backgroundColor: colorThemes.GREY_100,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 4,
      }}
    >
      <Typography variant="h4" color={colorThemes.GREY_200}>
        {title}
      </Typography>
      <Typography variant="body1" color={colorThemes.GREY_200}>
        {text}
      </Typography>
    </Box>
  );
};
