import { Box, Grid, IconButton, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { colorThemes } from '../theme';
import { NavigateBefore } from '@mui/icons-material';
import QuestionnaireAnswerButton from './QuestionnaireAnswersButton';
import { QuestionaireQuestion, RiskQuestionnaireCategories } from './Questions';
import QuestionnaireAnswerCheckbox from './QuestionnaireAnswersCheckbox';
import { Answer } from './RiskClassification';
import QuestionnaireResult from './QuestionnaireResult';
import HorizontalLinearStepper from '../sign_up/HorizontalLinearStepper';
import NavigateNext from '@mui/icons-material/NavigateNext';
import { RiskClass } from '../__generated__/gql/graphql';

interface ClassificationQuestionaireProps {
  projectId?: string;
  questionaireQuestion: QuestionaireQuestion;
  handleNextStep: (id: number, values: number[]) => void;
  handleBackStep: () => void;
  answers: Answer[];
  questionPosition: number;
  setRiskClass?: (riskClass: RiskClass) => void;
  nextStepRiskClass?: () => void;
}

const ClassificationQuestionaire: FC<ClassificationQuestionaireProps> = ({
  projectId,
  questionaireQuestion,
  handleNextStep,
  handleBackStep,
  answers,
  questionPosition,
  setRiskClass,
  nextStepRiskClass,
}) => {
  const [checkboxValues, setCheckboxValues] = useState<number[]>(
    answers[questionPosition]?.values ?? []
  );

  const handleCheckboxValue = (value: number) => {
    setCheckboxValues(prevValues => {
      if (value === questionaireQuestion.options.length - 1) {
        // if last checkbox checked deselect all others
        return prevValues.includes(value) ? [] : [value];
      } else {
        const newValues = prevValues.includes(value)
          ? prevValues.filter(v => v !== value)
          : [...prevValues, value];
        // deselect last checkbox if any other is selected
        return newValues.filter(
          v => v !== questionaireQuestion.options.length - 1
        );
      }
    });
  };

  const isChecked = (index: number) => {
    return checkboxValues.includes(index);
  };

  return (
    <Box
      overflow="auto"
      gap={2}
      sx={{
        width: '85%',
        height: '80%',
        backgroundColor: colorThemes.DARK_BLUE_600,
        borderRadius: '2rem',
        padding: '20px',
        pt: '40px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      {questionPosition > 0 ? (
        <Box display={'flex'} height={'100'} alignItems={'center'}>
          <IconButton
            onClick={handleBackStep}
            sx={{
              backgroundColor: colorThemes.ACTION_BLUE,
              '&:hover': { bgcolor: colorThemes.DARK_BLUE_700 },
              maxWidth: '40px',
              height: '40px',
            }}
          >
            <NavigateBefore />
          </IconButton>
        </Box>
      ) : (
        <Box width={'40px'} />
      )}

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        flexGrow={1}
        gap="20px"
        maxWidth={'80%'}
      >
        {questionaireQuestion.category !== 5 ? (
          <HorizontalLinearStepper
            step={questionaireQuestion.category}
            stepLabels={RiskQuestionnaireCategories}
          />
        ) : null}
        <Typography fontWeight="bold" fontSize={40} textAlign={'center'}>
          {questionaireQuestion.title}
        </Typography>
        <Typography fontSize={18} textAlign={'center'}>
          {questionaireQuestion.description}
        </Typography>
        <Grid container spacing={2} pt={4}>
          {questionaireQuestion.options.map((option, index) => (
            <Grid item xs={12} md={6} key={index}>
              {questionaireQuestion.type === 'MULTIPLE_CHOICE' ? (
                <QuestionnaireAnswerCheckbox
                  key={option.name}
                  option={option}
                  handleCheckboxValue={handleCheckboxValue}
                  isChecked={isChecked(index)}
                />
              ) : questionaireQuestion.type === 'SINGLE_CHOICE' ? (
                <QuestionnaireAnswerButton
                  option={option}
                  handleNextStep={handleNextStep}
                  questionId={questionaireQuestion.id}
                />
              ) : null}
            </Grid>
          ))}
        </Grid>
        {questionPosition === 24 ? (
          <QuestionnaireResult
            projectId={projectId}
            setRiskClass={setRiskClass}
            nextStepRiskClass={nextStepRiskClass}
          />
        ) : null}
      </Box>
      {questionaireQuestion.type === 'MULTIPLE_CHOICE' ? (
        <Box display={'flex'} height={'100'} alignItems={'center'}>
          <IconButton
            disabled={checkboxValues.length === 0}
            onClick={() =>
              handleNextStep(questionaireQuestion.id, checkboxValues)
            }
            sx={{
              backgroundColor: colorThemes.ACTION_BLUE,
              '&:hover': { bgcolor: colorThemes.DARK_BLUE_700 },
              '&:disabled': { bgcolor: colorThemes.DARK_BLUE_700 },
              maxWidth: '40px',
              height: '40px',
            }}
          >
            <NavigateNext />
          </IconButton>
        </Box>
      ) : (
        <Box width={'40px'} />
      )}
    </Box>
  );
};

export default ClassificationQuestionaire;
