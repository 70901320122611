import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  BoxProps,
  Link,
  styled,
} from '@mui/material';
import { colorThemes } from '../theme';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link as ReactRouterLink, useParams } from 'react-router-dom';
import { ProgressBar } from '../components/ProgressBar';

import { gql, useSuspenseQuery } from '@apollo/client';
import {
  GetProjectGovernanceFrameworkQuery,
  MaterializedCategory,
  MaterializedRequirement,
  RequirementCompletionStatusEnum,
} from '../__generated__/gql/graphql';
import ScrollToHashElement from '../iso/ScrollToHashElement';

import { ToastContainer } from 'react-toastify';
import { RequirementCard } from '../governance_framework_view/RequirementCard';
import { RequirementDrawer } from '../governance_framework_view/RequirementDrawer';

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const CustomList = styled('ol')({
  counterReset: 'section',
  listStyleType: 'none',
  '& .list-item::before': {
    counterIncrement: 'section',
    content: 'counters(section, ".") ". "',
    fontSize: 'inherit',
  },
});

const GET_PROJECT_GOVERNANCE_FRAMEWORK = gql`
  query GetProjectGovernanceFramework($frameworkId: String!) {
    materializedProjectGovernance(frameworkId: $frameworkId) {
      id
      name
      categories {
        categoryName
        requirements {
          id
          title
          description
          guidance
          userStatus {
            completionStatus
          }
          approvers {
            id
            name
            initials
          }
          owners {
            id
            name
            initials
          }
          userEvidence {
            evidenceTexts {
              id
              title
            }
          }
          specifications {
            id
            description
          }
        }
      }
    }
  }
`;

// TODO: Refactor this horrible code :P

function countCategoryRequirements(category: MaterializedCategory) {
  let completedCount = 0;
  let totalCount = 0;

  (category?.requirements ?? []).forEach(requirement => {
    totalCount++;
    if (
      requirement?.userStatus?.completionStatus ===
      RequirementCompletionStatusEnum.Approved
    ) {
      completedCount++;
    }
  });

  return {
    completedCount,
    totalCount,
  };
}

function countRequirementProgress(data: GetProjectGovernanceFrameworkQuery) {
  let totalCompletedCount = 0;
  let totalRequirementCount = 0;

  (data?.materializedProjectGovernance?.categories ?? []).forEach(category => {
    const categoryCounts = countCategoryRequirements(
      category as MaterializedCategory
    );
    totalCompletedCount += categoryCounts.completedCount;
    totalRequirementCount += categoryCounts.totalCount;
  });

  return {
    completedCount: totalCompletedCount,
    totalCount: totalRequirementCount,
  };
}

const ProjectGovernancePage: React.FC = () => {
  const { frameworkId } = useParams<{ frameworkId: string }>();
  const { data } = useSuspenseQuery<GetProjectGovernanceFrameworkQuery>(
    GET_PROJECT_GOVERNANCE_FRAMEWORK,
    {
      variables: { frameworkId },
    }
  );
  const categories = (data?.materializedProjectGovernance?.categories ??
    []) as MaterializedCategory[];
  return (
    <Box display="flex" height="100%" flexDirection="column">
      <Typography variant="h4" marginBottom={3}>
        {data?.materializedProjectGovernance?.name}
      </Typography>
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gap={2}
        flex="1"
        color={colorThemes.GREY_100}
        overflow={'hidden'}
      >
        <ScrollToHashElement />
        <Box
          gridColumn="span 3"
          sx={{ backgroundColor: colorThemes.DARK_BLUE_600 }}
          borderRadius="8px"
          padding="8px"
        >
          <Typography variant="h4" component="h2" marginBottom="11px">
            Chapters
          </Typography>
          <List disablePadding>
            {categories.map(category => (
              <ListItem disableGutters key={category?.categoryName}>
                <Link
                  to={`#${category?.categoryName?.replace(' ', '')}`}
                  underline="hover"
                  color="inherit"
                  component={ReactRouterLink}
                  sx={{ alignItems: 'center', display: 'flex' }}
                >
                  <RadioButtonUncheckedIcon
                    sx={{ marginRight: '9px' }}
                    fontSize="small"
                  />
                  {category?.categoryName}
                </Link>
              </ListItem>
            ))}
          </List>
        </Box>
        <Box
          gridColumn="span 9"
          display="flex"
          flexDirection="column"
          overflow="auto"
          gap={'16px'}
        >
          <Box display="flex" gap={1}>
            <SummaryCard
              flex={1}
              description="Requirement Progress"
              progressLabel="Requirements fulfilled"
              totalNumber={countRequirementProgress(data).totalCount}
              fulfilledNumber={countRequirementProgress(data).completedCount}
            />
          </Box>
          <CustomList
            sx={{
              padding: 4,
              bgcolor: colorThemes.DARK_BLUE_600,
              borderRadius: '8px',
              display: 'flex',
              height: '100%',
              flexDirection: 'column',
              overflow: 'auto',
              marginBlock: '0px',
            }}
          >
            {categories.map(category => (
              <Category category={category} key={category.categoryName} />
            ))}
          </CustomList>
        </Box>
      </Box>
      <ToastContainer />
    </Box>
  );
};

const Category: React.FC<{ category: MaterializedCategory }> = ({
  category,
}) => {
  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      key={category?.categoryName}
    >
      <AccordionSummary
        aria-controls={category?.categoryName}
        id={category?.categoryName}
      >
        <Box
          display="grid"
          gridTemplateColumns="1fr 200px"
          alignItems="center"
          paddingRight="8px"
          width="100%"
        >
          <Typography
            id={category?.categoryName?.replace(' ', '')}
            component="li"
            fontWeight={800}
            fontSize="26px"
            display="inline"
            className="list-item"
          >
            {category?.categoryName}
          </Typography>
          <ProgressBar
            value={
              (countCategoryRequirements(category).completedCount /
                countCategoryRequirements(category).totalCount) *
              100
            }
            variant="determinate"
          />
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <CustomList sx={{ display: 'grid', gap: '8px', paddingLeft: 0 }}>
          {category?.requirements?.map(requirement => (
            <Requirement
              key={requirement?.description}
              requirement={requirement!}
              totalNumberOfSpecs={requirement?.specifications?.length ?? 0}
              totalNumberOfEvidences={
                (requirement?.userEvidence?.evidenceFiles?.length ?? 0) +
                (requirement?.userEvidence?.evidenceTexts?.length ?? 0)
              }
            />
          ))}
        </CustomList>
      </AccordionDetails>
    </Accordion>
  );
};

const SummaryCard: React.FC<
  {
    progressLabel: string;
    description: string;
    totalNumber: number;
    fulfilledNumber: number;
  } & BoxProps
> = ({
  progressLabel,
  description,
  totalNumber,
  fulfilledNumber,
  ...props
}) => {
  return (
    <Box
      padding={4}
      bgcolor={colorThemes.DARK_BLUE_600}
      borderRadius="8px"
      display="flex"
      flexDirection="column"
      gap="4px"
      {...props}
    >
      <Typography variant="h4" component="h2">
        {fulfilledNumber}/{totalNumber}
      </Typography>
      <Typography component="p">{progressLabel}</Typography>
      <ProgressBar
        variant="determinate"
        value={(fulfilledNumber / totalNumber) * 100}
      />
      <Typography component="p">{description}</Typography>
    </Box>
  );
};

const Requirement: React.FC<{
  requirement: MaterializedRequirement;
  totalNumberOfSpecs: number;
  totalNumberOfEvidences: number;
}> = ({ requirement, totalNumberOfSpecs, totalNumberOfEvidences }) => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  return (
    <>
      <RequirementCard
        requirement={requirement}
        specificationNumber={totalNumberOfSpecs}
        evidenceNumber={totalNumberOfEvidences}
        onClick={() => setIsDrawerOpen(prev => !prev)}
      />
      <RequirementDrawer
        requirement={requirement}
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      />
    </>
  );
};

export default ProjectGovernancePage;
