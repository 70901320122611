import { User } from 'oidc-client-ts';

export function getUser() {
  const oidcStorage = sessionStorage.getItem(
    `oidc.user:${window._env_.AUTH_URL}:trail-lux`
  );
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
}

const generateLink = async (link: string) => {
  const token = getUser()?.access_token;
  const requestOptions = {
    method: 'GET',
    headers: {
      authorization: `Bearer ${token}`,
    },
  };

  const uri = process.env.REACT_APP_TERRA_URL || 'http://localhost:5002';
  const res = await fetch(`${uri}/${link}`, requestOptions);

  return res;
};

export default generateLink;
