import { Box, Card, SxProps, Theme, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { colorThemes } from '../theme';

type Size = 'small' | 'regular';

type Props = {
  onClick: () => void;
  actionDescription?: string;
  size?: Size;
  sx?: SxProps<Theme>;
};
const boxSX = (size: Size, sx?: SxProps<Theme>) => ({
  width: size === 'small' ? 180 : 275,
  height: size === 'small' ? 110 : 128,
  backgroundColor: colorThemes.DARK_BLUE_800,
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: colorThemes.DARK_BLUE_400,
  },
  borderStyle: 'dashed',
  borderWidth: 2,
  borderColor: colorThemes.DARK_BLUE_400,
  ...sx,
  transition: 'all .2s',
});

export default function AddNewItemCard({
  onClick,
  actionDescription,
  size = 'regular',
  sx,
}: Props) {
  return (
    <Card sx={boxSX(size, sx)} onClick={onClick}>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        height="100%"
      >
        <AddIcon
          sx={{ fontSize: size ? 64 : 64, color: colorThemes.YELLOW_400 }}
        />
        <Typography
          variant="h6"
          sx={{ fontWeight: 'bold', color: colorThemes.YELLOW_400 }}
        >
          {actionDescription}
        </Typography>
      </Box>
    </Card>
  );
}
