import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import {
  RiskClassCategoryEnum,
  RiskClassRoleEnum,
} from '../__generated__/gql/graphql';
import {
  getRiskClassCategoryText,
  getRiskClassRoleText,
} from '../risk_classification/QuestionnaireResult';

interface RiskClassTagProps {
  riskClassCategory?: RiskClassCategoryEnum | null | undefined | string;
  riskClassRole?: RiskClassRoleEnum | null | undefined | string;
  projectId: string;
  isEditable: boolean;
}

const RiskClassTag: React.FC<RiskClassTagProps> = ({
  riskClassCategory,
  riskClassRole,
  projectId,
  isEditable,
}) => {
  const getBorderColor = (
    riskClassCategory: RiskClassCategoryEnum | null | undefined | string
  ) => {
    switch (riskClassCategory) {
      case RiskClassCategoryEnum.Minimal:
        return { border: 'green', background: 'rgba(0, 128, 0, 0.1)' };
      case RiskClassCategoryEnum.Limited:
        return { border: 'orange', background: 'rgba(255, 165, 0, 0.1)' };
      case RiskClassCategoryEnum.High:
      case RiskClassCategoryEnum.HighExemptions:
        return { border: 'red', background: 'rgba(255, 0, 0, 0.1)' };
      case RiskClassCategoryEnum.Prohibited:
        return { border: 'black', background: 'rgba(0, 0, 0, 0.1)' };
      default:
        return { border: 'white', background: 'transparent' };
    }
  };

  const { border, background } = getBorderColor(riskClassCategory);
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap="10px"
      height="100%"
      sx={{
        border: `2px solid ${border}`,
        backgroundColor: background,
        py: '20px',
        borderRadius: '8px',
        textAlign: 'center',
      }}
    >
      {riskClassCategory && !isEditable ? (
        <Typography variant="h6">
          {riskClassCategory === 'OUT_OF_SCOPE'
            ? 'Out of Scope'
            : riskClassCategory === 'GPAI_SYSTEM'
            ? 'GPAI System'
            : getRiskClassCategoryText(riskClassCategory)}
        </Typography>
      ) : riskClassRole && !isEditable ? (
        <Typography variant="h6">
          {riskClassRole === 'OUT_OF_SCOPE'
            ? 'Out of Scope'
            : getRiskClassRoleText(riskClassRole as RiskClassRoleEnum)}
        </Typography>
      ) : isEditable ? (
        <Button component={Link} to={`/risk_classification/${projectId}`}>
          Change Risk Classification
        </Button>
      ) : (
        <Button component={Link} to={`/risk_classification/${projectId}`}>
          Add Risk Classification
        </Button>
      )}
    </Box>
  );
};

export default RiskClassTag;
